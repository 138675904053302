/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import {
  FiCheck,
  FiMap,
  FiMapPin,
  FiSearch,
  FiCompass,
  FiBarChart2,
} from "react-icons/fi";
import { RiMessage2Line } from "react-icons/ri";
import { MdTitle } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
/* eslint-disable-next-line */
import ptBR from "date-fns/esm/locale/pt-BR";
/* eslint-disable-next-line */
import { formatDistance } from "date-fns";
import * as Yup from "yup";

import { useSidebar } from "../../hooks/SidebarContext";

import Sidebar from "../../components/Sidebar";
import LoadingSpinner from "../../components/LoadingSpinner";
import BorderlessInput from "../../components/BorderlessInput";
import Select from "../../components/ReactSelect";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import Pagination from "../../components/Pagination";
import OnlineDeliverymansModal from "../../components/OnlineDeliverymansModal";

import api from "../../services/api";

import useStateCallback from "../../hooks/UseStateCallback";

import getValidationErrors from "../../utils/getValidationErrors";

import {
  OnlineDeliverymansV1Response,
  OnlineDeliverymanV1,
} from "../../@types/customTypes";

import * as Styled from "./styles";

interface StateResponse {
  id: number;
  name: string;
  uf: string;
}

interface CitiesResponse {
  id: number;
  state_id: number;
  name: string;
}

interface ToolsFormData {
  state_id: number;
  city_id: number;
}

interface Option {
  value: number;
  label: string;
}

type Contents = "tools" | "deliverymansList";

const PerCity: React.FC = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [states, setStates] = useState<Option[] | never[]>([]);
  const [cities, setCities] = useState<Option[] | never[]>([]);
  const [currentCity, setCurrentCity] = useState<null | Option>(null);
  const [onlineDeliverymansModalIsOpen, setOnlineDeliverymansModalIsOpen] =
    useStateCallback(false);
  const [selectedContent, setSelectedContent] = useState<Contents>("tools");
  const [onlineDeliverymansData, setOnlineDeliverymansData] = useState<
    OnlineDeliverymanV1[] | null
  >(null);
  const [rainValue, setRainValue] = useState(0);
  const [isBikeLimited, setIsBikeLimited] = useState(false);
  const [isReturnOff, setIsReturnOff] = useState(false);
  const [multiplier, setMultiplier] = useState(0);
  const [selectedDeliveryman, setSelectedDeliveryman] =
    useState<null | OnlineDeliverymanV1>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentSize, setCurrentSize] = useState<number | null>(8);
  const [calcTypes, setCalcTypes] = useState([{ label: "", value: 0 }]);

  const searchToolsFormRef = useRef<FormHandles>(null);
  const rainFlagFormRef = useRef<FormHandles>(null);  
  const multiplierFormRef = useRef<FormHandles>(null);
  const pushFormRef = useRef<FormHandles>(null);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const { search } = useLocation();

  const getCities = useCallback(async (state_id: number) => {
    setShowSpinner(true);
    setIsLoaded(false);
    setCities([]);
    const { data: CitiesData } = await api.get(`/cities/${state_id}`);
    const MappedCities = CitiesData.map((city: CitiesResponse) => {
      return {
        label: city.name,
        value: city.id,
      };
    });
    setCities(MappedCities);
    setShowSpinner(false);
  }, []);

  const handleGetToolsInfo = useCallback(async (city_id: number) => {
    const { data: configsData } = await api.get(`configsbackoffice/${city_id}`);
    setRainValue(configsData?.rain_cost ? Number(configsData.rain_cost) : 0);
    rainFlagFormRef.current?.setData({"calc_type": configsData?.calc_type ? Number(configsData.calc_type) : 0})
    setIsBikeLimited(
      configsData?.bicycle_limit_off ? configsData.bicycle_limit_off : false,
    );
    setIsReturnOff(
      configsData?.return_cost_off ? configsData.return_cost_off : false,
    );
    setMultiplier(
      configsData?.points_multiplier
        ? Number(configsData.points_multiplier)
        : 0,
    );

    setIsLoaded(true);
  }, []);

  const handleGetDeliverymansListInfo = useCallback(
    async (size = 8, page = 1, searchQuery = "", city_id: number) => {
      try {
        setShowSpinner(true);
        const { data: onlineDeliverymansResponse } =
          await api.get<OnlineDeliverymansV1Response>(
            `deliverymans/v1/online?city_id=${city_id}&page[size]=${size}&page[number]=${page}&search=user.name:${searchQuery}`,
          );
        setOnlineDeliverymansData(onlineDeliverymansResponse.data);
        setCurrentPage(page);
        setTotalPages(onlineDeliverymansResponse.to);
        setIsLoaded(true);
      } catch {
        // pass
      }
      setShowSpinner(false);
    },
    [],
  );

  const handleGetInfos = useCallback(
    async (data: ToolsFormData) => {
      try {
        const schema = Yup.object().shape({
          state_id: Yup.string().required("Escolha um estado"),
          city_id: Yup.number().required("Escolha uma cidade"),
        });
        await schema.validate(data, { abortEarly: false });
        setShowSpinner(true);
        const chosenCity = cities.find(
          (option) => option.value === data.city_id,
        );

        if (selectedContent === "tools") {
          await handleGetToolsInfo(data.city_id);
        } else {
          await handleGetDeliverymansListInfo(currentSize, 1, "", data.city_id);
        }

        setCurrentCity(chosenCity as Option);
      } catch (e) {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          searchToolsFormRef.current?.setErrors(errors);
        } else {
          addToast("Ocorreu um erro ao carregar dados!", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      }
      setShowSpinner(false);
    },
    [
      cities,
      addToast,
      handleGetDeliverymansListInfo,
      handleGetToolsInfo,
      selectedContent,
      currentSize,
    ],
  );

  const onKeyUpPercentMask = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      let inputValue = e.currentTarget.value;
      inputValue = inputValue.replace(/\D/g, "");

      if (e.key === "Backspace") {
        inputValue = e.currentTarget.value.slice(0, -1);

        if (inputValue === "") {
          e.currentTarget.value = inputValue;
          return;
        }
      }

      e.currentTarget.value = `${inputValue}%`;
    },
    [],
  );

  const onKeyUpXMask = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      let inputValue = e.currentTarget.value;
      inputValue = inputValue.replace(/\D/g, "");

      if (e.key === "Backspace") {
        inputValue = e.currentTarget.value.slice(0, -1);

        if (inputValue === "") {
          e.currentTarget.value = inputValue;
          return;
        }
      }

      e.currentTarget.value = `${inputValue}x`;
    },
    [],
  );

  const handleUpdateRainCost = useCallback(
    async (data: { percent: string }) => {
      try {
        setShowSpinner(true);
        data.percent = data.percent.replace(/\D/g, "");
        const schema = Yup.object().shape({
          percent: Yup.string().min(1, "Valor inválido"),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put(`/configs/rain/${currentCity?.value}`, data);
        setRainValue(Number(data.percent));
      } catch (e) {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          rainFlagFormRef.current?.setErrors(errors);
        } else {
          addToast("Ocorreu um erro ao atualizar flag!", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      }
      setShowSpinner(false);
    },
    [currentCity, addToast],
  );

  const handleUpdateBikeLimit = useCallback(
    async (bicycle_limit_off: boolean) => {
      setIsBikeLimited(bicycle_limit_off);
      try {
        setShowSpinner(true);
        await api.put(`/configs/bicycle_limit/${currentCity?.value}`, {
          bicycle_limit_off,
        });
      } catch (e) {
        setIsBikeLimited(!bicycle_limit_off);
        addToast("Ocorreu um erro ao atualizar flag!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    },
    [currentCity, addToast],
  );

  const handleUpdateReturn = useCallback(
    async (return_cost_off: boolean) => {
      setIsReturnOff(return_cost_off);
      try {
        setShowSpinner(true);
        await api.put(`/configs/return_cost_off/${currentCity?.value}`, {
          return_cost_off,
        });
      } catch (e) {
        setIsReturnOff(!return_cost_off);
        addToast("Ocorreu um erro ao atualizar flag!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    },
    [currentCity, addToast],
  );

  const handleUpdateMultiplier = useCallback(
    async (data: { points_multiplier: string }) => {
      try {
        setShowSpinner(true);
        data.points_multiplier = data.points_multiplier.replace(/\D/g, "");
        const schema = Yup.object().shape({
          points_multiplier: Yup.string().min(1, "Valor inválido"),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put(`/configs/points_multiplier/${currentCity?.value}`, data);
        setMultiplier(Number(data.points_multiplier));
      } catch (e) {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          multiplierFormRef.current?.setErrors(errors);
        } else {
          addToast("Ocorreu um erro ao atualizar flag!", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      }
      setShowSpinner(false);
    },
    [currentCity, addToast],
  );

  const handleSubmitPushNotification = useCallback(
    async (data: { title: string; message: string }) => {
      try {
        setShowSpinner(true);
        const schema = Yup.object().shape({
          message: Yup.string().required("Campo obrigatório"),
          title: Yup.string().required("Campo obrigatório"),
        });
        await schema.validate(data, { abortEarly: false });

        const dataToSubmitNotification = {
          ...data,
          city_id: currentCity?.value,
        };

        await api.post(`/deliverymans/push/topic`, dataToSubmitNotification);
        addToast("Notificação enviada com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (e) {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          pushFormRef.current?.setErrors(errors);
        } else {
          addToast("Ocorreu um erro ao enviar a notificação!", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      }
      setShowSpinner(false);
    },
    [addToast, currentCity],
  );

  const handleChangeSelectedContent = useCallback(
    async (content: Contents) => {
      setShowSpinner(true);
      try {
        setSelectedContent(content);

        if (content === "tools") {
          await handleGetToolsInfo(currentCity?.value as number);
        } else {
          await handleGetDeliverymansListInfo(
            null,
            null,
            null,
            currentCity?.value as number,
          );
        }
      } catch (err) {
        addToast("Ocorreu um erro ao carregar flags!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    },
    [addToast, handleGetToolsInfo, handleGetDeliverymansListInfo, currentCity],
  );

  const handleSetOnlineDeliverymansModalVisibility = useCallback(
    (newState: boolean, deliveryman?: OnlineDeliverymanV1) => {
      if (newState) {
        setOnlineDeliverymansModalIsOpen(newState, () =>
          setSelectedDeliveryman(deliveryman as OnlineDeliverymanV1),
        );
      } else {
        setOnlineDeliverymansModalIsOpen(newState, () =>
          setSelectedDeliveryman(null),
        );
      }
    },
    [setOnlineDeliverymansModalIsOpen],
  );

  useEffect(() => {
    (async () => {
      setShowSpinner(true);
      const { data: StatesData } = await api.get("/states");
      const MappedStates = StatesData.map((state: StateResponse) => {
        return {
          label: state.name,
          value: state.id,
        };
      });
      setStates(MappedStates);

      api.get("/calc_types").then((calcTypesResponse) => {
        setCalcTypes(
          calcTypesResponse.data.map(
            (status: { name: string; id: number; description: string }) => ({
              label: status.description,
              value: status.id,
            }),
          ),
        );
      });

      setShowSpinner(false);
    })();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(search);

    if (query.get("conteudo") === "listaEntregadores") {
      setSelectedContent("deliverymansList");
    }
  }, [search]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <OnlineDeliverymansModal
        isOpen={onlineDeliverymansModalIsOpen}
        deliverymansData={selectedDeliveryman ? [selectedDeliveryman] : null}
        setIsOpen={() => handleSetOnlineDeliverymansModalVisibility(false)}
        initialStep="map"
      />
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.Content>
          <Styled.TopBar>
            <Styled.Title>Gerenciar - Por Cidade</Styled.Title>
            {currentCity && (
              <Styled.TopContainer>
                <Styled.CurrentCity>{currentCity.label}</Styled.CurrentCity>
                <Styled.Separator>/</Styled.Separator>
                <Styled.TopButton
                  onClick={() =>
                    handleChangeSelectedContent(
                      selectedContent === "tools"
                        ? "deliverymansList"
                        : "tools",
                    )
                  }
                >
                  {selectedContent === "tools"
                    ? "Entregadores Online"
                    : "Ferramentas"}
                </Styled.TopButton>
              </Styled.TopContainer>
            )}
          </Styled.TopBar>
          <Styled.Strong>Selecione a localidade:</Styled.Strong>
          <Styled.SearchForm
            ref={searchToolsFormRef}
            onSubmit={handleGetInfos}
            autoComplete="off"
          >
            <Styled.InputsContainer>
              <Select
                isSearchable
                id="state_id"
                name="state_id"
                placeholder="Estado"
                formRef={searchToolsFormRef}
                options={states as Option[]}
                icon={FiMap}
                onChange={(option: any) => getCities(option?.value)}
              />
              {cities.length !== 0 && (
                <Select
                  isSearchable
                  id="city_id"
                  name="city_id"
                  icon={FiMapPin}
                  options={cities as Option[]}
                  placeholder="Cidade"
                  formRef={searchToolsFormRef}
                  hasAnimation
                />
              )}
            </Styled.InputsContainer>

            <Styled.ButtonSearch>
              Buscar
              <FiSearch
                size={25}
                onClick={() => searchToolsFormRef.current?.submitForm()}
              />
            </Styled.ButtonSearch>
          </Styled.SearchForm>
          
            <Styled.ToolsContainer show={isLoaded && selectedContent === "tools"}>
              <Styled.FlagCard>
                <Styled.CardTitle>Flag de Chuva</Styled.CardTitle>
                <Styled.CardStatus>
                  <Styled.CardStrong>Tipo de cálculo:</Styled.CardStrong>

                  <Styled.CardValue isActive={!!rainValue}>
                    {`${rainValue}%`}
                  </Styled.CardValue>
                </Styled.CardStatus>
                <Styled.FlagForm
                  ref={rainFlagFormRef}
                  onSubmit={handleUpdateRainCost}
                >
                  <Select
                    id="calc_type"
                    name="calc_type"
                    placeholder="Tipo de cálculo"
                    formRef={rainFlagFormRef}
                    options={calcTypes}
                    isSearchable={false}
                    icon={FiBarChart2}
                  />
                  <BorderlessInput
                    type="text"
                    id="percent"
                    name="percent"
                    placeholder="Porcentagem"
                    onKeyUp={onKeyUpPercentMask}
                    autoComplete="off"
                  />
                  <FiCheck
                    size={25}
                    onClick={() => rainFlagFormRef.current?.submitForm()}
                  />
                </Styled.FlagForm>
              </Styled.FlagCard>

              <Styled.FlagCard>
                <Styled.CardTitle>Limitar Bikes</Styled.CardTitle>
                <Styled.CardStatus>
                  <Styled.CardStrong>Status:</Styled.CardStrong>

                  <Styled.CardValue isActive={isBikeLimited}>
                    {isBikeLimited ? "On" : "Off"}
                  </Styled.CardValue>
                </Styled.CardStatus>
                <Styled.SwitchContainer>
                  <Styled.Switch
                    width={36}
                    height={18}
                    onChange={handleUpdateBikeLimit}
                    checked={isBikeLimited}
                  />
                </Styled.SwitchContainer>
              </Styled.FlagCard>

              <Styled.FlagCard>
                <Styled.CardTitle>Desativar Retorno</Styled.CardTitle>
                <Styled.CardStatus>
                  <Styled.CardStrong>Status:</Styled.CardStrong>

                  <Styled.CardValue isActive={isReturnOff}>
                    {isReturnOff ? "On" : "Off"}
                  </Styled.CardValue>
                </Styled.CardStatus>
                <Styled.SwitchContainer>
                  <Styled.Switch
                    width={36}
                    height={18}
                    onChange={handleUpdateReturn}
                    checked={isReturnOff}
                  />
                </Styled.SwitchContainer>
              </Styled.FlagCard>

              <Styled.FlagCard>
                <Styled.CardTitle>Multiplicar Pontos</Styled.CardTitle>
                <Styled.CardStatus>
                  <Styled.CardStrong>Status:</Styled.CardStrong>

                  <Styled.CardValue isActive={!!multiplier}>
                    {`${multiplier}x`}
                  </Styled.CardValue>
                </Styled.CardStatus>
                <Styled.FlagForm
                  ref={multiplierFormRef}
                  onSubmit={handleUpdateMultiplier}
                >
                  <BorderlessInput
                    type="text"
                    id="points_multiplier"
                    name="points_multiplier"
                    placeholder="Valor"
                    onKeyUp={onKeyUpXMask}
                    maxLength={4}
                    autoComplete="off"
                  />
                  <FiCheck
                    size={25}
                    onClick={() => multiplierFormRef.current?.submitForm()}
                  />
                </Styled.FlagForm>
              </Styled.FlagCard>
              <Styled.PushCard>
                <Styled.CardTitle>Push Notifications</Styled.CardTitle>
                <Styled.PushForm
                  ref={pushFormRef}
                  onSubmit={handleSubmitPushNotification}
                >
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Título"
                    icon={MdTitle}
                  />
                  <Input
                    type="text"
                    id="message"
                    name="message"
                    placeholder="Mensagem"
                    icon={RiMessage2Line}
                  />
                  <Button type="submit" content="Enviar" />
                </Styled.PushForm>
              </Styled.PushCard>
            </Styled.ToolsContainer>

          {isLoaded && selectedContent === "deliverymansList" && (
            <Styled.Ul>
              {onlineDeliverymansData?.map(
                (deliveryman: OnlineDeliverymanV1) => {
                  return (
                    <Styled.Li color="lightblue" key={deliveryman.id}>
                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>{deliveryman.id}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Apelido:</span>
                        <strong>{deliveryman.nickname}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Cidade:</span>
                        <strong>{deliveryman.address.city.name}</strong>
                      </Styled.CommonField>

                      <Styled.StatusField>
                        <span>
                          {formatDistance(
                            new Date(deliveryman.online_date),
                            Date.now(),
                            { addSuffix: true, locale: ptBR },
                          )}
                        </span>
                      </Styled.StatusField>

                      <Styled.ActionField>
                        <FiCompass
                          size={27}
                          onClick={() =>
                            handleSetOnlineDeliverymansModalVisibility(
                              true,
                              deliveryman,
                            )
                          }
                        />
                      </Styled.ActionField>
                    </Styled.Li>
                  );
                },
              )}
            </Styled.Ul>
          )}

          {isLoaded &&
            !onlineDeliverymansData?.length &&
            selectedContent === "deliverymansList" && (
              <Styled.WarningText>
                Não há entregadores online em {currentCity?.label}!
              </Styled.WarningText>
            )}

          {isLoaded &&
            !!onlineDeliverymansData?.length &&
            selectedContent === "deliverymansList" &&
            currentPage !== 0 && (
              <Pagination
                updateList={(a, b, c) =>
                  handleGetDeliverymansListInfo(
                    a,
                    b,
                    c,
                    currentCity?.value as number,
                  )
                }
                totalPages={totalPages}
                currentPage={currentPage}
                searchQuery=""
                sizes={[8, 10, 20, 30]}
                currentSize={currentSize}
                setCurrentSize={setCurrentSize}
              />
            )}
        </Styled.Content>
      </ContainerMain>
    </Container>
  );
};

export default PerCity;
