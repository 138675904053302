import { darken, lighten } from "polished";
import { Link as ReactLink } from "react-router-dom";
import { Form as Unform } from "@unform/web";
import styled, { css } from "styled-components";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

interface DeliverymanColor {
  color: string;
}

interface LinkProps {
  isDisabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const OperatorsContainer = styled.div`
  padding: 20px 30px;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
`;

export const Title = styled.h4`
  padding-bottom: 19px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);

  color: var(--color-text-secondary);
  > div:first-child {
    display: flex;
    align-items: center;
    > svg {
      color: #373737;
      margin-left: 25px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: ${lighten(0.25, "#466aa9")};
      }
    }
  }
`;

export const SearchForm = styled(Unform)`
  padding-right: 50px;
  display: flex;
  align-items: center;

  > div:last-child {
    width: 200px;
  }
`;

export const TopLink = styled(ReactLink)`
  background: #6a64d9;
  padding: 5px 14px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: 12px;
  margin-right: 30px;
  text-decoration: none;

  &:hover {
    background: ${darken("0.25", "#6a64d9")};
  }
`;

export const OperatorsShimmer = styled.div`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    width: 100%;
    height: 70px;
    border-radius: 4px;
  }

  > div:not(:first-child) {
    margin-top: 3px;
  }
`;

export const ListContainer = styled.div``;

export const Ul = styled.ul`
  list-style: none;
  padding: 0 30px;
  > li:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export const Li = styled.li<DeliverymanColor>`
  width: 100%;
  height: 70px;
  border-radius: 4px;
  border-left: 3px solid ${({ color }) => color};
  box-shadow: 2px 0px 5px gray;
  padding: 0 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  > div:first-child {
    min-width: 7%;
  }

  > div:nth-child(2) {
    width: 14%;
  }

  > div:nth-child(3) {
    min-width: 19%;
  }

  > div:nth-child(4) {
    min-width: 19%;
  }

  > div:nth-child(5) {
    min-width: 19%;
  }

  > div:last-child {
    min-width: 5%;
  }
`;

export const CommonField = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > strong {
    font-size: 14px;
    color: #515a6a;
  }
`;

export const ActionField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > svg:first-child {
    margin-right: 15px;
    color: #373737;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const Link = styled(ReactLink)<LinkProps>`
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          pointer-events: none;
          > svg {
            stroke: lightgray;
          }
        `
      : css`
          > svg {
            stroke: #373737;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              stroke: ${lighten(0.25, "#466aa9")};
            }
          }
        `}
`;
