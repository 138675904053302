import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

interface NavItemProps {
  isCurrentPage: boolean;
  isSidebarMaximized: boolean;
}

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

interface CollapseNavItemProps extends NavItemProps {
  isCollapsed: boolean;
}

interface CollapseItemsProps {
  isCollapsed: boolean;
  pathLength: number;
}

interface CollapseItemProps {
  isCurrentPage: boolean;
}

interface BurgerProps {
  isOpen: boolean;
}

export const Container = styled.aside<SidebarStatus>`
  width: 100vw;

  display: flex;
  justify-content: space-between;
  background: #f4f5f7;
  padding: 15px;
  box-shadow: 0px 2px 10px rgba(220, 220, 220, 1);

  @media (min-width: 1090px) {
    height: 100vh;
    max-height: 100vh;
    padding: 0;
    position: sticky;
    top: 0px;
    background: white;
    border-right: 1px solid rgba(220, 220, 220, 0.5);
    box-shadow: 0px 2px 10px rgba(220, 220, 220, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: width 0.3s !important;
    width: ${({ isSidebarMaximized }) =>
      isSidebarMaximized ? "270px" : "80px"};
  }
`;

export const Burger = styled.button<BurgerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.8rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 6;
  &:focus {
    outline: none;
  }
  div {
    width: 1.8rem;
    height: 0.2rem;
    background: #373737;
    border-radius: 8px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    /*     &:first-child {
      transform: ${({ isOpen }) => (isOpen ? "rotate(37deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
      transform: ${({ isOpen }) =>
      isOpen ? "translateX(20px)" : "translateX(0)"};
    }
    &:nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? "rotate(-37deg)" : "rotate(0)")};
    } */
  }
  @media (min-width: 1090px) {
    display: none;
  }
`;

export const Header = styled.header`
  display: flex;

  @media (min-width: 1090px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ArrowContainer = styled.div`
  position: absolute;
  right: -17.5px;
  top: 20px;
  padding: 8px;
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  background: var(--color-primary);
  border: 1px solid rgba(220, 220, 220, 0.5);
  cursor: pointer;
  border-radius: 50%;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  > svg {
    color: white;
  }

  @media (min-width: 1090px) {
    display: flex;
  }
`;

export const ImageContainer = styled.div<SidebarStatus>`
  display: none;

  @media (min-width: 1090px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.5);

    > img {
      width: 50%;
      transition: 0.2s;
      ${({ isSidebarMaximized }) =>
        !isSidebarMaximized &&
        css`
          opacity: 0;
          width: 0;
        `};
    }
  }
`;

export const GreetingContainer = styled.div`
  display: none;

  @media (min-width: 1090px) {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba(220, 220, 220, 0.5);
    width: 100%;
    > div {
      > p:first-child {
        font-size: 20px;
        color: #373737;
      }

      > p:last-child {
        color: #373737;
        font-size: 18px;
      }

      p {
        text-align: center;
      }
    }
  }
`;

export const LogoMin = styled.img`
  width: 40px;
  margin-top: 15px;
`;

export const NavSide = styled.nav<BurgerProps>`
  position: absolute;
  right: 0;
  top: 54px;
  width: 100vw;
  z-index: 5555;

  > ul {
    max-height: 70vh;
    background: #f4f5f7;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      box-shadow: 0 15px 20px -5px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0 15px 20px -5px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 15px 20px -5px rgba(0, 0, 0, 0.5);
    `}

  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : "translateX(-100%)"};

  transition: 0.4s ease-in-out;

  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: scrollbar;
  scrollbar-width: 5px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 8px;
  }

  @media (min-width: 1090px) {
    background: #f4f5f7;
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: unset;
    max-height: 52vh;
    background: unset;
    top: unset;
    right: unset;
    transform: unset;
    box-shadow: unset;
    -moz-box-shadow: unset;
    -webkit-box-shadow: unset;

    > ul {
      max-height: unset;
      background: unset;
    }
  }
`;

export const NavItem = styled.li<NavItemProps>`
  border-top: 1px solid lightgray;
  display: flex;

  > a {
    transition: 0.3s;
    padding: 14px;
    display: flex;
    background: ${({ isCurrentPage }) => (isCurrentPage ? "#F4F5F7" : "unset")};
    width: 100%;

    &:hover {
      opacity: ${({ isCurrentPage }) => (isCurrentPage ? "unset" : "0.5")};
    }

    > span {
      max-height: 19px !important;
      overflow: hidden;
      color: ${({ isCurrentPage }) =>
        isCurrentPage ? "var(--color-primary)" : "gray"};
      margin-left: 10px;
    }
    > svg {
      color: ${({ isCurrentPage }) =>
        isCurrentPage ? "var(--color-primary)" : "gray"};
    }
  }

  @media (min-width: 1090px) {
    border-top: none;
    list-style: none;
    display: flex;
    justify-content: center;
    border-left-width: 2px;
    border-left-style: solid;
    border-color: ${({ isCurrentPage }) =>
      isCurrentPage ? "var(--color-primary)" : "transparent"};

    > a {
      justify-content: ${({ isSidebarMaximized }) =>
        isSidebarMaximized ? "unset" : "center"};
      > span {
        display: ${({ isSidebarMaximized }) =>
          isSidebarMaximized ? "unset" : "none"};
      }
    }
  }
`;

export const NavButton = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const CollapseNavItem = styled.li<CollapseNavItemProps>`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid lightgray;

  > div:first-child {
    transition: 0.3s;
    padding: 14px;
    display: flex;
    background: ${({ isCurrentPage }) => (isCurrentPage ? "#F4F5F7" : "unset")};
    width: 100%;

    &:hover {
      opacity: ${({ isCurrentPage }) => (isCurrentPage ? "unset" : "0.5")};
    }

    > div:nth-child(2) {
      > span {
        max-height: 19px !important;
        overflow: hidden;
        color: ${({ isCurrentPage }) =>
          isCurrentPage ? "var(--color-primary)" : "gray"};

        margin-left: 10px;
      }
    }
    > svg {
      color: ${({ isCurrentPage }) =>
        isCurrentPage ? "var(--color-primary)" : "gray"};
    }
  }

  @media (min-width: 1090px) {
    border-top: none;

    > div:first-child {
      justify-content: ${({ isSidebarMaximized }) =>
        isSidebarMaximized ? "unset" : "center"};

      > div:nth-child(2) {
        > span {
          display: ${({ isSidebarMaximized }) =>
            isSidebarMaximized ? "unset" : "none"};
        }
      }
    }
  }
`;

export const CollapseNavButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CollapseItems = styled.ul<CollapseItemsProps>`
  max-height: ${({ isCollapsed, pathLength }) =>
    isCollapsed ? pathLength * 48 : 0}px;
  width: 100%;
  overflow: hidden;
  transition: 0.2s ease-out !important;
  display: flex;
  flex-direction: column;
`;

export const CollapseItem = styled.li<CollapseItemProps>`
  max-height: 48px;
  width: 100%;
  cursor: pointer;
  border-left-width: 2px;
  border-left-style: solid;
  border-color: ${({ isCurrentPage }) =>
    isCurrentPage ? "var(--color-primary)" : "transparent"};

  &:hover {
    opacity: ${({ isCurrentPage }) => (isCurrentPage ? "unset" : "0.5")};
  }

  > a {
    width: 100%;
    color: ${({ isCurrentPage }) =>
      isCurrentPage ? "var(--color-primary)" : "gray"};
  }

  @media (min-width: 1090px) {
    background: ${({ isCurrentPage }) => (isCurrentPage ? "#fbfcfc" : "unset")};
  }
`;

export const CollapseItemButton = styled(Link)`
  text-decoration: none;
  padding: 14px 14px 14px 35px;
  display: flex;
`;

export const NavText = styled.div<SidebarStatus>`
  display: flex;
  justify-content: space-between;
  flex: 1;

  @media (min-width: 1090px) {
    flex: ${({ isSidebarMaximized }) => (isSidebarMaximized ? 1 : "unset")};
  }
`;

export const Footer = styled.footer`
  display: flex;
  @media (min-width: 1090px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LogoutContainer = styled.div<SidebarStatus>`
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }

  > span {
    display: none !important;
    font-size: 20px;
    color: #373737;
    margin-left: 10px;
    display: ${({ isSidebarMaximized }) =>
      isSidebarMaximized ? "unset" : "none"};
  }

  > svg {
    color: tomato;
  }

  @media (min-width: 1090px) {
    margin-bottom: ${({ isSidebarMaximized }) =>
      isSidebarMaximized ? "10%" : "10vh"};
    > span {
      display: unset;
    }
  }
`;

export const Copyright = styled.div<SidebarStatus>`
  display: none;
  @media (min-width: 1090px) {
    font-size: 15px;
    max-height: 19px !important;
    margin-bottom: 10%;
    color: gray;
    overflow: hidden;
    display: ${({ isSidebarMaximized }) =>
      isSidebarMaximized ? "unset" : "none"};
  }
`;
