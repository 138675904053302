import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useField } from "@unform/core";
import { FiAlertCircle } from "react-icons/fi";

import * as Styled from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const BorderlessInput: React.FC<InputProps> = ({
  name,
  placeholder,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleSetIsFocused = useCallback(() => {
    clearError();
    setIsFocused(true);
  }, [clearError]);

  return (
    <Styled.Container isFocused={isFocused} isErrored={!!error}>
      <Styled.Input
        onFocus={handleSetIsFocused}
        onBlur={handleInputBlur}
        placeholder=" "
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
      />
      <label htmlFor={rest.id}>{placeholder}</label>
      {error && (
        <Styled.Error title={error}>
          <FiAlertCircle size={20} />
        </Styled.Error>
      )}
    </Styled.Container>
  );
};

export default BorderlessInput;
