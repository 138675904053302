import styled from "styled-components";
import img from "../../assets/img/404.svg";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const Image = styled.div`
  height: 600px;
  width: 600px;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;

  @media (max-width: 570px) {
    width: 300px;
    height: 300px;
    margin-top: 200px;
  }
`;
export const ButtonBack = styled.span`
  display: inline-block;
  align-items: center;
  justify-content: center;

  padding: 16px 24px;
  margin: 0 auto;

  border: none;
  border-radius: 4px;

  color: white;
  cursor: pointer;
  font-size: 24px;
  background-color: #3568b3;

  text-decoration: none;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: #2d5b9b;
  }
`;
