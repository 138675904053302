import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const formatLocale = (
  dateString: string | Date,
  formatString: string,
): string => {
  if (dateString === null) return "";
  const zonedDate = utcToZonedTime(new Date(dateString), "America/Sao_Paulo");
  return format(zonedDate, formatString);
};

export default formatLocale;
