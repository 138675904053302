import { ValidationError } from "yup";

interface Errors {
  [key: string]: string;
}

export default function getValidationErrors(err: ValidationError): Errors {
  const validationErrors: Errors = {};

  err.inner.forEach((error) => {
    if (
      error.path === "latitude_1" ||
      error.path === "longitude_1" ||
      error.path === "address_1" ||
      error.path === "number_1"
    ) {
      validationErrors.autocomplete_1 = "Endereço inválido";
    } else {
      validationErrors[error.path as string] = error.message;
    }
  });

  return validationErrors;
}
