import React from "react";
import { FaCarSide, FaMotorcycle, FaBicycle } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";

import * as Styled from "./styles";

interface VehicleSelectProps {
  setSelectedVehicles: React.Dispatch<React.SetStateAction<number[]>>;
  selectedVehicles: number[];
}

const VehiclesSelect: React.FC<VehicleSelectProps> = ({
  setSelectedVehicles,
  selectedVehicles,
}) => {
  const vehicleOptions = [
    {
      value: 1,
      label: "Moto",
      icon: (
        <FaMotorcycle
          size={30}
          color={
            selectedVehicles.includes(1) ? "white" : "var(--color-primary)"
          }
        />
      ),
    },
    {
      value: 2,
      label: "Carro",
      icon: (
        <FaCarSide
          size={30}
          color={
            selectedVehicles.includes(2) ? "white" : "var(--color-primary)"
          }
        />
      ),
    },
    {
      value: 3,
      label: "Bicicleta",
      icon: (
        <FaBicycle
          size={30}
          color={
            selectedVehicles.includes(3) ? "white" : "var(--color-primary)"
          }
        />
      ),
    },
  ];

  const handleVehicleChange = (vehicleValue: number) => {
    if (
      selectedVehicles.length === 1 &&
      selectedVehicles.includes(vehicleValue)
    )
      return;

    if (selectedVehicles.length === 3) {
      setSelectedVehicles([vehicleValue]);
      return;
    }

    if (selectedVehicles.includes(vehicleValue)) {
      setSelectedVehicles((state) =>
        state.filter(
          (selectedVehiclesSt) => selectedVehiclesSt !== vehicleValue,
        ),
      );
    } else {
      setSelectedVehicles((state) => [...state, vehicleValue]);
    }
  };

  const renderSelectedVehiclesText = () => {
    if (selectedVehicles.length === 3)
      return (
        <>
          Você selecionou <span>todos</span> os veículos.
        </>
      );

    if (selectedVehicles.length === 1) {
      if (selectedVehicles.includes(1))
        return (
          <>
            Você selecionou apenas <span>moto</span>.
          </>
        );

      if (selectedVehicles.includes(2))
        return (
          <>
            Você selecionou apenas <span>carro</span>.
          </>
        );

      if (selectedVehicles.includes(3))
        return (
          <>
            Você selecionou apenas <span>bicicleta</span>.
          </>
        );
    }

    if (selectedVehicles.length === 2) {
      if (selectedVehicles.includes(1) && selectedVehicles.includes(2))
        return (
          <>
            Você selecionou <span>carro</span> e <span>moto</span>.
          </>
        );

      if (selectedVehicles.includes(1) && selectedVehicles.includes(3))
        return (
          <>
            Você selecionou <span>moto</span> e <span>bicicleta</span>.
          </>
        );

      if (selectedVehicles.includes(2) && selectedVehicles.includes(3))
        return (
          <>
            Você selecionou <span>carro</span> e <span>bicicleta</span>.
          </>
        );
    }

    return "";
  };

  return (
    <div>
      <Styled.InfoContainer>
        <FiInfo size={15} color="gray" />
        <Styled.InfoText>
          É possivel selecionar um ou mais veículos.
        </Styled.InfoText>
      </Styled.InfoContainer>
      <Styled.Row>
        {vehicleOptions.map((vehicle) => (
          <Styled.VehicleButton
            data-tip={vehicle.label}
            isSelected={selectedVehicles.includes(vehicle.value)}
            key={vehicle.label}
            onClick={() => handleVehicleChange(vehicle.value)}
          >
            {vehicle.icon}
          </Styled.VehicleButton>
        ))}
      </Styled.Row>

      <Styled.SelectedVehiclesText>
        {renderSelectedVehiclesText()}
      </Styled.SelectedVehiclesText>

      <Styled.CheckboxContainer>
        <Styled.Input
          type="checkbox"
          checked={selectedVehicles.length === 3}
          onChange={() =>
            selectedVehicles.length !== 3 ? setSelectedVehicles([1, 2, 3]) : ""
          }
        />
        <Styled.CheckboxText>
          Todos veículos relacionados acima
        </Styled.CheckboxText>
      </Styled.CheckboxContainer>
    </div>
  );
};

export default VehiclesSelect;
