export const hardCodedCardBrands = [
  {
    value: "1",
    label: "Master",
  },
  {
    value: "2",
    label: "Visa",
  },
  {
    value: "3",
    label: "Diners",
  },
  {
    value: "4",
    label: "Elo",
  },
  {
    value: "5",
    label: "Amex",
  },
  {
    value: "6",
    label: "Hipercard",
  },
  {
    value: "7",
    label: "Banese",
  },
  {
    value: "8",
    label: "Banricompras",
  },
  {
    value: "9",
    label: "Cabal",
  },
  {
    value: "10",
    label: "Credz",
  },
  {
    value: "11",
    label: "Sicredi",
  },
  {
    value: "12",
    label: "Sorocred",
  },
  {
    value: "13",
    label: "VerdeCard",
  },
  {
    value: "14",
    label: "CredSystem",
  },
  {
    value: "15",
    label: "Banestes",
  },
  {
    value: "16",
    label: "JCB",
  },
  {
    value: "17",
    label: "Discover",
  },
  {
    value: "18",
    label: "Aura",
  },
];
