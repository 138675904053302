import { darken, lighten, shade } from "polished";
import styled, { css } from "styled-components";
import reactSelect from "react-select";
import { CircularProgressbar } from "react-circular-progressbar";
import { Form as Unform } from "@unform/web";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

interface CircularProps {
  isModalOpen: boolean;
  isPaused: boolean;
}

interface ListProps extends OrderColor {
  isAdmin: boolean;
}

interface OrderColor {
  color: string;
}

interface IsAdminProps {
  isAdmin: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const OrdersContainer = styled.div`
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
  margin-bottom: 40px;

  @media (min-width: 800px) {
    padding: 20px 30px;
  }
`;

export const CardTitle = styled.h4<IsAdminProps>`
  position: relative;
  padding: 15px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
  display: flex !important;
  flex-direction: column !important;
  align-items: start;
  gap: 24px;

  > div:first-child {
    color: var(--color-text-secondary);
    display: flex;
    align-items: center;

    > svg:first-child {
      margin-left: 15px;
    }

    > svg {
      color: #373737;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 10px;

      &:hover {
        color: ${lighten(0.25, "#466aa9")};
      }
    }
  }

  ${({ isAdmin }) =>
    isAdmin &&
    css`
      flex-direction: column;
      align-items: unset;
      > div:last-child {
        margin-top: 15px;
        color: var(--color-text-secondary);
        display: flex;
        align-items: center;
      }

      @media (min-width: 620px) {
        flex-direction: row;

        > div:last-child {
          margin-top: 0;
          // padding-right: 70px;
        }
      }
    `}

  @media (min-width: 800px) {
    > div:first-child {
      > svg:first-child {
        margin-left: 25px;
      }

      > svg {
        margin-left: 15px;
      }
    }

    padding: 0 0 19px 0;
  }
`;

export const OrdersShimmer = styled.div<IsAdminProps>`
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ isAdmin }) =>
    isAdmin
      ? css`
          > div {
            width: 100%;
            height: 300px;
            border-radius: 4px;
          }

          @media (min-width: 1024px) {
            > div {
              height: 280px;
            }
          }
        `
      : css`
          > div {
            width: 100%;
            height: 260px;
            border-radius: 4px;
          }

          @media (min-width: 800px) {
            > div {
              height: 70px;
            }
          }
        `}

  > div:not(:first-child) {
    margin-top: 3px;
  }
`;

export const CircularProgress = styled(CircularProgressbar) <CircularProps>`
  position: absolute;
  right: 20px;
  width: 30px;
  height: 30px;

  .CircularProgressbar-path {
    stroke: #466aa9;
    ${({ isModalOpen, isPaused }) =>
    !isModalOpen &&
    !isPaused &&
    css`
        animation: blink 3s infinite;
      `}
  }

  @keyframes blink {
    0% {
      stroke: #466aa9;
    }
    50% {
      stroke: ${lighten(0.25, "#466aa9")};
    }
    0% {
      stroke: #466aa9;
    }
  }
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0 15px;
  > li:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Li = styled.li<ListProps>`
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  border-top: 3px solid ${({ color }) => color};
  box-shadow: 2px 0px 5px gray;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > div {
    flex: 45%;
  }

  ${({ isAdmin }) =>
    isAdmin
      ? css`
          height: auto;

          > div:nth-child(1) {
            flex: 55%;
            > strong {
              max-width: 120px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          > div:nth-child(3) {
            flex: 55%;
          }

          > div:nth-child(5) {
            flex: 55%;
          }

          > div:nth-child(7) {
            flex: 55%;
          }
          > div:nth-child(9) {
            flex: 55%;
          }

          > div:nth-child(10) {
            border-top: 1px solid lightgray;
            margin-top: 10px;
            flex: 100%;
            justify-content: center;
            display: flex;
            > div {
              padding-top: 10px;
              flex: 70%;
              max-width: 80%;
            }
          }

          > div:last-child {
            flex: 100%;
            justify-content: center;
            margin-top: 10px;
          }
        `
      : css`
          height: 260px;

          > div:nth-child(5) {
            flex: 100%;
          }

          > div:nth-child(6) {
            flex: 70%;
            max-width: 80%;
            margin-top: 10px;
          }

          > div:last-child {
            flex: 100%;
            justify-content: center;
            margin-top: 10px;
          }
        `};

  @media (min-width: 550px) {
    padding: 15px 60px;
  }

  ${({ isAdmin, color }) =>
    !isAdmin &&
    css`
      @media (min-width: 800px) {
        flex-wrap: unset;
        height: 70px;
        padding: 0 10px;
        border-top: none;
        border-left: 3px solid ${color};

        > div {
          flex: unset;
        }

        > div:first-child {
          width: 10%;
        }
        > div:nth-child(2) {
          width: 18%;
        }

        > div:nth-child(3) {
          min-width: 16%;
        }

        > div:nth-child(4) {
          min-width: 10%;
        }
        > div:nth-child(5) {
          min-width: 10%;
          border-bottom: none;
          flex: unset;
        }

        > div:nth-child(6) {
          max-width: 13%;
          min-width: 120px;
          margin-top: 0;
          flex: unset;
        }

        > div:last-child {
          min-width: 10%;
          margin-top: 0;
          flex: unset;
        }
      }

      @media (max-width: 800px) {
        > div:nth-child(6) {
          flex: 70%;
          max-width: 100%;
          margin-top: 10px;
        }
      }
    `}

  ${({ isAdmin }) =>
    isAdmin &&
    css`
      @media (min-width: 1024px) {
        height: 280px;

        > div:nth-child(1) {
          flex: 33%;
          > strong {
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        > div:nth-child(2) {
          flex: 33%;
        }

        > div:nth-child(3) {
          flex: 33%;
        }

        > div:nth-child(4) {
          flex: 33%;
        }

        > div:nth-child(5) {
          flex: 33%;
        }

        > div:nth-child(6) {
          flex: 33%;
        }

        > div:nth-child(7) {
          flex: 33%;
        }

        > div:nth-child(8) {
          flex: 33%;
        }

        > div:nth-child(9) {
          flex: 33%;
        }

        > div:nth-child(10) {
          padding-top: 10px;
          border-top: 1px solid lightgray;
          margin-top: 10px;
          flex: 100%;
          justify-content: center;
          display: flex;
          > div {
            flex: 70%;
            max-width: 80%;
          }
        }

        > div:last-child {
          flex: 100%;
          justify-content: center;
          margin-top: 10px;
        }
      }
    `}
`;

export const CommonField = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > strong {
    font-size: 14px;
    color: #515a6a;
  }
`;
export const StatusField = styled.div<OrderColor>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
  padding: 5px 11px;
  background-color: ${({ color }) => color};
  border-radius: 14px;
  color: white;

  > svg {
    cursor: pointer;
    margin-left: 15px;
    color: tomato;
    -webkit-filter: drop-shadow(1.2px 0.9px 0.2px rgba(0, 0, 0, 1));
    filter: drop-shadow(1px 0.8px 0.2px rgba(0, 0, 0, 1));
    transition: 0.3s;

    &:hover {
      color: ${darken(0.2, "#c53030")};
    }
  }
`;

export const ReactSelect = styled(reactSelect) <OrderColor>`
  .react-select__control {
    border-radius: 14px;
    border: none;
    background-color: ${({ color }) => color};
    min-height: unset;
    justify-content: center;
    outline: none;
    padding: 5px 0;
    box-shadow: none;
  }

  .react-select__loading-indicator {
    display: none;
  }

  .react-select__value-container {
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .react-select__single-value {
    font-size: 13px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
    color: white;
  }

  .react-select__menu {
    top: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(220, 220, 220, 1);
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    padding: 0 !important;
  }

  .react-select__menu-list {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding: 5px 10px;
  }

  .react-select__option--is-focused:active {
    background-color: unset;
  }

  .react-select__option--is-selected:active {
    background-color: unset;
  }

  .react-select__option {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(220, 220, 220, 0.5);
    }
    background: white;
    color: #253451;
    font-size: 13px;
    display: block;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 5px 0;
    cursor: pointer;
    &:hover {
      color: ${shade(0.6, "#253451")};
    }
  }

  .react-select__indicator {
    padding: 0 8px 0 0;
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }

  .react-select__indicator-separator {
    display: none;
  }
`;

export const ActionField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > svg:first-child {
    color: #373737;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const SearchForm = styled(Unform)`
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  width: 100%;
  gap: 5px;
  > div {
    width: 200px;
  }

  > select {
    width: 150px;
    outline: none;
    border: none;
    height: 33px;
    border-radius: 6px;
    padding: 0 4px;
  }

  @media (min-width: 620px) {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const OrderStatus = styled.section<OrderColor>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;

  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      color: white;
      border: none;
      border-radius: 4px;
      padding: 0.75rem 1rem;
      cursor: pointer;
      transition: 0.3s;
      filter: brightness(0.9);
    }

    button:first-of-type {
      background-color: var(--color-danger);
    }

    button:nth-child(2) {
      width: 208px;
      background-color: #F5C133;
    }

    button:last-of-type {
      width: 208px;
      background-color: #00a759;
    }
  }

  @media (max-width: 768px) {
    section {
      flex-direction: column;
      gap: 0.85rem;
      margin: 0.5rem 0;
    }

    > div {
      order: 1;
    }
    button {
      order: 2;
    }
  }
`;

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  background-color: #466aa9;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #345b8e;
  }
`;

export const ContainerDiv = styled.div`
  width: 100% !important;
  display: flex;
`;

export const CardSearchContainer = styled.div``;

export const GridForm = styled.div`
  display: grid;
  grid-template-columns: 100%;
  width: 100% !important;
  gap: 14px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const GridFormButtons = styled.div`
  display: grid;
  grid-template-columns: 100%;
  width: 100% !important;
  gap: 8px;
  margin-top: 8px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const SearchButtonForm = styled.button`
  background-color: #466aa9;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;

  &:hover {
    background-color: #345b8e;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerDivButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

export const ContainerDivButtonActions = styled.div`
  width: 100% !important;
  display: flex;
  gap: 12px;
  justify-content: end;
`;
