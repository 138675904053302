import styled from "styled-components";

export const InfoContainer = styled.div`
  background: rgba(100, 100, 100, 0.05);
  max-width: 290px;
  border-radius: 4px;
  padding: 7px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
  margin-top: -10px;

  @media (min-width: 700px) {
    margin-left: 15px;
  }
`;

export const InfoText = styled.span`
  font-size: 13px;
  margin-left: 5px;
  color: gray;
`;

export const Row = styled.div`
  display: flex;

  margin-bottom: 15px;
  > div {
    margin-right: 10px;
  }

  @media (min-width: 700px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const VehicleButton = styled.div<{ isSelected: boolean }>`
  height: 70px;
  width: 70px;
  background: ${({ isSelected }) =>
    isSelected ? "var(--color-primary)" : "white"};
  border-radius: 16px;
  box-shadow: 1px 1px 10px rgb(70, 106, 169, 0.2);
  cursor: pointer;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 15px !important;

  &:hover {
    background: var(--color-secondary);
  }
`;

export const SelectedVehiclesText = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--color-text-primary);

  > span {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    color: var(--color-text-primary);
  }

  @media (min-width: 700px) {
    margin-left: 40px;
  }
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  @media (min-width: 700px) {
    margin-left: 40px;
  }
`;

export const Input = styled.input`
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  transition: 0.1s;
  border: 1px solid #adb8c0;
  text-align: center;
  color: var(--color-text-primary);
  border-radius: 3px;
  outline: none;

  &:disabled {
    pointer-events: none;
    background: rgba(128, 128, 128, 0.2);
    border: 1px solid lightgray;
  }

  &:checked {
    color: var(--color-primary);
  }

  &:checked:before {
    content: "✓";
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const CheckboxText = styled.span`
  font-size: 13px;
  margin-left: 5px;
  color: var(--color-text-dark);
`;
