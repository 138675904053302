import React, { useEffect } from "react";

import * as Styled from "./styles";

const LoadingSpinner: React.FC = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <Styled.Overlay>
      <Styled.Spinner />
    </Styled.Overlay>
  );
};

export default LoadingSpinner;
