import { darken, lighten, shade } from "polished";
import { Form as Unform } from "@unform/web";
import styled, { css } from "styled-components";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

interface TransactionColor {
  color: string;
}

interface PaginationProps {
  isDisabled: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const TransactionsContainer = styled.div`
  padding: 20px 30px;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
  margin-bottom: 40px;
`;

export const Title = styled.h4`
  padding-bottom: 19px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);

  color: var(--color-text-secondary);
  > div:first-child {
    display: flex;
    align-items: center;
    > svg {
      color: #373737;
      margin-left: 25px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: ${lighten(0.25, "#466aa9")};
      }
    }
  }
`;

export const SearchForm = styled(Unform)`
  padding-right: 50px;
  display: flex;
  align-items: center;

  > div:last-child {
    width: 200px;
  }
`;

export const TopLink = styled.div`
  background: #6a64d9;
  padding: 5px 14px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: 12px;
  margin-right: 30px;
  text-decoration: none;

  &:hover {
    background: ${darken("0.25", "#6a64d9")};
  }
`;

export const TransactionsShimmer = styled.div`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    width: 100%;
    height: 70px;
    border-radius: 4px;
  }

  > div:not(:first-child) {
    margin-top: 3px;
  }
`;

export const BalanceContainer = styled.div`
  padding-left: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  > div {
    width: 55px;
    height: 20px;
    margin-left: 10px;
  }
`;

export const Balance = styled.strong`
  font-size: 18px;
  color: var(--color-primary);
  margin-left: 10px;
  display: flex;
  align-items: center;

  > svg {
    color: #373737;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const ListContainer = styled.div``;

export const Ul = styled.ul`
  list-style: none;
  padding: 0 30px;
  > li:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export const Li = styled.li<TransactionColor>`
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  border-top: 3px solid var(--color-primary);
  box-shadow: 2px 0px 5px gray;
  height: 200px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > div {
    flex: 45%;
  }

  > div:nth-child(4) {
    flex: 100%;
    max-width: 350px;
  }

  > div:last-child {
    border-top: 1px solid lightgray;
    padding-top: 10px;
    flex: 70%;
    justify-content: center;
  }

  @media (min-width: 800px) {
    flex-wrap: unset;
    height: 70px;
    padding: 0 10px;
    border-top: none;
    border-left: 3px solid var(--color-primary);

    > div {
      flex: unset;
    }

    > div:first-child {
      width: 19%;
      > strong {
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > div:nth-child(2) {
      min-width: 12%;
    }

    > div:nth-child(3) {
      width: 18%;
      min-width: 145px;
    }

    > div:nth-child(4) {
      width: 10%;
      min-width: 150px;
      max-width: 180px;
      margin-right: 25px;
    }

    > div:last-child {
      min-width: 5%;
      border-top: unset;
      padding-top: unset;
      flex: unset;
      justify-content: flex-end;
    }
  }
`;

export const CommonField = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > strong {
    font-size: 14px;
    color: #515a6a;
  }
`;
export const StatusField = styled.div<TransactionColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
  padding: 5px 11px;
  background-color: ${({ color }) => color};
  border-radius: 14px;
  color: white;
`;

export const ActionField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > svg:first-child {
    margin-right: 15px;
    color: #373737;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 30px 15px;
`;

export const PageSize = styled.div`
  position: relative;
  cursor: pointer;
  background: #6c757d;
  color: white;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 38px;
  border-radius: 4px;
  display: flex;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.15, "#6c757d")};
  }
`;

export const PageSizeValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 14px;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%);
  background-position: calc(100% - 17px) 7px, calc(100% - 12px) 7px;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  flex: 1;
`;

export const Sizes = styled.div`
  position: absolute;
  width: 100%;
  left: 20px;
  max-height: 400px;
  overflow-y: auto;
  top: calc(100% - 20px);
  background: white;

  box-shadow: 2px 2px 10px rgba(220, 220, 220, 1);
  border-radius: 5px;
  z-index: 5;
  color: var(--color-text-primary);
  > ul {
    > li {
      list-style: none;
      > option {
        font-size: 15px;
        padding: 5px 0 5px 7px;
        transition: background 0.2s;

        &:hover {
          color: ${shade(0.6, "#253451")};
          background: ${darken(0.1, "#fff")};
        }
      }
    }
    > li:not(:last-child) {
      list-style: none;
      border-bottom: 1px solid rgba(220, 220, 220, 0.5);
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
`;

export const PrevPage = styled.div<PaginationProps>`
  cursor: pointer;
  border: 1px solid lightgray;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding: 8px 12px;
  pointer-events: none;
  color: ${({ isDisabled }) =>
    isDisabled ? "lightgray" : "var(--color-primary)"};

  transition: 0.2s;
  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      pointer-events: unset;
      &:hover {
        background: #466aa9;
        color: #fff;
      }
    `};
`;

export const NextPage = styled.div<PaginationProps>`
  cursor: pointer;
  border: 1px solid lightgray;
  border-left: none;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  padding: 8px 12px;
  pointer-events: none;
  color: ${({ isDisabled }) =>
    isDisabled ? "lightgray" : "var(--color-primary)"};

  transition: 0.2s;
  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      pointer-events: unset;
      &:hover {
        background: #466aa9;
        color: #fff;
      }
    `};
`;
