const ORDERS_FILTER_OPTIONS = [
  { label: "Nome fantasia", value: "fantasy_name" },
  { label: "Entregador", value: "deliveryman_name" },
  { label: "Cidade", value: "cities_name" },
  { label: "Nome do cliente", value: "customers_name" },
  { label: "ID do pedido", value: "orders_id" },
];

const CANCELED_STATUS_ID = 6;
const DELIVERED_STATUS_ID = 5;
const WAITING_STATUS_ID = 1;
const ON_THE_WAY_WITHDRAWAL = 7;
const ON_THE_WAY = 4;
const ARRIVED_AT_COLLECTION = 7;

export { ORDERS_FILTER_OPTIONS, CANCELED_STATUS_ID, WAITING_STATUS_ID, DELIVERED_STATUS_ID, ON_THE_WAY_WITHDRAWAL, ON_THE_WAY, ARRIVED_AT_COLLECTION };
