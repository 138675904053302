import React from "react";
import { Link } from "react-router-dom";
import * as S from "./style";

const NotFound: React.FC = () => {
  return (
    <S.Container id="Not-found">
      <S.Image />
      <Link to="/" id="back">
        <S.ButtonBack>Voltar</S.ButtonBack>
      </Link>
    </S.Container>
  );
};

export default NotFound;
