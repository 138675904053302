import styled from "styled-components";

export const Container = styled.div`
  width: 800px;
`;

export const InitialStepContainer = styled.div`
  height: 500px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const InitialStepTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
`;

export const InitialStepActions = styled.div`
  display: flex;
  width: 50%;
  > button + button {
    margin-left: 10px;
  }
`;
