import React from "react";
import * as Styled from "./styles";

interface SelectButtonProps {
  onSelect: (selected: boolean) => void; // Função para lidar com a seleção do botão
  backgroundColor: string; // Cor de fundo do botão
  hoverColor: string; // Cor do botão ao passar o mouse por cima
  label: string; // Texto do rótulo do botão
  labelHeader: string; // Texto do rótulo do botão
  isSelected: boolean; // Indicador se o botão está selecionado ou não
}

const SelectButton: React.FC<SelectButtonProps> = ({
  onSelect,
  backgroundColor,
  hoverColor,
  label,
  labelHeader,
  isSelected,
}) => {
  const handleClick = () => {
    const newSelected = !isSelected; // Inverte o estado de isSelected
    onSelect(newSelected); // Chama a função onSelect com o novo estado
  };

  return (
    <Styled.Container>
      <Styled.Label backgroundColor={backgroundColor}>
        {labelHeader}
      </Styled.Label>
      <Styled.Button
        type="button"
        onClick={handleClick} // Chama handleClick quando o botão é clicado
        backgroundColor={isSelected ? hoverColor : backgroundColor} // Altera a cor de fundo com base no estado de isSelected
      >
        {label}
      </Styled.Button>
    </Styled.Container>
  );
};

export default SelectButton;
