import React, { useCallback, useState } from "react";
import {
  FiAtSign,
  FiBarChart2,
  FiEdit,
  FiHash,
  FiPhone,
  FiStar,
} from "react-icons/fi";
import { RiMotorbikeLine } from "react-icons/ri";
import { GiIdCard, GiKeyCard } from "react-icons/gi";
import { AiOutlineCar } from "react-icons/ai";
import Viewer from "react-viewer";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import Modal from "../Modal";
import LoadingSpinner from "../LoadingSpinner";

import api from "../../services/api";

import useStateCallback from "../../hooks/UseStateCallback";

import {
  cpfOrCnpjFormat,
  phoneFormat,
  rgFormat,
} from "../../utils/inputAndTextMasks";

import { DeliverymanFromGetDeliverymans } from "../../@types/customTypes";

import * as Styled from "./styles";

interface DeliverymanImages {
  src: string;
  alt: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  deliverymanImages: DeliverymanImages[];
  deliverymanData: DeliverymanFromGetDeliverymans;
  getDeliverymansData: (
    size?: any,
    page?: any,
    searchQuery?: any,
  ) => Promise<void>;
}

const DeliverymanDetailsModal: React.FC<IModalProps> = ({
  isOpen,
  deliverymanData,
  setIsOpen,
  deliverymanImages,
  getDeliverymansData,
}) => {
  const [isImagesVisible, setIsImagesVisible] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [imageIndex, setImageIndex] = useStateCallback<null | number>(null);

  const { addToast } = useToasts();

  const handleSetPreferentialMarkup = useCallback(async () => {
    const result = await Swal.fire({
      title: `Deseja ${
        deliverymanData.preferential ? "desmarcar" : "marcar"
      } este entregador como preferencial?`,
      html: `Tem certeza que deseja ${
        deliverymanData.preferential ? "desmarcar" : "marcar"
      } este entregador como preferencial?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--color-primary)",
      cancelButtonColor: "var(--color-danger)",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });

    if (result.isConfirmed) {
      try {
        setShowSpinner(true);
        await getDeliverymansData();
        await api.put(`deliverymans/${deliverymanData.id}`, {
          preferential: !deliverymanData.preferential,
        });

        addToast("Entregador atualizado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (err) {
        addToast("Falha ao marcar entregador como preferencial!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    }
  }, [
    deliverymanData.preferential,
    getDeliverymansData,
    addToast,
    deliverymanData.id,
  ]);

  const handleOpenImageViewer = useCallback(
    (index: number) => {
      setImageIndex(index, () => {
        setIsImagesVisible(true);
      });
    },
    [setImageIndex],
  );

  const handleCloseImageViewer = useCallback(() => {
    setIsImagesVisible(false);
    setImageIndex(null);
  }, [setImageIndex]);

  return (
    <>
      {showSpinner && <LoadingSpinner />}
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        customStyles={{ maxWidth: "650px", padding: "30px" }}
      >
        <Viewer
          visible={isImagesVisible}
          onClose={handleCloseImageViewer}
          images={deliverymanImages}
          activeIndex={imageIndex as number}
          onMaskClick={handleCloseImageViewer}
        />
        <Styled.Container>
          <Styled.DetailsContainer>
            <Styled.Strong>Dados do Entregador</Styled.Strong>
            <Styled.CommonField>
              <Styled.FieldTitle>
                <FiHash size={18} />
                <h3>ID</h3>
              </Styled.FieldTitle>
              <p>{deliverymanData.id}</p>
            </Styled.CommonField>

            <Styled.CommonField>
              <Styled.FieldTitle>
                <RiMotorbikeLine size={18} />
                <h3>Entregador</h3>
              </Styled.FieldTitle>
              <p>
                {deliverymanData.user.name} (
                <strong>{deliverymanData.nickname}</strong>)
              </p>
            </Styled.CommonField>

            <Styled.CommonField>
              <Styled.FieldTitle>
                <FiBarChart2 size={18} />
                <h3>Status</h3>
              </Styled.FieldTitle>
              <p>{deliverymanData.status.name}</p>
            </Styled.CommonField>

            {deliverymanData.cnh && (
              <Styled.CommonField>
                <Styled.FieldTitle>
                  <GiKeyCard size={22} />
                  <h3>CNH</h3>
                </Styled.FieldTitle>
                <p>{cpfOrCnpjFormat(deliverymanData.cnh)}</p>
              </Styled.CommonField>
            )}

            <Styled.CommonField>
              <Styled.FieldTitle>
                <GiIdCard size={18} />
                <h3>RG</h3>
              </Styled.FieldTitle>
              <p>{rgFormat(deliverymanData.rg)}</p>
            </Styled.CommonField>

            <Styled.CommonField>
              <Styled.FieldTitle>
                <FiAtSign size={18} />
                <h3>E-mail</h3>
              </Styled.FieldTitle>
              <p>{deliverymanData.user.email}</p>
            </Styled.CommonField>

            <Styled.CommonField>
              <Styled.FieldTitle>
                <FiPhone size={18} />
                <h3>Telefone</h3>
              </Styled.FieldTitle>
              <p>{phoneFormat(deliverymanData.phones[0].number)}</p>
            </Styled.CommonField>

            <Styled.CommonField>
              <Styled.FieldTitle>
                <AiOutlineCar size={18} />
                <h3>Veículo</h3>
              </Styled.FieldTitle>
              <p>{deliverymanData.vehicle.vehicle_type.name}</p>
            </Styled.CommonField>

            <Styled.CommonField>
              <Styled.FieldTitle>
                <FiStar size={18} />
                <h3>Preferencial</h3>
              </Styled.FieldTitle>
              <p>{deliverymanData.preferential ? "Sim" : "Não"}</p>
            </Styled.CommonField>

            {deliverymanData.files.length > 0 && (
              <>
                <Styled.Strong>Imagens do Entregador</Styled.Strong>
                <Styled.ImagesContainer>
                  {deliverymanData.files.map((file, fileIndex) => (
                    <Styled.ImageContainer key={file.id}>
                      <Styled.Image
                        src={`https://storage.googleapis.com/docs-loocal/public/deliverymans/${file.original_name}`}
                        alt={file.doc_type.name}
                        onClick={() => handleOpenImageViewer(fileIndex)}
                      />
                      <Styled.ImageTitle>
                        {file.doc_type.name}
                      </Styled.ImageTitle>
                    </Styled.ImageContainer>
                  ))}
                </Styled.ImagesContainer>
              </>
            )}
          </Styled.DetailsContainer>
          <Styled.ActionContainer>
            <Styled.Link to={`entregadores/${deliverymanData.id}`}>
              <FiEdit size={20} />
            </Styled.Link>

            <FiStar size={22} onClick={handleSetPreferentialMarkup} />
          </Styled.ActionContainer>
        </Styled.Container>
      </Modal>
    </>
  );
};

export default DeliverymanDetailsModal;
