import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;

  @media (min-width: 800px) {
    padding: 30px;
  }
`;

export const Strong = styled.h2`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 10px;
  color: #373737;
`;

export const Subtitle = styled.h4`
  color: #373737;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const PeriodText = styled.h4`
  color: gray;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 80%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 15px;
`;

export const OrdersQuantity = styled.div`
  align-items: center;
  justify-content: center;
`;

export const CommonField = styled.div`
  display: flex;

  align-items: center;
  margin-bottom: 10px;

  > p {
    color: #373737;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;

    > strong {
      font-weight: 500;
    }
  }

  @media (min-width: 700px) {
    > p {
      margin-left: 50px;
      margin-top: 0;
      align-items: unset;
    }
  }
`;

export const FieldTitle = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  min-width: 178px;

  > h3 {
    color: ${({ color }) => color};
    margin-left: 8px;
    font-weight: bold;
  }
  > svg {
    color: var(--color-primary);
  }
`;
