import styled, { keyframes } from "styled-components";
import { lighten } from "polished";

import contactBg from "../../assets/img/contact-bg.webp";

export const Container = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  background: white;
  overflow: hidden;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: ${appearFromRight} 1s;

  > img {
    width: 280px;
  }
`;

export const ContactContainer = styled.div`
  margin: 70px 0;
  width: 340px;
  > h1 {
    text-align: center;
    color: #373737;
    font-size: 23px;
    margin-bottom: 24px;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #373737;
    text-decoration: none;
    transition: 0.3s;
    margin-top: 50px;
    &:hover {
      color: ${lighten(0.25, "#373737")};
      > svg {
        stroke: ${lighten(0.25, "#373737")};
      }
    }

    > svg {
      stroke: #373737;
      margin-right: 10px;
      transition: 0.3s;
    }
  }

  @media (min-width: 700px) {
    width: 30rem;
    padding: 2rem;
  }
`;

export const ContactChannels = styled.div`
  margin-bottom: 15px;
  border: 1px dashed #89cff0;
  border-radius: 8px;
  background: rgba(128, 128, 128, 0.1);
  padding: 25px 10px;

  > h3 {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  > svg {
    color: var(--color-primary);
    margin-right: 5px;
  }
  > span {
    color: var(--color-text-secondary);
    margin-right: 10px;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${contactBg}) no-repeat center;
  background-size: cover;
`;
