import { lighten } from "polished";
import styled from "styled-components";

export const CloseModalBtn = styled.div`
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
  display: flex;
  width: 24px;
  height: 24px;
  background: transparent;
  z-index: 999999999999;
  svg {
    color: #373737;
    width: 100%;
    height: 100%;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#373737")};
    }
  }
`;
