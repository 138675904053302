import React, { useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { formatCurrencyByText } from "../../utils/inputAndTextMasks";

import Modal from "../Modal";
import CopyInput from "../CopyInput";

import * as Styled from "./styles";

interface PixDetails {
  amount: string | null;
  QRCodeBase64: string | null;
  QRCodeCopiaCola: string | null;
}

interface ModalProps {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  pixDetails: PixDetails;
}

const QRCodeModal: React.FC<ModalProps> = ({
  handleCloseModal,
  isModalOpen,
  pixDetails,
}) => {
  const { addToast } = useToasts();

  const copyCopiaEColaToClipboard = useCallback(
    (copiaEColaRef: React.MutableRefObject<HTMLInputElement | null>) => {
      copiaEColaRef.current?.select();
      document.execCommand("copy");

      addToast("Código copiado para sua área de transferência!", {
        appearance: "success",
        autoDismiss: true,
      });
    },
    [addToast],
  );

  return (
    <Modal
      setIsOpen={handleCloseModal}
      isOpen={isModalOpen}
      customStyles={{
        width: "300px",
        background: "white",
      }}
    >
      <Styled.Container>
        <Styled.Title>Pague agora mesmo!</Styled.Title>
        <Styled.HR />
        <Styled.SubTitle>QR Code</Styled.SubTitle>
        <img src={pixDetails?.QRCodeBase64 || undefined} alt="QR Code" />
        <Styled.SubTitle>Pix Copia e Cola</Styled.SubTitle>

        <CopyInput
          type="text"
          placeholder="Nome do Cliente"
          name="copyInput"
          id="copyInput"
          onClickCopy={copyCopiaEColaToClipboard}
          value={pixDetails?.QRCodeCopiaCola || undefined}
        />

        <Styled.Details>
          {formatCurrencyByText(pixDetails.amount || "0")}
        </Styled.Details>
      </Styled.Container>
    </Modal>
  );
};

export default QRCodeModal;
