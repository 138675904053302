import { darken, lighten } from "polished";
import styled from "styled-components";
import { Form as Unform } from "@unform/web";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

interface TransactionColor {
  color: string;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const TransactionsContainer = styled.div`
  padding: 20px 30px;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
  margin-bottom: 40px;
`;

export const TopBar = styled.div`
  padding-bottom: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
`;

export const Buttons = styled.div`
  display: flex;

  > div:not(:last-child) {
    margin-right: 20px;
  }
`;

export const Link = styled.div`
  background: #6a64d9;
  padding: 5px 14px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: 12px;

  &:hover {
    background: ${darken("0.25", "#6a64d9")};
  }
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
  > svg {
    color: #373737;
    margin-left: 25px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const Strong = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 20px;
  color: #373737;
`;

export const NoContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 25px;
  color: #373737;
  height: 30vh;
  text-align: center;
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  margin-bottom: 15px;
  > svg {
    margin-top: 10px;

    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }

  @media (min-width: 500px) {
    flex-direction: row;
    margin: 0 0 20px 25px;
    > svg {
      margin-left: 15px;
      margin-top: 0;
    }
  }
`;

export const TransactionsShimmer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    width: 100%;
    height: 240px;
    border-radius: 4px;
  }

  @media (min-width: 800px) {
    > div {
      height: 70px;
    }
  }

  > div:not(:first-child) {
    margin-top: 3px;
  }
`;

export const ListContainer = styled.div``;

export const Ul = styled.ul`
  list-style: none;
  > li:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (min-width: 500px) {
    padding: 0 15px;
  }
`;

export const Li = styled.li<TransactionColor>`
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  border-top: 3px solid ${({ color }) => color};
  box-shadow: 2px 0px 5px gray;
  height: 240px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > div {
    flex: 45%;
  }

  > div:nth-child(5) {
    flex: 100%;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }

  > div:last-child {
    flex: 70%;
    justify-content: center;
  }

  @media (min-width: 800px) {
    flex-wrap: unset;
    height: 70px;
    padding: 0 10px;
    border-top: none;
    border-left: 3px solid ${({ color }) => color};

    > div {
      flex: unset;
    }

    > div:first-child {
      width: 7%;
    }
    > div:nth-child(2) {
      width: 9%;
    }

    > div:nth-child(3) {
      min-width: 12%;
    }

    > div:nth-child(4) {
      min-width: 15%;
    }
    > div:nth-child(5) {
      min-width: 21%;
      border-bottom: none;
      flex: unset;
      padding-bottom: 0;
    }

    > div:last-child {
      max-width: 25%;
      min-width: 165px;
      flex: unset;
    }
  }
`;

export const CommonField = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > strong {
    font-size: 14px;
    color: #515a6a;
  }
`;
export const StatusField = styled.div<TransactionColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
  padding: 5px 11px;
  background-color: ${({ color }) => color};
  border-radius: 14px;
  color: white;
  text-align: center;
`;

export const ActionField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > svg:first-child {
    margin-right: 10px;
  }

  > svg {
    stroke: #373737;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      stroke: ${lighten(0.25, "#466aa9")};
    }
  }
`;
