import { darken } from "polished";
import styled from "styled-components";

interface Button {
  padding?: string;
  customColor?: string | null;
}

export const StyledButton = styled.button<Button>`
  height: 2.5rem;
  width: 100%;
  background: ${({ customColor, disabled }) => {
    if (customColor) return customColor;

    if (disabled) return "lightgray";

    return "var(--color-primary)";
  }};
  color: white;
  border: none;
  border-radius: 2px !important;
  padding: ${({ padding }) => padding};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem !important;
  cursor: ${({ disabled }) => !disabled && "pointer"};
  outline: none !important;
  transition: background 0.5s;

  &:hover:not(:disabled) {
    background: ${darken(0.05, "#466aa9")};
  }
`;
