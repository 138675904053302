import styled from "styled-components";
import { Form as Unform } from "@unform/web";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const Strong = styled.h1`
  font-weight: 500;
  font-size: 18px;
  color: #373737;
`;

export const Form = styled(Unform)`
  min-width: 80%;

  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Subtitle = styled.h4`
  color: #373737;
  margin: 5px 0 10px 0;
  text-align: center;
`;

export const AddressContainer = styled.div`
  > div:last-child {
    margin-top: 15px;
  }

  @media (min-width: 900px) {
    display: flex;

    > div:last-child {
      width: 50%;
      margin-left: 15px;
      margin-top: 0;
    }
  }
`;

export const RadioOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  > p {
    margin-bottom: 5px;
    color: #373737;
  }

  > div {
    display: flex;
    > label:first-child {
      margin-right: 15px;
    }
  }
`;
