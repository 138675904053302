import styled, { keyframes } from "styled-components";
import { darken, lighten } from "polished";

import contractBackground from "../../assets/img/contract-bg.webp";

export const Container = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  background: white;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const AnimationContainer = styled.div`
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #373737;
    text-decoration: none;
    margin-bottom: 30px;
    padding-bottom: 15px;
    transition: 0.3s;
    border-bottom: 1px solid rgba(122, 111, 122, 0.1);

    > svg {
      margin-right: 10px;
      stroke: #373737;
      transition: 0.3s;
    }
    &:hover {
      color: ${lighten(0.25, "#373737")};
      > svg {
        stroke: ${lighten(0.25, "#373737")};
      }
    }
  }

  overflow-y: scroll;
  padding: 15px;
  color: gray;
  animation: ${fadeIn} 1s;

  > strong {
    color: ${darken(0.25, "gray")};
    font-weight: 500;
  }

  > img {
    width: 280px;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${contractBackground}) no-repeat center;
  background-size: cover;
`;
