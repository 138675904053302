import React from "react";
import {
  FiAtSign,
  FiBarChart2,
  FiCalendar,
  FiCheckCircle,
  FiDollarSign,
  FiPhone,
} from "react-icons/fi";
import { AiOutlineBank } from "react-icons/ai";
import { RiMotorbikeLine, RiCoinsLine } from "react-icons/ri";
import { GiReceiveMoney, GiBank, GiIdCard } from "react-icons/gi";
import { FaPiggyBank } from "react-icons/fa";

import Modal from "../Modal";

import {
  cpfOrCnpjFormat,
  formatCurrencyByText,
  phoneFormat,
} from "../../utils/inputAndTextMasks";
import formatLocale from "../../utils/formatLocale";

import { Withdraw } from "../../@types/customTypes";

import * as Styled from "./styles";

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleMarkWithdrawAsPaid(
    name: string,
    amount: number,
    type: string,
    requestId: number | null,
    paid: number | null,
  ): Promise<void>;
  withdrawData: Withdraw;
}

const WithdrawDetailsModal: React.FC<IModalProps> = ({
  isOpen,
  withdrawData,
  type,
  handleMarkWithdrawAsPaid,
  setIsOpen,
}) => {
  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      customStyles={{ maxWidth: "650px", padding: "30px" }}
    >
      <Styled.Container>
        <Styled.DetailsContainer>
          <Styled.Strong>Dados do Entregador</Styled.Strong>
          <Styled.CommonField>
            <Styled.FieldTitle>
              <RiMotorbikeLine size={18} />
              <h3>Entregador</h3>
            </Styled.FieldTitle>

            <p>
              {withdrawData?.deliveryman?.name}
              {withdrawData?.deliveryman?.nickname ? (
                <strong>({withdrawData?.deliveryman?.nickname})</strong>
              ) : (
                <></>
              )}
            </p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <GiIdCard size={18} />
              <h3>Documento</h3>
            </Styled.FieldTitle>
            <p>
              {cpfOrCnpjFormat(withdrawData?.deliveryman?.bank_account?.doc)}
            </p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiAtSign size={18} />
              <h3>E-mail</h3>
            </Styled.FieldTitle>
            <p>{withdrawData?.deliveryman?.email}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiPhone size={18} />
              <h3>Telefone</h3>
            </Styled.FieldTitle>
            <p>{phoneFormat(withdrawData?.deliveryman?.phone || "")}</p>
          </Styled.CommonField>

          <Styled.Strong>Dados do Resgate</Styled.Strong>
          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiBarChart2 size={18} />
              <h3>Saque rápido</h3>
            </Styled.FieldTitle>
            <p>{withdrawData.is_fast_withdraw ? "Sim" : "Não"}</p>
          </Styled.CommonField>
          <Styled.CommonField>
            <Styled.FieldTitle>
              <GiReceiveMoney size={18} />
              <h3>Valor do Resgate</h3>
            </Styled.FieldTitle>
            <p>
              {formatCurrencyByText(
                withdrawData.amount || withdrawData.requested_value,
              )}
            </p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <RiCoinsLine size={18} />
              <h3>Taxa</h3>
            </Styled.FieldTitle>
            <p>
              {formatCurrencyByText(
                withdrawData.fee ||
                  withdrawData.requested_value - (withdrawData?.value || 0),
              )}
            </p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiCalendar size={18} />
              <h3>Criado em</h3>
            </Styled.FieldTitle>
            <p>
              {formatLocale(withdrawData.created_at, "dd/MM/yyyy ' ' HH:mm")}
            </p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiCalendar size={18} />
              <h3>Transferido em</h3>
            </Styled.FieldTitle>
            <p>
              {withdrawData.transfered_at
                ? formatLocale(
                    withdrawData.transfered_at,
                    "dd/MM/yyyy ' ' HH:mm",
                  )
                : ""}
              {withdrawData.request_date
                ? formatLocale(
                    withdrawData.request_date,
                    "dd/MM/yyyy ' ' HH:mm",
                  )
                : ""}

              {!withdrawData.request_date && !withdrawData.request_date
                ? "N/A"
                : ""}
            </p>
          </Styled.CommonField>
          <Styled.Strong>Dados Bancários</Styled.Strong>
          {withdrawData?.type?.name !== "Pix" ? (
            <>
              <Styled.CommonField>
                <Styled.FieldTitle>
                  <AiOutlineBank size={18} />
                  <h3>Instituição</h3>
                </Styled.FieldTitle>
                <p>
                  {withdrawData?.deliveryman?.bank_account?.bank} (
                  <strong>
                    {withdrawData?.deliveryman?.bank_account?.bank_id}
                  </strong>
                  )
                </p>
              </Styled.CommonField>

              <Styled.CommonField>
                <Styled.FieldTitle>
                  <FiDollarSign size={18} />
                  <h3>Tipo de Conta</h3>
                </Styled.FieldTitle>
                <p>{withdrawData?.deliveryman?.bank_account?.account_type}</p>
              </Styled.CommonField>

              <Styled.CommonField>
                <Styled.FieldTitle>
                  <GiBank size={18} />
                  <h3>Agência</h3>
                </Styled.FieldTitle>
                <p>{withdrawData?.deliveryman?.bank_account?.agency}</p>
              </Styled.CommonField>

              <Styled.CommonField>
                <Styled.FieldTitle>
                  <FaPiggyBank size={18} />
                  <h3>Conta</h3>
                </Styled.FieldTitle>
                <p>{withdrawData?.deliveryman?.bank_account?.account}</p>
              </Styled.CommonField>
            </>
          ) : (
            <Styled.CommonField>
              <Styled.FieldTitle>
                <AiOutlineBank size={18} />
                <h3>Chave PIX</h3>
              </Styled.FieldTitle>
              <p>{withdrawData?.key_pix_requester}</p>
            </Styled.CommonField>
          )}
        </Styled.DetailsContainer>
        {type !== "pix" ? (
          <Styled.ActionContainer>
            <FiCheckCircle
              size={30}
              onClick={() => {
                handleMarkWithdrawAsPaid(
                  withdrawData?.deliveryman?.name || withdrawData?.name,
                  withdrawData?.amount || withdrawData?.requested_value,
                  type,
                  withdrawData.request_id,
                  null,
                );
                setIsOpen();
              }}
            />
          </Styled.ActionContainer>
        ) : (
          withdrawData.status.status_id === 5 && (
            <Styled.ActionContainer>
              <FiCheckCircle
                size={30}
                onClick={() => {
                  handleMarkWithdrawAsPaid(
                    withdrawData?.deliveryman?.name || withdrawData?.name,
                    withdrawData?.amount || withdrawData?.requested_value,
                    type,
                    withdrawData.request_id,
                    withdrawData?.value || null,
                  );
                  setIsOpen();
                }}
              />
            </Styled.ActionContainer>
          )
        )}
      </Styled.Container>
    </Modal>
  );
};

export default WithdrawDetailsModal;
