import React, { useCallback, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { FiBriefcase, FiSearch } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import api from "../../services/api";

import { useSidebar } from "../../hooks/SidebarContext";
import useStateCallback from "../../hooks/UseStateCallback";

import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import FinancesModal from "../../components/FinancesModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import getValidationErrors from "../../utils/getValidationErrors";
import { formatCurrencyByText } from "../../utils/inputAndTextMasks";
import formatLocale from "../../utils/formatLocale";

import * as Styled from "./styles";
import { CompanyFromGetCompanies } from "../../@types/customTypes";
import AsyncReactSelect from "../../components/AsyncReactSelect";

interface Transaction {
  id: number;
  user_id: null | number;
  transaction_type_id: number;
  transaction: string;
  amount: string;
  is_credit: true;
  balance: string;
  blocked_balance: string;
  created_at: string;
  color?: string;
}

interface TransactionsResponse {
  data: Transaction[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

interface FinancesListFormData {
  company_id: number;
  company_name: string;
}

interface GetCompaniesApiResponse {
  current_page: number;
  data: CompanyFromGetCompanies[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

const EstablishmentsFinances: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>(
    [] as Transaction[],
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState<null | boolean>(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentType, setCurrentType] = useStateCallback<null | string>(null);
  const [currentCompanyId, setCurrentCompanyId] = useState<null | number>(null);

  const formRef = useRef<FormHandles>(null);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();

  const loadSelectCompanyOptions = useCallback(
    async (inputValue: string, callback: any) => {
      if (!inputValue) {
        return;
      }

      try {
        const { data: SearchResultData } =
          await api.get<GetCompaniesApiResponse>(
            `companies?search=fantasy_name:${inputValue}`,
          );

        callback(
          SearchResultData.data
            .map((result, optionIndex: number) => {
              if (optionIndex < 6) {
                return {
                  value: result.id,
                  label: `${result.id} - ${result.fantasy_name}`,
                  companyCityId: result.address.city_id,
                  companyLat: result.address.latitude,
                  companyLng: result.address.longitude,
                };
              }
              return false;
            })
            .filter((result) => result !== false),
        );
      } catch (e) {
        addToast("Falha ao carregar lista de estabelecimentos!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const handleGetTransactions = useCallback(
    async (size = 6, page = 1, company_id = 10) => {
      try {
        setIsLoading(true);
        const { data: transactionsResponse } =
          await api.get<TransactionsResponse>(
            `companies/${company_id}/transactions?page[size]=${size}&page[number]=${page}`,
          );

        transactionsResponse.data.forEach((transaction) => {
          switch (transaction.transaction) {
            case "Recarga cancelada":
              transaction.color = "#c53030";
              break;
            case "Recarga com Transferência Bancária":
              transaction.color = "#e5e619";
              break;
            case "Recarga com Boleto":
              transaction.color = "#DB7093";
              break;
            case "Pagamento de entrega":
              transaction.color = "#663399";
              break;
            case "Recarga com Cartão de Crédito":
              transaction.color = "blue";
              break;
            case "Pagamento de taxa":
              transaction.color = "olive";
              break;
            case "Estorno":
              transaction.color = "gray";
              break;
            default:
              break;
          }
        });
        setCurrentPage(page);
        setTotalPages(transactionsResponse.meta.last_page);
        setTransactions(transactionsResponse.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const handleOpenModal = useCallback(
    (type: string) => {
      setCurrentType(type, () => setIsModalOpen(true));
    },
    [setIsModalOpen, setCurrentType],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setTimeout(() => {
      setCurrentType(null);
    }, 300);
  }, [setIsModalOpen, setCurrentType]);

  const handleUpdateFinancesList = useCallback(
    (data: FinancesListFormData) => {
      handleGetTransactions(8, 1, data.company_id);
      setCurrentCompanyId(data.company_id);
    },
    [handleGetTransactions],
  );

  const handleSubmitFinancesListForm = useCallback(
    async (data: FinancesListFormData) => {
      try {
        const schema = Yup.object().shape({
          company_id: Yup.string().required("Campo obrigatório"),
        });
        await schema.validate(data, { abortEarly: false });
        handleUpdateFinancesList(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleUpdateFinancesList],
  );

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <FinancesModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        setShowSpinner={setShowSpinner}
        currentType={currentType as string}
      />
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.TransactionsContainer>
          <Styled.TopBar>
            <Styled.Title>Financeiro - Estabelecimentos</Styled.Title>
            <Styled.Buttons>
              <Styled.Link
                onClick={() => handleOpenModal("companyTransaction")}
              >
                + Nova Transação
              </Styled.Link>
            </Styled.Buttons>
          </Styled.TopBar>
          <Styled.Strong>Histórico de transações:</Styled.Strong>
          <Styled.Form ref={formRef} onSubmit={handleSubmitFinancesListForm}>
            <AsyncReactSelect
              id="company_id"
              name="company_id"
              placeholder="Estabelecimento"
              formRef={formRef}
              icon={FiBriefcase}
              loadOptions={loadSelectCompanyOptions}
              isSearchable
            />
            <FiSearch size={25} onClick={() => formRef.current?.submitForm()} />
          </Styled.Form>
          <Styled.ListContainer>
            {isLoading === null && (
              <Styled.NoContent>
                Selecione um estabelecimento...
              </Styled.NoContent>
            )}
            {isLoading !== null && isLoading ? (
              <Styled.TransactionsShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.TransactionsShimmer>
            ) : (
              <Styled.Ul>
                {transactions.map((transaction) => {
                  return (
                    <Styled.Li
                      color={transaction.color || "lightblue"}
                      key={transaction.id}
                    >
                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>{transaction.id}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Tipo:</span>
                        <strong>
                          {transaction.is_credit ? "Crédito" : "Débito"}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Valor:</span>
                        <strong>
                          {formatCurrencyByText(transaction.amount)}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Balance:</span>
                        <strong>
                          {formatCurrencyByText(transaction.balance)}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Data:</span>
                        <strong>
                          {formatLocale(
                            transaction.created_at,
                            "dd/MM/yyyy HH:mm",
                          )}
                        </strong>
                      </Styled.CommonField>
                      <Styled.StatusField
                        color={transaction.color || "lightblue"}
                      >
                        {transaction.transaction}
                      </Styled.StatusField>
                    </Styled.Li>
                  );
                })}
              </Styled.Ul>
            )}

            {currentPage !== 0 && (
              <Pagination
                updateList={handleGetTransactions}
                totalPages={totalPages}
                currentPage={currentPage}
                companyId={currentCompanyId}
              />
            )}
          </Styled.ListContainer>
        </Styled.TransactionsContainer>
      </ContainerMain>
    </Container>
  );
};

export default EstablishmentsFinances;
