import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FiHome,
  FiFileText,
  FiFilePlus,
  FiMinimize2,
  FiMaximize2,
  FiDollarSign,
  FiCheckSquare,
  FiSettings,
} from "react-icons/fi";
import { Helmet } from "react-helmet";
import { AiOutlinePoweroff } from "react-icons/ai";
import { RiUserSettingsLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";

import { useAuth } from "../../hooks/AuthContext";
import { useSidebar } from "../../hooks/SidebarContext";

import supportService from "../../services/supportService";

import logoImg from "../../assets/img/logo-movio.webp";
import logoMin from "../../assets/img/logo-min.svg";

import * as Styled from "./styles";

interface CollapseNavStatesProps {
  gerenciar: boolean;
  financeiro: boolean;
  meusDados: boolean;
  financeiroComercio: boolean;
}

interface CollapseNavItemsListProps {
  name: string;
  stateName: "gerenciar" | "financeiro" | "meusDados" | "financeiroComercio";
  pathnames: { pathname: string; name: string }[];
  collapseItems: JSX.Element[];
  icon: JSX.Element;
  exclude?: string[];
}

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  const { signOut, user_name, role, fantasy_name } = useAuth();
  const {
    isSidebarMaximized,
    handleToggleSidebar,
    collapseNavStates,
    setCollapseNavStates,
  } = useSidebar();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleSetNavMinVisibility = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const handleToggleCollapseNavButton = useCallback(
    (path: "gerenciar" | "financeiro" | "meusDados" | "financeiroComercio") => {
      if (!isSidebarMaximized) {
        handleToggleSidebar();
      }
      setCollapseNavStates((state) => {
        const newState = {};
        Object.keys(state).forEach((key) => {
          if (key === path) {
            Object.assign(newState, { [key]: !state[key] });
          } else {
            Object.assign(newState, { [key]: false });
          }
        });
        return newState as CollapseNavStatesProps;
      });
    },
    [isSidebarMaximized, handleToggleSidebar, setCollapseNavStates],
  );

  /**
   * NonCollapse -> Add new items to nonCollapsePathnames and it's done =)
   *
   */

  const nonCollapsePathnames = useMemo(
    () => [
      {
        pathname: "/pedidos",
        name: "Meus Pedidos",
        icon: <FiFileText size={18} />,
        exclude: ["financial"],
      },
      {
        pathname: "/despachos",
        name: "Despachos",
        icon: <FiCheckSquare size={18} />,
        exclude: ["admin", "operator", "financial", "superAdmin"],
      },
      {
        pathname: "/novo-pedido",
        name: "Novo Pedido",
        icon: <FiFilePlus size={18} />,
        exclude: ["financial"],
      },
      {
        pathname: "/integra-ifood",
        name: "Integração Ifood",
        icon: <FiSettings size={18} />,
        exclude: ["admin", "operator", "financial", "superAdmin"],
      },
    ],
    [],
  );

  const lastItems = useMemo(
    () => [
      {
        pathname: "/dashboard",
        name: "Dashboard",
        icon: <FiHome size={18} />,
      },
    ],
    [],
  );

  const nonCollapseNavLastItems = useMemo(
    () =>
      lastItems.map((nonPath, index) => {
        if (nonPath.exclude) {
          let shouldReturnComponent = true;
          nonPath.exclude.forEach((nonPathExclude) => {
            switch (nonPathExclude) {
              case "financial":
                if (role === process.env.REACT_APP_FINANCIAL_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "operator":
                if (role === process.env.REACT_APP_OPERATOR_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "admin":
                if (role === process.env.REACT_APP_MAIN_OFFICE_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "superAdmin":
                if (role === process.env.REACT_APP_ADMIN_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "client":
                if (
                  role !== process.env.REACT_APP_ADMIN_ROLE &&
                  role !== process.env.REACT_APP_OPERATOR_ROLE &&
                  role !== process.env.REACT_APP_FINANCIAL_ROLE &&
                  role !== process.env.REACT_APP_MAIN_OFFICE_ROLE
                ) {
                  shouldReturnComponent = false;
                }
                break;
              default:
                break;
            }
          });

          if (!shouldReturnComponent) {
            return null;
          }
        }

        return (
          <Styled.NavItem
            isSidebarMaximized={isSidebarMaximized}
            isCurrentPage={pathname === nonPath.pathname}
            key={index + Math.random()}
          >
            <Styled.NavButton to={nonPath.pathname}>
              {nonPath.icon} <span>{nonPath.name}</span>
            </Styled.NavButton>
          </Styled.NavItem>
        );
      }),
    [isSidebarMaximized, lastItems, pathname, role],
  );

  const nonCollapseNavItems = useMemo(
    () =>
      nonCollapsePathnames.map((nonPath, index) => {
        if (nonPath.exclude) {
          let shouldReturnComponent = true;
          nonPath.exclude.forEach((nonPathExclude) => {
            switch (nonPathExclude) {
              case "financial":
                if (role === process.env.REACT_APP_FINANCIAL_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "operator":
                if (role === process.env.REACT_APP_OPERATOR_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "admin":
                if (role === process.env.REACT_APP_MAIN_OFFICE_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "superAdmin":
                if (role === process.env.REACT_APP_ADMIN_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "client":
                if (
                  role !== process.env.REACT_APP_ADMIN_ROLE &&
                  role !== process.env.REACT_APP_OPERATOR_ROLE &&
                  role !== process.env.REACT_APP_FINANCIAL_ROLE &&
                  role !== process.env.REACT_APP_MAIN_OFFICE_ROLE
                ) {
                  shouldReturnComponent = false;
                }
                break;
              default:
                break;
            }
          });

          if (!shouldReturnComponent) {
            return null;
          }
        }

        return (
          <Styled.NavItem
            isSidebarMaximized={isSidebarMaximized}
            isCurrentPage={pathname === nonPath.pathname}
            key={index + Math.random()}
          >
            <Styled.NavButton to={nonPath.pathname}>
              {nonPath.icon} <span>{nonPath.name}</span>
            </Styled.NavButton>
          </Styled.NavItem>
        );
      }),
    [nonCollapsePathnames, isSidebarMaximized, pathname, role],
  );

  /**
   * Collapse sub items -> Add new items here, modify collapseNavItemsList, the typescript props and the collapseStates at hooks/sidebarContext =)
   *
   */

  const gerenciarCollapsePathnames = useMemo(
    () => [
      { pathname: "/gerenciar/operadores", name: "Operadores" },
      { pathname: "/gerenciar/estabelecimentos", name: "Estabelecimentos" },
      { pathname: "/gerenciar/entregadores", name: "Entregadores" },
      { pathname: "/gerenciar/por-cidade", name: "Por Cidade" },
    ],
    [],
  );

  const financeiroCollapsePathnames = useMemo(
    () => [
      { pathname: "/financeiro/entregadores", name: "Entregadores" },
      { pathname: "/financeiro/estabelecimentos", name: "Estabelecimentos" },
    ],
    [],
  );

  const financeiroComercioCollapsePathnames = useMemo(
    () => [
      { pathname: "/financeiro/creditos", name: "Inserir créditos" },
      { pathname: "/financeiro/extrato", name: "Extrato" },
    ],
    [],
  );

  const meusDadosCollapsePathnames = useMemo(
    () => [
      { pathname: "/meus-dados/responsavel", name: "Responsável" },
      { pathname: "/meus-dados/estabelecimento", name: "Estabelecimento" },
    ],
    [],
  );

  const gerenciarCollapseItems = useMemo(
    () =>
      gerenciarCollapsePathnames.map((genPath, index) => (
        <Styled.CollapseItem
          isCurrentPage={pathname.includes(genPath.pathname)}
          key={index + Math.random()}
        >
          <Styled.CollapseItemButton to={genPath.pathname}>
            •&nbsp;&nbsp; {genPath.name}
          </Styled.CollapseItemButton>
        </Styled.CollapseItem>
      )),
    [gerenciarCollapsePathnames, pathname],
  );

  const financeiroCollapseItems = useMemo(
    () =>
      financeiroCollapsePathnames.map((finPath, index) => (
        <Styled.CollapseItem
          isCurrentPage={pathname.includes(finPath.pathname)}
          key={index + Math.random()}
        >
          <Styled.CollapseItemButton to={finPath.pathname}>
            •&nbsp;&nbsp; {finPath.name}
          </Styled.CollapseItemButton>
        </Styled.CollapseItem>
      )),
    [financeiroCollapsePathnames, pathname],
  );

  const financeiroComercioCollapseItems = useMemo(
    () =>
      financeiroComercioCollapsePathnames.map((finCPath, index) => (
        <Styled.CollapseItem
          isCurrentPage={pathname.includes(finCPath.pathname)}
          key={index + Math.random()}
        >
          <Styled.CollapseItemButton to={finCPath.pathname}>
            •&nbsp;&nbsp; {finCPath.name}
          </Styled.CollapseItemButton>
        </Styled.CollapseItem>
      )),
    [financeiroComercioCollapsePathnames, pathname],
  );

  const meusDadosCollapseItems = useMemo(
    () =>
      meusDadosCollapsePathnames.map((myPath, index) => (
        <Styled.CollapseItem
          isCurrentPage={pathname.includes(myPath.pathname)}
          key={index + Math.random()}
        >
          <Styled.CollapseItemButton to={myPath.pathname}>
            •&nbsp;&nbsp; {myPath.name}
          </Styled.CollapseItemButton>
        </Styled.CollapseItem>
      )),
    [meusDadosCollapsePathnames, pathname],
  );

  /**
   * Collapse main items -> Add new items to collapse sub items then modify here :)
   *
   */

  const collapseNavItemsList: CollapseNavItemsListProps[] = useMemo(
    () => [
      {
        name: "Gerenciar",
        stateName: "gerenciar",
        pathnames: gerenciarCollapsePathnames,
        collapseItems: gerenciarCollapseItems,
        icon: <RiUserSettingsLine size={19} />,
        exclude: ["financial", "client"],
      },
      {
        name: "Financeiro",
        stateName: "financeiro",
        pathnames: financeiroCollapsePathnames,
        collapseItems: financeiroCollapseItems,
        icon: <FiDollarSign size={18} />,
        exclude: ["operator", "client"],
      },
      {
        name: "Meus Dados",
        stateName: "meusDados",
        pathnames: meusDadosCollapsePathnames,
        collapseItems: meusDadosCollapseItems,
        icon: <RiUserSettingsLine size={20} />,
        exclude: ["admin", "operator", "financial", "superAdmin"],
      },
      {
        name: "Financeiro",
        stateName: "financeiroComercio",
        pathnames: financeiroComercioCollapsePathnames,
        collapseItems: financeiroComercioCollapseItems,
        icon: <FiDollarSign size={18} />,
        exclude: ["admin", "operator", "financial", "superAdmin"],
      },
    ],
    [
      gerenciarCollapseItems,
      financeiroCollapseItems,
      meusDadosCollapseItems,
      gerenciarCollapsePathnames,
      meusDadosCollapsePathnames,
      financeiroCollapsePathnames,
      financeiroComercioCollapseItems,
      financeiroComercioCollapsePathnames,
    ],
  );

  const collapseNavItems = useMemo(
    () =>
      collapseNavItemsList.map((colListItem, index) => {
        if (colListItem.exclude) {
          let shouldReturnComponent = true;
          colListItem.exclude.forEach((colListItemExclude) => {
            switch (colListItemExclude) {
              case "financial":
                if (role === process.env.REACT_APP_FINANCIAL_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "operator":
                if (role === process.env.REACT_APP_OPERATOR_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "admin":
                if (role === process.env.REACT_APP_MAIN_OFFICE_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "superAdmin":
                if (role === process.env.REACT_APP_ADMIN_ROLE) {
                  shouldReturnComponent = false;
                }
                break;
              case "client":
                if (
                  role !== process.env.REACT_APP_ADMIN_ROLE &&
                  role !== process.env.REACT_APP_OPERATOR_ROLE &&
                  role !== process.env.REACT_APP_FINANCIAL_ROLE &&
                  role !== process.env.REACT_APP_MAIN_OFFICE_ROLE
                ) {
                  shouldReturnComponent = false;
                }
                break;
              default:
                break;
            }
          });

          if (!shouldReturnComponent) {
            return null;
          }
        }

        return (
          <Styled.CollapseNavItem
            isSidebarMaximized={isSidebarMaximized}
            isCurrentPage={colListItem.pathnames.some((colListItemPaths) =>
              pathname.includes(colListItemPaths.pathname),
            )}
            isCollapsed={collapseNavStates[colListItem.stateName]}
            key={index + Math.random()}
          >
            <Styled.CollapseNavButton
              onClick={() =>
                handleToggleCollapseNavButton(colListItem.stateName)
              }
            >
              {colListItem.icon}
              <Styled.NavText isSidebarMaximized={isSidebarMaximized}>
                <span>{colListItem.name}</span>
                <span>
                  {collapseNavStates[colListItem.stateName] ? "-" : "+"}
                </span>
              </Styled.NavText>
            </Styled.CollapseNavButton>
            <Styled.CollapseItems
              isCollapsed={collapseNavStates[colListItem.stateName]}
              pathLength={colListItem.pathnames.length}
            >
              {colListItem.collapseItems}
            </Styled.CollapseItems>
          </Styled.CollapseNavItem>
        );
      }),
    [
      isSidebarMaximized,
      pathname,
      role,
      collapseNavItemsList,
      collapseNavStates,
      handleToggleCollapseNavButton,
    ],
  );

  useEffect(() => {
    if (role !== process.env.REACT_APP_ADMIN_ROLE) {
      supportService();
    }
  }, [role]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!navRef.current?.contains(event.target as HTMLElement)) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    return undefined;
  }, [isOpen]);

  return (
    <Styled.Container isSidebarMaximized={isSidebarMaximized} ref={navRef}>
      <Helmet>
        <title>
          {role === process.env.REACT_APP_ADMIN_ROLE ? "Backoffice" : "Cliente"}{" "}
          - Loocal
        </title>
      </Helmet>

      <Styled.Header>
        <Styled.Burger isOpen={isOpen} onClick={handleSetNavMinVisibility}>
          <div />
          <div />
          <div />
        </Styled.Burger>
        <Styled.ArrowContainer onClick={handleToggleSidebar}>
          {isSidebarMaximized ? (
            <FiMinimize2 size={15} />
          ) : (
            <FiMaximize2 size={15} />
          )}
        </Styled.ArrowContainer>
        <Styled.ImageContainer isSidebarMaximized={isSidebarMaximized}>
          <img src={logoImg} alt="Loocal Logotipo" />
        </Styled.ImageContainer>

        <Styled.GreetingContainer>
          {isSidebarMaximized ? (
            <div>
              <p>Bem vindo,</p>
              <p>
                {user_name} {fantasy_name}!
              </p>
            </div>
          ) : (
            <Styled.LogoMin src={logoMin} alt="Loocal Logotipo" />
          )}
        </Styled.GreetingContainer>
        <Styled.NavSide isOpen={isOpen}>
          <ul>
            {nonCollapseNavItems}
            {collapseNavItems}
            {nonCollapseNavLastItems}
          </ul>
        </Styled.NavSide>
      </Styled.Header>
      <Styled.Footer>
        <Styled.LogoutContainer
          onClick={handleSignOut}
          isSidebarMaximized={isSidebarMaximized}
        >
          <AiOutlinePoweroff size={18} /> <span>Sair</span>
        </Styled.LogoutContainer>
        <Styled.Copyright isSidebarMaximized={isSidebarMaximized}>
          © 2020 Loocal
        </Styled.Copyright>
      </Styled.Footer>
    </Styled.Container>
  );
};

export default Sidebar;
