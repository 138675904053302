/* eslint-disable */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useField } from "@unform/core";
import { FiAlertCircle, FiEye, FiEyeOff } from "react-icons/fi";
import { IconBaseProps } from "react-icons";

import * as Styled from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  showPasswordOption?: boolean;
}

const Input: React.FC<InputProps> = ({
  icon: Icon,
  name,
  onBlur,
  placeholder,
  type,
  showPasswordOption,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const handleInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);

      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  const handleSetIsFocused = useCallback(() => {
    clearError();
    setIsFocused(true);
  }, [clearError]);

  const handlePasswordVisibility = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  return (
    <>
      <Styled.InputContainer isFocused={isFocused} isErrored={!!error}>
        {Icon && <Icon size={20} />}

        <Styled.Input
          onFocus={handleSetIsFocused}
          onBlur={handleInputBlur}
          placeholder=" "
          type={showPassword ? "text" : type}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
        <label htmlFor={rest.id}>{placeholder}</label>
        {error && (
          <Styled.Error title={error}>
            <FiAlertCircle size={20} />
          </Styled.Error>
        )}
        {!error && showPasswordOption && (
          <Styled.Text onClick={handlePasswordVisibility}>
            {showPassword ? <FiEye size={20} /> : <FiEyeOff size={20} />}
          </Styled.Text>
        )}
      </Styled.InputContainer>
    </>
  );
};

export default Input;
