import { lighten } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  .leaflet-container {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .map-popup .leaflet-popup-content-wrapper {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    box-shadow: none;
  }
  .map-popup .leaflet-popup-content {
    color: #373737;
    font-size: 18px;
    font-weight: 500;
    margin: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-popup .leaflet-popup-tip-container {
    display: none;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  z-index: 777;
  right: 15px;
  top: 15px;
  cursor: pointer;

  > svg {
    transition: 0.3s;
  }
  > svg:hover {
    stroke: ${lighten(0.4, "#373737")};
  }
`;

export const DetailsContainer = styled.div`
  padding: 0 10px;
  text-align: center;
  height: 50%;
`;

export const ModalTitle = styled.h4`
  color: var(--color-text-secondary);
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
  display: flex;
  justify-content: flex-start;
`;

export const Info = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    color: #373737;
    font-size: 17px;
    overflow: auto;
    max-height: 95%;
    overflow-y: auto;
    width: 100%;

    scrollbar-width: thin;
    -ms-overflow-style: scrollbar;
    scrollbar-width: 5px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: #aaa;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-primary);
      border-radius: 8px;
    }
  }
`;

export const Strong = styled.span`
  font-weight: 500;
  font-size: 17px;
  /* line-height: 28px; */
`;
