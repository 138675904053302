import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import {
  FiArrowLeft,
  FiAtSign,
  FiBookmark,
  FiLock,
  FiNavigation,
  FiSettings,
  FiUnlock,
  FiUser,
} from "react-icons/fi";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import Select from "../../components/ReactSelect";
import MultipleSelect from "../../components/MultipleSelect";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import getValidationErrors from "../../utils/getValidationErrors";

import * as Styled from "./styles";

interface StatesResponse {
  id: number;
  name: string;
  uf: string;
}

interface CitiesResponse {
  id: number;
  state_id: number;
  name: string;
}

interface OperatorFormData {
  email: string;
  name: string;
  role: string;
  state_id: string;
  cities: string | string[];
  password: string;
  password_confirmation: string;
}

const OperatorsNew: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [childrenStateUpdater, setChildrenStateUpdater] = useState(0);
  const [cities, setCities] = useState([{ label: "", value: "" }]);
  const [states, setStates] = useState([{ label: "", value: "" }]);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const getCities = useCallback(async (state_id: number, isUpdate = true) => {
    if (isUpdate) {
      setChildrenStateUpdater((state) => state + 1);
    }

    setShowSpinner(true);
    setCities([{ label: "", value: "" }]);
    formRef.current?.setData({
      cities: [],
    });
    const { data: CitiesData } = await api.get(`/cities/${state_id}`);
    const MappedCities = CitiesData.map((city: CitiesResponse) => {
      return {
        label: city.name,
        value: city.id,
      };
    });
    setCities(MappedCities);

    if (isUpdate) {
      setShowSpinner(false);
    }
  }, []);

  const submitNewOperatorForm = useCallback(async (data: OperatorFormData) => {
    try {
      data.cities = JSON.parse(`[${data.cities}]`);

      const dataToSubmitNewOperator = {
        user: {
          name: data.name,
          email: data.email,
          password: data.password,
        },
        cities: data.cities,
        role: data.role,
      };

      await api.post("operators", dataToSubmitNewOperator);

      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: "Alterações realizadas com sucesso!",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Falha ao atualizar, erro: ${error.message}`,
      });
    }
    setShowSpinner(false);
    setIsLoading(false);
  }, []);

  const verifyFormSubmit = useCallback(
    async (data: OperatorFormData) => {
      setShowSpinner(true);
      setIsLoading(true);
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Email inválido")
            .required("Campo obrigatório"),
          name: Yup.string().required("Campo obrigatório"),
          role: Yup.string().required("Campo obrigatório"),
          password: Yup.string().min(4, "No mínimo 4 digitos"),
          password_confirmation: Yup.string()
            .min(4, "No mínimo 4 digitos")
            .oneOf([Yup.ref("password")], "Confirmação inválida"),
          cities: Yup.string().required("Campo obrigatório"),
          state_id: Yup.string().required("Campo obrigatório"),
        });
        await schema.validate(data, { abortEarly: false });
        submitNewOperatorForm(data);
      } catch (err) {
        setShowSpinner(false);
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [submitNewOperatorForm],
  );

  useEffect(() => {
    (async () => {
      setShowSpinner(true);
      try {
        const { data: StatesData } = await api.get("/states");
        const MappedStates = StatesData.map((state: StatesResponse) => {
          return {
            label: state.name,
            value: state.id.toString(),
          };
        });
        setStates(MappedStates);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar estados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    })();
  }, [addToast]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.OperatorContainer>
          <Styled.Link to="/gerenciar/operadores">
            <FiArrowLeft size={20} />
            Voltar
          </Styled.Link>
          <Styled.Form
            onSubmit={verifyFormSubmit}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Dados do responsável</Styled.Strong>
            <Styled.Row>
              <Select
                id="role"
                name="role"
                placeholder="Cargo"
                formRef={formRef}
                isSearchable={false}
                options={[
                  {
                    label: "loocalAdmin",
                    value: "loocalAdmin",
                  },
                  {
                    label: "loocalOperator",
                    value: "loocalOperator",
                  },
                  {
                    label: "loocalFinancial",
                    value: "loocalFinancial",
                  },
                ]}
                icon={FiSettings}
              />

              <Input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                icon={FiAtSign}
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                icon={FiUser}
              />
            </Styled.Row>

            <Styled.Row>
              <Select
                id="state_id"
                name="state_id"
                placeholder="Estado de atuação"
                formRef={formRef}
                options={states}
                icon={FiBookmark}
                onChange={(option) => getCities(option?.value)}
                isSearchable
              />
              <MultipleSelect
                type="normal"
                id="cities"
                name="cities"
                placeholder="Cidade de atuação"
                formRef={formRef}
                options={cities}
                childrenStateUpdater={childrenStateUpdater}
                icon={FiNavigation}
                hasAnimation
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                icon={FiUnlock}
              />

              <Input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                placeholder="Confirme a senha"
                icon={FiLock}
              />
            </Styled.Row>

            <div>
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.Form>
        </Styled.OperatorContainer>
      </ContainerMain>
    </Container>
  );
};

export default OperatorsNew;
