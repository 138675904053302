import React, { useCallback, useEffect, useMemo } from "react";
import { useToasts } from "react-toast-notifications";

import api from "../../services/api";

import { useAuth } from "../../hooks/AuthContext";

import Modal from "../Modal";
import DeliverymanMap from "../DeliverymanMap";

import {
  formatCurrencyByText,
  phoneFormat,
} from "../../utils/inputAndTextMasks";
import getPaymentMethodById from "../../utils/getPaymentMethodById";
import formatLocale from "../../utils/formatLocale";

import { Order } from "../../@types/customTypes";

import * as Styled from "./styles";

interface ModalProps {
  currentOrder: Order;
  setCurrentOrder: React.Dispatch<React.SetStateAction<Order | null>>;
  handleCloseModal: () => void;
  isModalOpen: boolean;
}

const DetailsModal: React.FC<ModalProps> = ({
  handleCloseModal,
  currentOrder,
  setCurrentOrder,
  isModalOpen,
}) => {
  const { addToast } = useToasts();
  const { role } = useAuth();

  const getOrder = useCallback(async () => {
    try {
      const { data } = await api.get(`orders/${currentOrder.id}`);
      setCurrentOrder(data);
    } catch (e) {
      addToast(
        "Ocorreu um erro ao carregar dados, entre em contato com o suporte!",
        {
          appearance: "warning",
          autoDismiss: true,
        },
      );
    }
  }, [addToast, currentOrder.id, setCurrentOrder]);

  const deliverymanLocation = useMemo(
    () =>
      currentOrder.deliveries
        ? [
            Number(currentOrder.deliveries[0]?.deliveryman?.latitude),
            Number(currentOrder.deliveries[0]?.deliveryman?.longitude),
          ]
        : [],
    [currentOrder.deliveries],
  );

  const companyLocation = useMemo(
    () => [
      Number(currentOrder.company.address.latitude),
      Number(currentOrder.company.address.longitude),
    ],
    [currentOrder.company.address],
  );

  const customerLocation = useMemo(
    () => [
      Number(currentOrder.customer.address.latitude),
      Number(currentOrder.customer.address.longitude),
    ],
    [currentOrder.customer.address],
  );

  useEffect(() => {
    const reloadOrderInterval = setInterval(() => {
      getOrder();
    }, 5 * 1000);

    return () => {
      clearInterval(reloadOrderInterval);
    };
  }, [addToast, getOrder]);

  return (
    <Modal
      setIsOpen={handleCloseModal}
      isOpen={isModalOpen}
      customStyles={{
        maxWidth: "unset",
        padding: "0",
        height: "80%",
        width: "80%",
      }}
    >
      <Styled.Container>
        {currentOrder && (
          <>
            <DeliverymanMap
              deliverymanLocation={deliverymanLocation}
              customerLocation={customerLocation}
              companyLocation={companyLocation}
              isOrderFinished={currentOrder.status.name === "Entregue"}
            />
            <Styled.DetailsContainer>
              <Styled.Info>
                <div>
                  {(role === process.env.REACT_APP_ADMIN_ROLE ||
                    role === process.env.REACT_APP_OPERATOR_ROLE) &&
                    currentOrder.status.name === "Entregue" && (
                      <>
                        <div>
                          Entregue por{" "}
                          <Styled.Strong>
                            {currentOrder.deliveries[0].deliveryman?.nickname}
                          </Styled.Strong>{" "}
                          em{" "}
                          <Styled.Strong>
                            {formatLocale(
                              currentOrder.updated_at,
                              "dd/MM/yyyy 'às' HH:mm",
                            )}
                          </Styled.Strong>
                        </div>
                        <br />
                      </>
                    )}
                  Cliente:{" "}
                  <Styled.Strong>{currentOrder.customer.name}</Styled.Strong>
                  {" - "}
                  Telefone:{" "}
                  <Styled.Strong>
                    {phoneFormat(currentOrder.customer.phones[0])}
                  </Styled.Strong>
                  <br />
                  <br />
                  Endereço:{" "}
                  <Styled.Strong>
                    {currentOrder.customer.address.address}
                    {currentOrder.customer.address.number &&
                      ` - ${currentOrder.customer.address.number}`}
                    {currentOrder.customer.address.complement &&
                      ` (${currentOrder.customer.address.complement})`}
                  </Styled.Strong>
                  {(role === process.env.REACT_APP_ADMIN_ROLE ||
                    role === process.env.REACT_APP_OPERATOR_ROLE) && (
                    <>
                      {" - "}
                      Distância:{" "}
                      <Styled.Strong>
                        {currentOrder.distance / 1000} KM
                      </Styled.Strong>
                    </>
                  )}
                  <br />
                  <br />
                  CEP:
                  <Styled.Strong>
                    {" "}
                    {currentOrder.customer.address.postal_code}
                  </Styled.Strong>
                  {" - "}Bairro:{" "}
                  <Styled.Strong>
                    {currentOrder.customer.address.neighborhood}
                  </Styled.Strong>
                  {" - "} Cidade:{" "}
                  <Styled.Strong>
                    {currentOrder.customer.address.city.name}
                  </Styled.Strong>
                  <br />
                  <br />
                  Tipo do pedido: <Styled.Strong>Normal</Styled.Strong> - Método
                  de pagamento:{" "}
                  <Styled.Strong>
                    {getPaymentMethodById(currentOrder.payment_type_id)}
                  </Styled.Strong>
                  <br />
                  <br /> Valor do pedido:
                  <Styled.Strong>
                    {" "}
                    {formatCurrencyByText(currentOrder.amount)}
                  </Styled.Strong>{" "}
                  - Troco:{" "}
                  <Styled.Strong>
                    {formatCurrencyByText(currentOrder.change)}
                  </Styled.Strong>{" "}
                  - Taxa de Entrega:{" "}
                  <Styled.Strong>
                    {formatCurrencyByText(
                      role === process.env.REACT_APP_ADMIN_ROLE ||
                        role === process.env.REACT_APP_OPERATOR_ROLE
                        ? currentOrder.delivery_cost
                        : Number(currentOrder.delivery_cost) +
                            Number(currentOrder.loocal_fee),
                    )}
                  </Styled.Strong>
                  {(role === process.env.REACT_APP_ADMIN_ROLE ||
                    role === process.env.REACT_APP_OPERATOR_ROLE) && (
                    <>
                      {" "}
                      - Taxa da Loocal:{" "}
                      <Styled.Strong>
                        {" "}
                        {formatCurrencyByText(currentOrder.loocal_fee)}
                      </Styled.Strong>{" "}
                    </>
                  )}
                  <br />
                  <br />O pedido{" "}
                  <Styled.Strong>
                    {currentOrder.thermal_box ? "precisará " : "não precisará"}
                  </Styled.Strong>{" "}
                  de caixa térmica,{" "}
                  <Styled.Strong>
                    {currentOrder.return ? "precisará " : "não precisará"}
                  </Styled.Strong>{" "}
                  retornar e{" "}
                  <Styled.Strong>
                    {currentOrder.get_sign ? "precisará " : "não precisará"}
                  </Styled.Strong>{" "}
                  de assinatura.
                  {currentOrder.observations[0] && (
                    <>
                      <br />
                      <br />
                      Observações:{" "}
                      <Styled.Strong>
                        {currentOrder.observations[0]}
                      </Styled.Strong>
                    </>
                  )}
                </div>
              </Styled.Info>
            </Styled.DetailsContainer>
          </>
        )}
      </Styled.Container>
    </Modal>
  );
};

export default React.memo(DetailsModal);
