/* eslint-disable */
import React, { ButtonHTMLAttributes } from "react";

import { StyledButton } from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  content?: string;
  padding?: string;
  isSecondary?: boolean;
}

const ButtonV2: React.FC<ButtonProps> = ({
  type,
  content,
  onClick,
  title,
  padding,
  isSecondary = false,
  ...rest
}) => {
  return (
    <StyledButton
      type={type}
      padding={padding || "1.5rem"}
      onClick={onClick}
      isSecondary={isSecondary}
      {...rest}
    >
      {content}
      {title}
    </StyledButton>
  );
};

export default ButtonV2;
