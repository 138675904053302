import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  FiCheckCircle,
  FiRefreshCw,
  FiZoomIn,
  FiXCircle,
} from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import { format } from "date-fns-tz";
import { FaFileDownload } from "react-icons/fa";

// eslint-disable-next-line
import ptBR from "date-fns/locale/pt-BR";

import api from "../../services/api";
import apiFinance from "../../services/apiFinance";

import { useSidebar } from "../../hooks/SidebarContext";
import useStateCallback from "../../hooks/UseStateCallback";

import WithdrawDetailsModal from "../../components/WithdrawDetailsModal";
import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import FinancesModal from "../../components/FinancesModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import DatePickerTooltip, {
  DateStateObject,
} from "../../components/DatePickerTooltip";

import { formatCurrencyByText } from "../../utils/inputAndTextMasks";
import formatLocale from "../../utils/formatLocale";

import { Withdraw } from "../../@types/customTypes";
import Select from "../../components/SimpleSelect";
import * as Styled from "./styles";

interface WithdrawsResponse {
  data: Withdraw[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

const DeliverymansFinances: React.FC = () => {
  const [withdraws, setWithdraws] = useState<Withdraw[]>([] as Withdraw[]);
  const [selectedWithdraw, setSelectedWithdraw] = useState<Withdraw>(
    {} as Withdraw,
  );
  const [totalPages, setTotalPages] = useState(0);
  const [transactionType, setTrransactionType] = useState("normal");
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFinancesModalOpen, setIsFinancesModalOpen] = useState(false);
  const [currentType, setCurrentType] = useStateCallback<null | string>(null);

  const [totalPagesPIX, setTotalPagesPIX] = useState(0);
  const [currentPagePIX, setCurrentPagePIX] = useState(0);
  const [withdrawsPIX, setWithdrawsPIX] = useState<Withdraw[]>(
    [] as Withdraw[],
  );

  const dateReqParams = useRef<null | DateStateObject>(null);
  const [filterPaymentPix, setFilterPaymentPix] = useState("0");
  const refFilterPaymentPix = useRef<any>(null);
  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();

  const handleGetWithdraws = useCallback(
    async (size = 8, page = 1) => {
      try {
        setIsLoading(true);
        const { data: withdrawsResponse }: { data: WithdrawsResponse } =
          await api.get(
            `deliverymans/withdraws?page[size]=${size}&page[number]=${page}`,
          );

        setCurrentPage(page);
        setTotalPages(withdrawsResponse.meta.last_page);
        setWithdraws(withdrawsResponse.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const handleGetWithdrawsPIX = useCallback(
    async (size = 8, page = 1) => {
      try {
        setIsLoading(true);
        let complement = "";
        if (filterPaymentPix !== "0") {
          complement += `&status=${filterPaymentPix}`;
        }
        const { data } = await apiFinance.get(
          `pix/?page[size]=${size}&page[number]=${page}${complement}`,
        );

        setCurrentPagePIX(page);
        setTotalPagesPIX(data.last_page);
        setWithdrawsPIX(data.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast, filterPaymentPix],
  );

  const handleDownloadCsv = useCallback(async () => {
    try {
      setShowSpinner(true);
      const { data } = await api.get(`deliverymans/withdraws/excel`, {
        responseType: "arraybuffer",
        params: dateReqParams.current,
      });

      const downloadLink = document.createElement("a");

      downloadLink.href = window.URL.createObjectURL(
        new Blob([data], { type: "application/octet-stream" }),
      );

      const formattedDate = format(new Date(), "dd/MM/yyyy", {
        timeZone: "America/Sao_Paulo",
        locale: ptBR,
      });

      downloadLink.download = `financeiro-entregadores-${formattedDate}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      addToast("Ocorreu um erro ao fazer download do CSV!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    setShowSpinner(false);
  }, [addToast]);

  const handleMarkWithdrawAsCancel = useCallback(
    // eslint-disable-next-line
    async (
      name: string,
      amount: number,
      type: string,
      requestId: number | null,
    ) => {
      const { isConfirmed } = await Swal.fire({
        title: "Deseja apontar resgate como cancelado?",
        html: `Tem certeza que deseja apontar o resgate de <strong>${name}</strong>, no valor de <strong>${formatCurrencyByText(
          amount,
        )}</strong> como cancelado?`,
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (isConfirmed) {
        if (type === "pix") {
          setIsLoading(true);
          try {
            await apiFinance.post(`pix/${requestId}/disapprove`);
          } catch (e: any) {
            addToast(e.response.data.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          setIsLoading(false);
          handleGetWithdrawsPIX();
        }
      }
    },
    [handleGetWithdrawsPIX, addToast],
  );

  const handleMarkWithdrawAsPaid = useCallback(
    // eslint-disable-next-line
    async (
      name: string,
      amount: number,
      type: string,
      requestId: number | null,
      paid: number | null,
    ) => {
      const { isConfirmed } = await Swal.fire({
        title:
          type === "pix"
            ? "Liberar solicitação de saque?"
            : "Deseja apontar resgate como pago?",
        html:
          type === "pix"
            ? `Confirme a liberação de saque ${name} no valor solicitado de ${formatCurrencyByText(
                amount,
              )} e valor pago de ${formatCurrencyByText(paid)}`
            : `Tem certeza que deseja apontar o resgate de <strong>${name}</strong>, no valor de <strong>${formatCurrencyByText(
                amount,
              )}</strong> como pago?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (isConfirmed) {
        if (type === "pix") {
          setIsLoading(true);
          try {
            await apiFinance.post(`pix/${requestId}/approve`);
          } catch (e: any) {
            addToast(e.response.data.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          setIsLoading(false);
          handleGetWithdrawsPIX();
        }
      }
    },
    [handleGetWithdrawsPIX, addToast],
  );

  const handleOpenFinancesModal = useCallback(
    (type: string) => {
      setCurrentType(type, () => setIsFinancesModalOpen(true));
    },
    [setIsFinancesModalOpen, setCurrentType],
  );

  const handleCloseFinancesModal = useCallback(() => {
    setIsFinancesModalOpen(false);
    setTimeout(() => {
      setCurrentType(null);
    }, 300);
  }, [setIsFinancesModalOpen, setCurrentType]);

  const handleSetIsModalOpen = useCallback(() => {
    setIsModalOpen((state) => !state);
  }, []);

  const handleViewWithdrawDetails = useCallback(
    (withdraw: Withdraw) => {
      setSelectedWithdraw(withdraw);
      handleSetIsModalOpen();
    },
    [handleSetIsModalOpen],
  );

  useEffect(() => {
    handleGetWithdraws();
  }, [handleGetWithdraws]);

  useEffect(() => {
    handleGetWithdrawsPIX();
  }, [handleGetWithdrawsPIX]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <FinancesModal
        isModalOpen={isFinancesModalOpen}
        handleCloseModal={handleCloseFinancesModal}
        setShowSpinner={setShowSpinner}
        currentType={currentType as string}
      />
      {isModalOpen && (
        <WithdrawDetailsModal
          isOpen={isModalOpen}
          setIsOpen={handleSetIsModalOpen}
          withdrawData={selectedWithdraw}
          type={transactionType}
          handleMarkWithdrawAsPaid={handleMarkWithdrawAsPaid}
        />
      )}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.WithdrawsContainer>
          <Styled.TopBar>
            <Styled.Title>
              Financeiro - Entregadores{" "}
              <FiRefreshCw
                size={20}
                onClick={() => {
                  handleGetWithdraws();
                  handleGetWithdrawsPIX();
                }}
              />
              <DatePickerTooltip
                tooltipId="csv-data-tooltip"
                reqParamsRef={dateReqParams}
                doneButtonLabel="Gerar Relatório"
                onClickDone={handleDownloadCsv}
              />
              <FaFileDownload
                size={20}
                data-tip
                data-for="csv-data-tooltip"
                data-event="click"
              />
              {transactionType === "pix" && (
                <Select
                  placeholder="Situação do pagamento *"
                  formRef={refFilterPaymentPix}
                  name="selectFiltro"
                  isSearchable={false}
                  defaultValue="0"
                  onChange={(e: any) => {
                    setFilterPaymentPix(e.value);
                  }}
                  options={[
                    { value: "0", label: "Todos" },
                    { value: "1", label: "Aberto" },
                    { value: "3", label: "Realizado" },
                    { value: "4", label: "Não realizado" },
                    { value: "5", label: "Em análise" },
                  ]}
                  icon={FiRefreshCw}
                />
              )}
            </Styled.Title>
            <Styled.Buttons>
              <Styled.Link
                onClick={() =>
                  handleOpenFinancesModal("deliverymanTransaction")
                }
              >
                + Nova Transação
              </Styled.Link>
              <Styled.Link onClick={() => handleOpenFinancesModal("delivery")}>
                + Entrega Retroativa
              </Styled.Link>
            </Styled.Buttons>
          </Styled.TopBar>

          <Styled.TopBar>
            <Styled.Buttons>
              <Styled.Link
                onClick={() => {
                  setTrransactionType("normal");
                }}
              >
                Transações por conta bancária
              </Styled.Link>
              <Styled.Link
                onClick={() => {
                  setTrransactionType("pix");
                }}
              >
                Transações via PIX
              </Styled.Link>
            </Styled.Buttons>
          </Styled.TopBar>

          <Styled.ListContainer>
            {isLoading ? (
              <Styled.WithdrawsShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.WithdrawsShimmer>
            ) : (
              <Styled.Ul>
                {transactionType === "normal"
                  ? withdraws.map((withdraw) => (
                      <Styled.Li key={withdraw.id}>
                        <Styled.CommonField>
                          <span>Entregador:</span>
                          <strong>{withdraw.deliveryman.name}</strong>
                        </Styled.CommonField>
                        <Styled.CommonField>
                          <span>Valor:</span>
                          <strong>
                            {formatCurrencyByText(withdraw.amount)}
                          </strong>
                        </Styled.CommonField>
                        <Styled.CommonField>
                          <span>Criado em:</span>
                          <strong>
                            {formatLocale(
                              withdraw.created_at,
                              "dd/MM/yyyy ' ' HH:mm",
                            )}
                          </strong>
                        </Styled.CommonField>
                        <Styled.CommonField>
                          <span>Transferido em:</span>
                          <strong>
                            {withdraw.transfered_at
                              ? formatLocale(
                                  withdraw.transfered_at,
                                  "dd/MM/yyyy ' ' HH:mm",
                                )
                              : "n/a"}
                          </strong>
                        </Styled.CommonField>
                        <Styled.ActionField>
                          <FiCheckCircle
                            size={24}
                            onClick={() =>
                              handleMarkWithdrawAsPaid(
                                withdraw.deliveryman.name,
                                withdraw.amount,
                                "normal",
                                withdraw.request_id,
                                null,
                              )
                            }
                          />
                          <FiZoomIn
                            size={28}
                            onClick={() => handleViewWithdrawDetails(withdraw)}
                          />
                        </Styled.ActionField>
                      </Styled.Li>
                    ))
                  : withdrawsPIX.map((withdraw: any) => (
                      <Styled.Li key={withdraw.id}>
                        <Styled.CommonField>
                          <span>Entregador:</span>
                          <strong>{withdraw?.deliveryman?.name}</strong>
                        </Styled.CommonField>
                        <Styled.CommonField>
                          <span>Valor solicitado:</span>
                          <strong>
                            {formatCurrencyByText(withdraw.requested_value)}
                          </strong>
                        </Styled.CommonField>

                        <Styled.CommonField>
                          <span>Valor pago:</span>
                          <strong>
                            {formatCurrencyByText(withdraw.value)}
                          </strong>
                        </Styled.CommonField>
                        <Styled.CommonField>
                          <span>Criado em:</span>
                          <strong>
                            {formatLocale(
                              withdraw.created_at,
                              "dd/MM/yyyy ' ' HH:mm",
                            )}
                          </strong>
                        </Styled.CommonField>
                        <Styled.CommonField>
                          <span>Situação do pagamento:</span>
                          <strong>{withdraw?.status?.name}</strong>
                        </Styled.CommonField>
                        <Styled.ActionField>
                          {withdraw.status.status_id === 5 ? (
                            <Styled.CommonField>
                              <FiCheckCircle
                                size={24}
                                onClick={() =>
                                  handleMarkWithdrawAsPaid(
                                    withdraw?.deliveryman?.name ||
                                      withdraw?.name,
                                    withdraw.amount || withdraw.requested_value,
                                    "pix",
                                    withdraw.request_id,
                                    withdraw.value,
                                  )
                                }
                              />

                              <FiXCircle
                                size={24}
                                onClick={() =>
                                  handleMarkWithdrawAsCancel(
                                    withdraw?.deliveryman?.name ||
                                      withdraw?.name,
                                    withdraw.amount || withdraw.requested_value,
                                    "pix",
                                    withdraw.request_id,
                                  )
                                }
                              />
                            </Styled.CommonField>
                          ) : (
                            <></>
                          )}
                          <FiZoomIn
                            size={28}
                            onClick={() => handleViewWithdrawDetails(withdraw)}
                          />
                        </Styled.ActionField>
                      </Styled.Li>
                    ))}
              </Styled.Ul>
            )}

            {currentPage !== 0 && transactionType === "normal" && (
              <Pagination
                updateList={handleGetWithdraws}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            )}

            {currentPage !== 0 && transactionType === "pix" && (
              <Pagination
                updateList={handleGetWithdrawsPIX}
                totalPages={totalPagesPIX}
                currentPage={currentPagePIX}
              />
            )}
          </Styled.ListContainer>
        </Styled.WithdrawsContainer>
      </ContainerMain>
    </Container>
  );
};

export default DeliverymansFinances;
