import styled, { css } from "styled-components";

interface InputContainer {
  isFocused: boolean;
}

export const Container = styled.div<InputContainer>`
  border: 1px solid rgba(220, 220, 220);
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: white;
  padding: 2px 0px;
  position: relative;
  min-width: 250px;

  > label {
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    top: -10px;
    left: 20px;
    background: white;
    font-size: 14px;
    color: #8e8e8e;
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      > svg {
        stroke: var(--color-primary) !important;
        color: var(--color-primary) !important;
      }
      border-color: var(--color-primary);
    `}

  &:hover {
    > svg {
      stroke: var(--color-primary) !important;
      color: var(--color-primary) !important;
    }
    border-color: var(--color-primary);
  }

  > svg {
    margin: 0 16px 0 16px;
    stroke: #8e8e8e;
    transition: opacity 0.3s;
    color: #8e8e8e;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  flex: 1;
  border: 0;
  background: transparent;
  outline: none;
  color: #373737;
  font-size: 16px;
  padding: 14px 0 14px 16px;
  cursor: text;

  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;

  &::placeholder {
    color: #8e8e8e;
  }
  &:-webkit-autofill {
    & ~ label {
      color: #8e8e8e;
    }
  }
`;
