import { darken, lighten } from "polished";
import styled, { css } from "styled-components";

interface GreenContainerProps {
  noHover?: boolean;
}

interface ViewMoreProps {
  disabled?: boolean;
}

interface MainContainerProps {
  isSidebarMaximized: boolean;
}

interface NormalCardProps {
  isDinamic?: boolean;
  isMax?: boolean;
}

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.main<MainContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 767px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: unset;
    justify-content: center;
  }

  @media (min-width: 1090px) {
    margin-left: 15px;
    display: grid;

    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-content: unset;

    ${({ isSidebarMaximized }) =>
      isSidebarMaximized
        ? css`
            grid-template-columns: 1fr 1fr;
          `
        : css`
            grid-template-columns: 1fr 1fr 1fr;
          `}
  }

  @media (min-width: 1275px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const NormalCard = styled.div<NormalCardProps>`
  margin-top: 30px;
  padding: 30px;
  background: white;
  width: 300px;
  height: 180px;
  border-radius: 12px;
  position: relative;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: ${({ isDinamic }) =>
    isDinamic
      ? "2px 2px 10px rgba(0,167,89, 0.4)"
      : "2px 2px 10px rgba(220, 220, 220, 0.2)"};

  ${({ isMax }) =>
    isMax
      ? css`
          height: 353px;
        `
      : css`
          height: 195px;
        `}

  @media (min-width: 767px) {
    margin-left: 30px;
  }

  @media (min-width: 1024px) {
    margin: 30px 0 30px 15px;
  }
`;

export const CardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > svg {
    cursor: pointer;
    transition: 0.3s;
    color: #373737;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }

  > h4 {
    margin-bottom: 0;
  }
`;

export const CardTitle = styled.h4`
  color: var(--color-text-secondary);
  margin-bottom: 10px;
`;

export const CardStrong = styled.p`
  font-weight: 500;
  font-size: 20px;
  padding-left: 10px;
  color: #373737;
`;

export const DinamicText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: var(--color-danger);
  position: absolute;
  right: 15px;
  top: 12px;
`;

export const GreenContainer = styled.div<GreenContainerProps>`
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 36px;

  ${({ noHover }) =>
    !noHover &&
    css`
      cursor: pointer;

      &:hover {
        > div {
          color: ${lighten(0.25, "#466aa9")};
        }
      }
    `}

  > svg {
    cursor: pointer;
    right: 5px;
    bottom: 0;
    transition: 0.3s;
    color: #373737;
    margin-left: 15px;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const Shimmer = styled.div`
  width: 140px;
  display: flex;
  justify-content: flex-end;

  > div:first-child {
    width: 30%;
    height: 36px;
  }

  > div:not(:first-child) {
    width: 50%;
    margin-left: 10%;
    height: 36px;
  }
`;

export const CardGreen = styled.div<{ isDinamic?: boolean }>`
  font-size: 25px;
  padding-left: 25px;
  color: ${({ isDinamic }) =>
    isDinamic ? "var(--color-danger)" : "var(--color-primary)"};
  transition: 0.3s;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/* export const DeliverymansList = styled.div<DeliverymansListProps>`
  min-height: 125px;
  > div:not(:first-child) {
    margin-top: 5px;
  }
`; */

export const ViewMoreContainer = styled.div`
  border-top: 1px solid rgba(220, 220, 220, 0.5);
  padding-top: 15px;
  margin-top: 15px;
`;

export const DeliverymanContainer = styled.div`
  > p {
    color: #373737;
    max-width: 239px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ViewMore = styled.div<ViewMoreProps>`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  transition: color 0.2s;

  ${({ disabled }) =>
    disabled
      ? css`
          color: gray;
          pointer-events: none;
        `
      : css`
          cursor: pointer;
          color: #6a64d9;
          &:hover {
            color: ${darken(0.25, "#6a64d9")};
          }
        `}
`;
