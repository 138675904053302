import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h3:last-of-type {
    margin-bottom: 8px;
  }
`;

export const Title = styled.h2`
  font-size: 22px;
  margin: 8px 0;
`;

export const HR = styled.hr`
  margin: 8px;
  border: 1px solid lightgray;
  width: 100%;
`;

export const SubTitle = styled.h3`
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: -5px;
  z-index: 50;
`;

export const Details = styled.span`
  font-size: 18px;
  margin-top: 10px;
`;
