import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import { useField } from "@unform/core";

import * as Styled from "./styles";

interface RadioOptions {
  id: string | number;
  label: string;
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: RadioOptions[];
}

const Radio: React.FC<RadioProps> = ({
  name,
  options,
  defaultValue,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref: HTMLInputElement) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref: HTMLInputElement) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Styled.RadioContainer>
      {options.map((option, index) => (
        <label key={option.id} htmlFor={option.id as string}>
          <input
            ref={(elRef: HTMLInputElement) => {
              inputRefs.current[index] = elRef;
              return undefined;
            }}
            type="radio"
            name={fieldName}
            value={option.id}
            defaultChecked={defaultValue === option.id}
            {...rest}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </Styled.RadioContainer>
  );
};

export default Radio;
