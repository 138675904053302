import { lighten } from "polished";
import styled from "styled-components";

export const Container = styled.div``;

export const DetailsContainer = styled.div`
  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Strong = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 15px;
  color: #373737;

  &:not(:first-child) {
    padding-top: 15px;
    border-top: 1px solid lightgray;
  }

  @media (min-width: 800px) {
    &:not(:first-child) {
      padding-top: 0;
      border-top: none;
    }
  }
`;

export const CommonField = styled.div`
  > p {
    color: #373737;
    margin-top: 15px;
    > strong {
      font-weight: 500;
    }
  }

  @media (min-width: 800px) {
    display: flex;
    align-items: center;
    > p {
      margin-top: 0;
      margin-left: 50px;
    }
  }
`;

export const FieldTitle = styled.div`
  display: flex;
  align-items: center;
  min-width: 178px;

  > h3 {
    color: var(--color-text-secondary);
    margin-left: 8px;
  }
  > svg {
    color: var(--color-primary);
  }
`;

export const ActionContainer = styled.div`
  margin-top: 20px;

  display: flex;
  justify-content: center;

  > svg {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;
