import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import AppProvider from "./hooks";
import { BalanceProvider } from "./context/balance";

import Routes from "./routes";

import GlobalStyle from "./styles/global";

const App: React.FC = () => (
  <>
    <AppProvider>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={5000}
        placement="top-right"
      >
        <BalanceProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </BalanceProvider>
      </ToastProvider>
    </AppProvider>
    <GlobalStyle />
  </>
);

export default App;
