/* eslint-disable */
import React, { useCallback, useState } from "react";

// eslint-disable-next-line
import ptBR from "date-fns/locale/pt-BR";
// eslint-disable-next-line
import { toDate } from "date-fns";

import Button from "../Button";

import formatLocale from "../../utils/formatLocale";

import "react-datepicker/dist/react-datepicker.css";
import * as Styled from "./styles";

export interface DateStateObject {
  periodo_relatorio: number;
  periodo_inicio: string;
  periodo_fim: string;
}

interface DatePeriodStateObject {
  start: Date | null;
  end: Date | null;
}

interface DatePickerTooltipProps {
  tooltipId: string;
  reqParamsRef?: React.MutableRefObject<DateStateObject | null>;
  doneButtonLabel: string;
  onClickDone: () => void;
  tooltipRef?: React.MutableRefObject<any>;
}

const DatePickerTooltip: React.FC<DatePickerTooltipProps> = ({
  tooltipId,
  tooltipRef,
  reqParamsRef,
  doneButtonLabel,
  onClickDone,
}) => {
  const [selectedDatePeriod, setSelectedDatePeriod] =
    useState<DatePeriodStateObject>({
      start: reqParamsRef?.current
        ? new Date(reqParamsRef.current.periodo_inicio)
        : new Date(),
      end: reqParamsRef?.current
        ? new Date(reqParamsRef.current.periodo_fim)
        : new Date(),
    });

  const setDateStates = useCallback(
    (dateObject) => {
      if (reqParamsRef)
        reqParamsRef.current = {
          periodo_relatorio: dateObject.period,
          periodo_inicio: formatLocale(dateObject.start, "yyyy/MM/dd"),
          periodo_fim: formatLocale(dateObject.end, "yyyy/MM/dd"),
        };

      setSelectedDatePeriod({ start: dateObject.start, end: dateObject.end });
    },
    [reqParamsRef, setSelectedDatePeriod],
  );

  const onDatePickerChange = useCallback(
    (dates: [Date, Date]) => {
      const [start, end] = dates;

      setDateStates({
        period: 3,
        start,
        end,
      });
    },
    [setDateStates],
  );

  const setDateTodayOrMonth = useCallback(
    (period: "today" | "month") => {
      const endDateOfMonth = toDate(new Date());
      const endMonth = endDateOfMonth.getMonth();
      endDateOfMonth.setFullYear(endDateOfMonth.getFullYear(), endMonth + 1, 0);
      endDateOfMonth.setHours(0, 0, 0, 0);

      const startDateOfMonth = toDate(new Date());
      startDateOfMonth.setDate(1);
      startDateOfMonth.setHours(0, 0, 0, 0);

      setDateStates({
        period: period === "today" ? 1 : 4,
        start: startDateOfMonth,
        end: period === "today" ? startDateOfMonth : endDateOfMonth,
      });
    },
    [setDateStates],
  );

  return (
    <Styled.CsvTooltip
      ref={tooltipRef}
      id={tooltipId}
      place="bottom"
      effect="solid"
      clickable
    >
      <div>Selecione o período desejado:</div>
      <Styled.CsvOptionsUl>
        <Styled.CsvOption onClick={() => setDateTodayOrMonth("today")}>
          Hoje
        </Styled.CsvOption>
        <Styled.CsvOption onClick={() => setDateTodayOrMonth("month")}>
          Mês Atual
        </Styled.CsvOption>
      </Styled.CsvOptionsUl>

      <Styled.DateRangePicker
        selected={selectedDatePeriod.start as Date}
        onChange={onDatePickerChange}
        startDate={selectedDatePeriod.start}
        endDate={selectedDatePeriod.end}
        showDisabledMonthNavigation
        placeholderText="dd/mm/aaaa - dd/mm/aaaa"
        selectsRange
        locale={ptBR}
        dateFormat="dd/MM/yyyy"
      />

      <Button
        title={doneButtonLabel}
        onClick={onClickDone}
        disabled={!selectedDatePeriod.start}
      />
    </Styled.CsvTooltip>
  );
};

export default DatePickerTooltip;
