import styled from "styled-components";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  margin: 20px;
  max-width: calc(100vw - 40px);

  @media (min-width: 800px) {
    margin: 30px;
    max-width: calc(100vw - 60px);
  }

  @media (min-width: 1090px) {
    max-width: ${({ isSidebarMaximized }) =>
      isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  }
`;
