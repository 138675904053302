/* eslint-disable import/no-cycle */
import React, { useCallback, useRef, useState } from "react";
import { RiPagesLine, RiBankCardFill } from "react-icons/ri";
import {
  FiCalendar,
  FiClock,
  FiCreditCard,
  FiDollarSign,
  FiFlag,
  FiLock,
  FiUser,
} from "react-icons/fi";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import api from "../../services/api";
import apiFinance from "../../services/apiFinance";

import { useAuth } from "../../hooks/AuthContext";
import { useSidebar } from "../../hooks/SidebarContext";

import getValidationErrors from "../../utils/getValidationErrors";
import { hardCodedCardBrands } from "../../utils/HardCodedCardBrands";
import {
  onChangeAcceptOnlyNumbers,
  onChangeCreditCardMask,
  onChangeCurrencyMask,
} from "../../utils/inputAndTextMasks";

import Sidebar from "../../components/Sidebar";
import Select from "../../components/ReactSelect";
import Button from "../../components/Button";
import Input from "../../components/Input";
import LoadingSpinner from "../../components/LoadingSpinner";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import QRCodeModal from "../../components/QRCodeModal";

import { ReactComponent as PixImg } from "../../assets/img/pix.svg";

import * as Styled from "./styles";

interface PixDetails {
  amount: string | null;
  QRCodeBase64: string | null;
  QRCodeCopiaCola: string | null;
}

interface amountFormData {
  amount: string;
}

interface CreditFormData {
  brand_id: string;
  holder: string;
  number: string;
  cvv: string;
  expiration_month: string;
  expiration_year: string;
  amount: string;
}

export type PaymentMethodTypes = "CREDIT_CARD" | "BANK_SLIP" | "PIX";

const AddCredits: React.FC = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodTypes>("CREDIT_CARD");
  const [isAmex, setIsAmex] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pixDetails, setPixDetails] = useState<PixDetails>({
    QRCodeBase64: null,
    QRCodeCopiaCola: null,
    amount: null,
  });
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { company_id } = useAuth();
  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();

  const handleOnChangeBrand = useCallback(
    (option: { value: string; label: string }) => {
      if (option.label === "Amex") {
        setIsAmex(true);
      } else {
        setIsAmex(false);
      }

      formRef.current?.clearField("number");
      formRef.current?.clearField("cvv");
    },
    [],
  );

  const handleChoosePaymentMethod = useCallback(
    (method: PaymentMethodTypes) => {
      setSelectedPaymentMethod(method);
    },
    [],
  );

  const handleSetQRCodeModalVisibility = useCallback(() => {
    setIsQRCodeModalOpen((state) => !state);
  }, []);

  const addCreditsWithCreditCard = useCallback(
    async (data: CreditFormData) => {
      try {
        const dataToAddCredits = {
          amount: data.amount,
          card: {
            brand_id: data.brand_id,
            cvv: data.cvv,
            expiration_month: data.expiration_month,
            expiration_year: data.expiration_year,
            holder: data.holder,
            number: data.number,
          },
        };

        await api.post(
          `companies/${company_id}/recharge/card`,
          dataToAddCredits,
        );

        Swal.fire({
          icon: "success",
          title: "Recarga efetuada com suceso!",
          text: "Sua recarga foi efetuada, obrigado!",
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um problema ao efetuar recarga, verifique seus dados!",
        });
      }
    },
    [company_id],
  );

  const handleSubmitCreditCardForm = useCallback(
    async (data: CreditFormData) => {
      setIsLoading(true);
      try {
        data.number = data.number.replace(/\D/g, "");
        data.amount = data.amount.replace(/[^0-9,-]+/g, "").replace(/,/g, ".");

        const schema = Yup.object().shape({
          brand_id: Yup.number()
            .moreThan(0.99, "Selecione uma bandeira")
            .typeError("Selecione uma bandeira"),
          holder: Yup.string().min(3, "Nome inválido"),
          number: isAmex
            ? Yup.string().min(15, "Número inválido")
            : Yup.string().min(16, "Número inválido"),
          cvv: isAmex
            ? Yup.string().min(4, "CVV Inválido")
            : Yup.string().min(3, "CVV Inválido"),
          expiration_month: Yup.number()
            .moreThan(0, "Mês inválido")
            .lessThan(13, "Mês inválido")
            .typeError("Mês inválido"),
          expiration_year: Yup.number()
            .moreThan(19, "Ano inválido")
            .typeError("Ano inválido"),
          amount: Yup.number()
            .moreThan(99.99, "Recarga mínima de R$ 100,00")
            .typeError("Recarga mínima de R$ 100,00"),
        });
        await schema.validate(data, { abortEarly: false });
        addCreditsWithCreditCard(data);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [addCreditsWithCreditCard, isAmex],
  );

  const generateBillet = useCallback(
    async (formData: amountFormData) => {
      try {
        const data = {
          value: parseInt(formData.amount, 10) * 100,
          company_id,
        };
        const { data: generateBilletData } = await apiFinance.post(
          "billet",
          data,
        );
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Boleto gerado com sucesso!",
          allowOutsideClick: false,
          confirmButtonText: "Visualizar Boleto",
        }).then((result) => {
          if (result.value) {
            const win = window.open(
              generateBilletData.data.pdf.charge,
              "_blank",
            );
            if (win !== null) {
              win.focus();
            }
          }
        });
      } catch (e: any) {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: e.message,
        });
      }
    },
    [company_id],
  );

  const handleSubmitBilletForm = useCallback(
    async (data: amountFormData) => {
      setIsLoading(true);
      try {
        data.amount = data.amount.replace(/[^0-9,-]+/g, "").replace(/,/g, ".");
        const schema = Yup.object().shape({
          amount: Yup.number()
            .moreThan(99.99, "Recarga mínima de R$ 100,00")
            .typeError("Recarga mínima de R$ 100,00"),
        });
        await schema.validate(data, { abortEarly: false });
        generateBillet(data);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [generateBillet],
  );

  const generatePixQRCode = useCallback(
    async (data: amountFormData) => {
      try {
        const dataToAddCredits = {
          company_id,
          ...data,
        };

        const { data: PixData } = await api.post(
          `pix/generate`,
          dataToAddCredits,
        );

        setPixDetails({
          QRCodeBase64: PixData.qr_code_image,
          QRCodeCopiaCola: PixData.qr_code_copia_cola,
          amount: data.amount,
        });
        handleSetQRCodeModalVisibility();
      } catch (e) {
        addToast("Ocorreu um erro ao gerar QR Code!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      setIsLoading(false);
    },
    [company_id, handleSetQRCodeModalVisibility, addToast],
  );

  const handleSubmitPixForm = useCallback(
    async (data: amountFormData) => {
      setIsLoading(true);
      try {
        data.amount = data.amount.replace(/[^0-9,-]+/g, "").replace(/,/g, ".");
        const schema = Yup.object().shape({
          amount: Yup.number()
            .moreThan(99.99, "Recarga mínima de R$ 100,00")
            .typeError("Recarga mínima de R$ 100,00"),
        });
        await schema.validate(data, { abortEarly: false });
        generatePixQRCode(data);
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [generatePixQRCode],
  );

  return (
    <Container>
      <Sidebar />
      {isLoading && <LoadingSpinner />}
      <QRCodeModal
        isModalOpen={isQRCodeModalOpen}
        handleCloseModal={handleSetQRCodeModalVisibility}
        pixDetails={pixDetails}
      />
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.AddCreditsContainer>
          <Styled.Title>Financeiro - Inserir Créditos</Styled.Title>
          <Styled.Strong>Selecione seu método de pagamento</Styled.Strong>
          <Styled.PaymentWrapper>
            <Styled.PaymentMethodsContainer
              selectedPaymentMethod={selectedPaymentMethod}
            >
              <Styled.PaymentMethodBox
                onClick={() => handleChoosePaymentMethod("CREDIT_CARD")}
              >
                <RiBankCardFill size={40} />
                <span>Cartão de crédito</span>
              </Styled.PaymentMethodBox>
              <Styled.PaymentMethodBox
                onClick={() => handleChoosePaymentMethod("BANK_SLIP")}
              >
                <RiPagesLine size={40} />
                <span>Boleto bancário</span>
              </Styled.PaymentMethodBox>

              <Styled.PaymentMethodBox
                onClick={() => handleChoosePaymentMethod("PIX")}
              >
                <PixImg />
                <span>Pix</span>
              </Styled.PaymentMethodBox>
            </Styled.PaymentMethodsContainer>
            <Styled.SelectedPaymentMethodContainer>
              {selectedPaymentMethod === "CREDIT_CARD" && (
                <Styled.PaymentForm
                  ref={formRef}
                  onSubmit={handleSubmitCreditCardForm}
                  autoComplete="off"
                >
                  <Styled.SelectedPaymentMethodTitle
                    selectedPaymentMethod={selectedPaymentMethod}
                  >
                    Dados do Cartão
                  </Styled.SelectedPaymentMethodTitle>
                  <Select
                    id="brand_id"
                    name="brand_id"
                    onChange={handleOnChangeBrand}
                    placeholder="Bandeira *"
                    formRef={formRef}
                    options={hardCodedCardBrands}
                    icon={FiFlag}
                    isSearchable={false}
                  />
                  <Input
                    type="text"
                    id="holder"
                    name="holder"
                    placeholder="Nome do titular *"
                    icon={FiUser}
                  />
                  <Styled.Row>
                    <Input
                      type="text"
                      id="number"
                      name="number"
                      placeholder="Número do cartão *"
                      onChange={(e) => onChangeCreditCardMask(e, isAmex)}
                      icon={FiCreditCard}
                      maxLength={isAmex ? 17 : 19}
                    />
                    <Input
                      onChange={onChangeAcceptOnlyNumbers}
                      type="text"
                      id="cvv"
                      name="cvv"
                      placeholder="CVV *"
                      icon={FiLock}
                      maxLength={isAmex ? 4 : 3}
                    />
                  </Styled.Row>
                  <Styled.Row>
                    <Input
                      type="text"
                      id="expiration_month"
                      name="expiration_month"
                      placeholder="Vencimento - mês *"
                      icon={FiClock}
                      onChange={onChangeAcceptOnlyNumbers}
                      maxLength={2}
                    />
                    <Input
                      onChange={onChangeAcceptOnlyNumbers}
                      type="text"
                      id="expiration_year"
                      name="expiration_year"
                      icon={FiCalendar}
                      placeholder="Vencimento - ano *"
                      maxLength={2}
                    />
                  </Styled.Row>

                  <Input
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Valor *"
                    onChange={onChangeCurrencyMask}
                    icon={FiDollarSign}
                    maxLength={10}
                  />
                  <p>
                    Atenção: Seu saldo é liberado na hora que o pagamento for
                    aprovado! Por antecipar esse crédito, descontamos o custo de
                    antecipação que é de 3,5% do saldo disponível!
                  </p>
                  <br />
                  <Button
                    type="submit"
                    content={isLoading ? "Carregando..." : "Salvar"}
                    disabled={isLoading}
                  />
                </Styled.PaymentForm>
              )}{" "}
              {selectedPaymentMethod === "BANK_SLIP" && (
                <Styled.PaymentForm
                  ref={formRef}
                  onSubmit={handleSubmitBilletForm}
                >
                  <Styled.SelectedPaymentMethodTitle
                    selectedPaymentMethod={selectedPaymentMethod}
                  >
                    Gerar Boleto Bancário
                  </Styled.SelectedPaymentMethodTitle>
                  <Input
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Valor *"
                    onChange={onChangeCurrencyMask}
                    icon={FiDollarSign}
                    maxLength={10}
                  />
                  <p>
                    Atenção: Após o pagamento do boleto, poderá levar até 3 dias
                    úteis para que o valor selecionado apareça no seu saldo de
                    créditos.
                  </p>
                  <br />
                  <Button
                    type="submit"
                    content={isLoading ? "Carregando..." : "Gerar"}
                    disabled={isLoading}
                  />
                </Styled.PaymentForm>
              )}
              {selectedPaymentMethod === "PIX" && (
                <Styled.PaymentForm
                  ref={formRef}
                  onSubmit={handleSubmitPixForm}
                >
                  <Styled.SelectedPaymentMethodTitle
                    selectedPaymentMethod={selectedPaymentMethod}
                  >
                    Gerar Pix - QR Code
                  </Styled.SelectedPaymentMethodTitle>
                  <Input
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Valor *"
                    onChange={onChangeCurrencyMask}
                    icon={FiDollarSign}
                    maxLength={10}
                  />
                  <p>
                    Atenção: Após o pagamento do pix, aguarde alguns instantes
                    até que seu saldo seja atualizado.
                  </p>
                  <br />
                  <Button
                    type="submit"
                    content={isLoading ? "Carregando..." : "Gerar"}
                    disabled={isLoading}
                  />
                </Styled.PaymentForm>
              )}
            </Styled.SelectedPaymentMethodContainer>
          </Styled.PaymentWrapper>
        </Styled.AddCreditsContainer>
      </ContainerMain>
    </Container>
  );
};

export default AddCredits;
