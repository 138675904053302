import React from "react";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

import * as S from "./styled";

const Terms = (): JSX.Element => {
  React.useEffect(() => {
    window.document.title = "Loocal - Política de privacidade";
  }, []);

  return (
    <>
      <S.Container>
        <S.Content>
          <S.AnimationContainer>
            <Link to="/">
              <FiArrowLeft size={17} /> Ir a página inicial
            </Link>
            <strong>Política de Privacidade - Entregadores</strong>
            <br />
            <br />
            Loocal Tecnologia E Solucoes Limitada construiu o aplicativo Loocal
            como um aplicativo gratuito. Este SERVIÇO é prestado pela Loocal
            Tecnologia E Solucoes Limitada sem nenhum custo e deve ser utilizado
            no estado em que se encontra. Esta página é usada para informar os
            visitantes sobre nossas políticas de coleta, uso e divulgação de
            Informações Pessoais, caso alguém decida usar nosso Serviço. Se você
            optar por usar nosso Serviço, você concorda com a coleta e uso de
            informações em relação a esta política. As informações pessoais que
            coletamos são usadas para fornecer e melhorar o serviço. Não
            usaremos ou compartilharemos suas informações com ninguém, exceto
            conforme descrito nesta Política de Privacidade. Os termos usados
            nesta Política de Privacidade têm os mesmos significados que em
            nossos Termos e Condições, que podem ser acessados na Loocal, a
            menos que definido de outra forma nesta Política de Privacidade.
            <br />
            <br />
            <strong>Coleta e uso de informações</strong>
            <br />
            <br />
            Para uma melhor experiência, ao usar nosso Serviço, podemos exigir
            que você nos forneça algumas informações de identificação pessoal,
            incluindo, mas não se limitando a localização em segundo plano. Da
            localização em segundo plano:
            <br />
            <br />
            <li>
              Utilizamos e armazenamos a localização em tempo real de nossos
              usuários, após ter a permissão concedida pelo mesmo, com o
              objetivo de melhorar sua expêriencia em recursos como: Exibir
              entregas com base na localização atual, permitir dar como entregue
              com base na localização atual e outras;
            </li>
            <br />
            As informações que solicitamos serão retidas por nós e usadas
            conforme descrito nesta política de privacidade. O aplicativo usa
            serviços de terceiros que podem coletar informações usadas para
            identificá-lo. Link para a política de privacidade de provedores de
            serviços terceirizados usados pelo aplicativo:
            <br />
            <br />
            <li>Google Play Services</li>
            <li>Google Analytics for Firebase</li>
            <li>Sentry</li>
            <br />
            <br />
            <strong>Log Data</strong>
            <br />
            <br />
            Queremos informá-lo que sempre que você utilizar nosso Serviço, em
            caso de erro no aplicativo, coletamos dados e informações (por meio
            de produtos de terceiros) em seu telefone denominado Log Data. Estes
            dados de registro podem incluir informações como endereço de
            protocolo de Internet &ldquo;IP&ldquo; do dispositivo, nome do
            dispositivo, versão do sistema operacional, configuração do
            aplicativo ao utilizar nosso serviço, email ou nome do usuário, hora
            e data de uso do serviço e outras estatísticas.
            <br />
            <br />
            <strong>Cookies</strong>
            <br />
            <br />
            Cookies são arquivos com uma pequena quantidade de dados que são
            comumente usados como identificadores exclusivos anônimos. Eles são
            enviados para o seu navegador a partir dos sites que você visita e
            são armazenados na memória interna do seu dispositivo. Este Serviço
            não usa esses “cookies” explicitamente. No entanto, o aplicativo
            pode usar código de terceiros e bibliotecas que usam “cookies” para
            coletar informações e melhorar seus serviços. Você tem a opção de
            aceitar ou recusar esses cookies e saber quando um cookie está sendo
            enviado para o seu dispositivo. Se você optar por recusar nossos
            cookies, pode não ser capaz de usar algumas partes deste Serviço.
            <br />
            <br />
            <strong>Provedores de serviço</strong>
            <br />
            <br />
            Podemos empregar empresas terceirizadas e indivíduos pelos seguintes
            motivos:
            <br />
            <br />
            <li>Para facilitar nosso serviço;</li>
            <li>Para fornecer o Serviço em nosso nome;</li>
            <li>Executar serviços relacionados com o serviço; ou</li>
            <li>
              Para nos ajudar a analisar como nosso serviço é usado. Queremos
              informar aos usuários deste Serviço que esses terceiros têm acesso
              às suas Informações Pessoais. O motivo é realizar as tarefas
              atribuídas a eles em nosso nome. No entanto, eles são obrigados a
              não divulgar ou usar as informações para qualquer outra
              finalidade. Segurança Valorizamos sua confiança em nos fornecer
              suas informações pessoais, portanto, estamos nos esforçando para
              usar meios comercialmente aceitáveis de protegê-las. Mas lembre-se
              que nenhum método de transmissão pela internet, ou método de
              armazenamento eletrônico é 100% seguro e confiável, e não podemos
              garantir sua segurança absoluta.
            </li>
            <br />
            <br />
            <strong>Links para outros sites</strong>
            <br />
            <br />
            Este serviço pode conter links para outros sites. Se você clicar em
            um link de terceiros, será direcionado a esse site. Observe que
            esses sites externos não são operados por nós. Portanto,
            recomendamos fortemente que você reveja a Política de Privacidade
            desses sites. Não temos controle e não assumimos responsabilidade
            pelo conteúdo, políticas de privacidade ou práticas de quaisquer
            sites ou serviços de terceiros.
            <br />
            <br />
            <strong>Privacidade das crianças</strong>
            <br />
            <br />
            Esses Serviços não se dirigem a ninguém com menos de 13 anos. Não
            coletamos intencionalmente informações de identificação pessoal de
            crianças com menos de 13 anos. No caso de descobrirmos que uma
            criança menor de 13 anos nos forneceu informações pessoais, nós as
            apagamos imediatamente de nossos servidores. Se você é um pai ou
            responsável e sabe que seu filho nos forneceu informações pessoais,
            entre em contato para que possamos tomar as medidas necessárias.
            <br />
            <br />
            <strong>Mudanças nesta Política de Privacidade</strong>
            <br />
            <br />
            Podemos atualizar nossa Política de Privacidade de tempos em tempos.
            Portanto, recomendamos que você revise esta página periodicamente
            para verificar quaisquer alterações. Iremos notificá-lo de quaisquer
            alterações, publicando a nova Política de Privacidade nesta página.
            Esta política entra em vigor a partir de 28/03/2021.
            <br />
            <br />
            <strong>Entre em contato conosco</strong>
            <br />
            <br />
            Se você tiver alguma dúvida ou sugestão sobre nossa Política de
            Privacidade, não hesite em entrar em contato conosco pelo e-mail
            contato@loocal.online.
          </S.AnimationContainer>
        </S.Content>
      </S.Container>
    </>
  );
};

export default Terms;
