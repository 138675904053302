import React, { useCallback, useEffect, useRef, useState } from "react";
// import Axios from "axios";

import companyMarker from "../../assets/img/company-marker.png";
import motoMarker from "../../assets/img/moto-marker.png";
import starMarker from "../../assets/img/star-marker.png";

import * as Styled from "./styles";

interface DeliverymanMapProps {
  deliverymanLocation: number[];
  customerLocation: number[];
  companyLocation: number[] | never[];
  isOrderFinished: boolean;
}

const DeliverymanMap: React.FC<DeliverymanMapProps> = ({
  deliverymanLocation,
  customerLocation,
  companyLocation,
  isOrderFinished,
}) => {
  const [mapAlreadyLoaded, setMapAlreadyLoaded] = useState(false);

  const mapDivRef = useRef<HTMLInputElement>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const deliverymanMarkerRef = useRef<google.maps.Marker | null>(null);
  const customerMarkerRef = useRef<google.maps.Marker | null>(null);
  const companyMarkerRef = useRef<google.maps.Marker | null>(null);

  const lastDeliverymanLocationRef = useRef<number[] | null>(null);

  const deltaLat = useRef(0);
  const deltaLng = useRef(0);
  const movingIndex = useRef(0);

  const numDeltas = 100;
  const delay = 100;

  const moveMarker = useCallback(() => {
    if (lastDeliverymanLocationRef.current) {
      lastDeliverymanLocationRef.current[0] += deltaLat.current;
      lastDeliverymanLocationRef.current[1] += deltaLng.current;

      const latlng = new google.maps.LatLng(
        lastDeliverymanLocationRef.current[0],
        lastDeliverymanLocationRef.current[1],
      );

      deliverymanMarkerRef.current?.setTitle(
        `Latitude:${lastDeliverymanLocationRef.current[0]} | Longitude:${lastDeliverymanLocationRef.current[1]}`,
      );

      deliverymanMarkerRef.current?.setPosition(latlng);

      if (movingIndex.current !== numDeltas) {
        movingIndex.current++;
        setTimeout(moveMarker, delay);
      } else {
        /*    directionsService.current?.route(
          {
            origin: {
              query: deliverymanLocation.join(","),
            },
            destination: {
              query: customerLocation.join(","),
            },
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === "OK") {
              directionsRenderer.current?.setDirections(response);
            } else {
              // failed
            }
          },
        ); */
      }
    }
  }, [movingIndex]);

  const transition = useCallback(
    (result: number[]) => {
      if (lastDeliverymanLocationRef.current) {
        movingIndex.current = 0;
        deltaLat.current =
          (result[0] - lastDeliverymanLocationRef.current[0]) / numDeltas;
        deltaLng.current =
          (result[1] - lastDeliverymanLocationRef.current[1]) / numDeltas;
        moveMarker();
      }
    },
    [moveMarker],
  );

  const initialize = useCallback(() => {
    const deliverymanLatLng = isOrderFinished
      ? new google.maps.LatLng(
          customerLocation[0] - 0.00001,
          customerLocation[1] + 0.0001,
        )
      : new google.maps.LatLng(deliverymanLocation[0], deliverymanLocation[1]);

    lastDeliverymanLocationRef.current = isOrderFinished
      ? [customerLocation[0] - 0.00001, customerLocation[1] + 0.00006]
      : deliverymanLocation;

    const customerLatLng = new google.maps.LatLng(
      customerLocation[0],
      customerLocation[1],
    );

    const companyLatLng = new google.maps.LatLng(
      companyLocation[0],
      companyLocation[1],
    );

    mapRef.current = new google.maps.Map(mapDivRef.current as HTMLDivElement, {
      zoom: 15,
      center: customerLatLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    });

    deliverymanMarkerRef.current = new google.maps.Marker({
      position: deliverymanLatLng,
      map: mapRef.current,
      title: "Deliveryman Marker",
      icon: motoMarker,
      zIndex: 5,
    });

    customerMarkerRef.current = new google.maps.Marker({
      position: customerLatLng,
      map: mapRef.current,
      title: "Customer Marker",
      icon: starMarker,
      zIndex: 1,
    });

    companyMarkerRef.current = new google.maps.Marker({
      position: companyLatLng,
      map: mapRef.current,
      title: "Company Marker",
      icon: companyMarker,
    });

    setMapAlreadyLoaded(true);
  }, [deliverymanLocation, customerLocation, isOrderFinished, companyLocation]);

  useEffect(() => {
    if (lastDeliverymanLocationRef.current !== null && !isOrderFinished) {
      if (
        deliverymanLocation[0] !== lastDeliverymanLocationRef.current[0] ||
        deliverymanLocation[1] !== lastDeliverymanLocationRef.current[1]
      ) {
        transition(deliverymanLocation);
      }
    }
  }, [deliverymanLocation, transition, customerLocation, isOrderFinished]);

  useEffect(() => {
    if (isOrderFinished) {
      transition([customerLocation[0] - 0.00001, customerLocation[1] + 0.0001]);
    }
  }, [isOrderFinished, customerLocation, transition]);

  useEffect(() => {
    if (!mapAlreadyLoaded) {
      initialize();
    }
  }, [initialize, mapAlreadyLoaded]);

  return <Styled.Container ref={mapDivRef} />;
};

export default DeliverymanMap;
