import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import {
  FiAtSign,
  FiCalendar,
  FiLock,
  FiSettings,
  FiUnlock,
  FiUser,
} from "react-icons/fi";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";

import api from "../../services/api";

import { useAuth } from "../../hooks/AuthContext";
import { useSidebar } from "../../hooks/SidebarContext";

import getValidationErrors from "../../utils/getValidationErrors";
import formatLocale from "../../utils/formatLocale";

import LoadingSpinner from "../../components/LoadingSpinner";
import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import ReadonlyInput from "../../components/ReadonlyInput";
import Button from "../../components/Button";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import * as Styled from "./styles";

interface ResponsibleFormData {
  newPassword: string;
  oldPassword: string;
  passwordConfirmation: string;
}

const Responsible: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { responsible_id } = useAuth();
  const { addToast } = useToasts();
  const { isSidebarMaximized } = useSidebar();

  useEffect(() => {
    (async () => {
      try {
        const { data: responsibleResponseData } = await api.get(
          `responsibles/${responsible_id}`,
        );
        const updated_at = formatLocale(
          responsibleResponseData.user.updated_at,
          "dd/MM/yyy HH:mm",
        );
        formRef.current?.setData({
          name: responsibleResponseData.user.name,
          email: responsibleResponseData.user.email,
          updated_at,
        });
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    })();
  }, [responsible_id, addToast]);

  const handleSubmit = useCallback(
    async (data: ResponsibleFormData) => {
      setIsLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          oldPassword: Yup.string().min(4, "No mínimo 4 digitos"),
          newPassword: Yup.string().min(4, "No mínimo 4 digitos"),
          passwordConfirmation: Yup.string()
            .min(4, "No mínimo 4 digitos")
            .oneOf([Yup.ref("newPassword")], "Confirmação inválida"),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put("/password", {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        });
        addToast("Dados atualizados com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        formRef.current?.setData({
          oldPassword: "",
          passwordConfirmation: "",
          newPassword: "",
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response?.status === 400) {
          addToast(
            "Ocorreu um erro ao atualizar dados, verifique as credenciais!",
            {
              appearance: "warning",
              autoDismiss: true,
            },
          );
        } else {
          addToast(
            "Ocorreu um erro ao atualizar dados, entre em contato com o suporte!",
            {
              appearance: "warning",
              autoDismiss: true,
            },
          );
        }
      }
      setIsLoading(false);
    },
    [addToast],
  );

  return (
    <Container>
      <Sidebar />
      {isLoading && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.MyDataContainer>
          <Styled.Title>Meus Dados - Responsável</Styled.Title>
          <Styled.MyDataForm
            onSubmit={handleSubmit}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Responsável</Styled.Strong>
            <Styled.Row>
              <ReadonlyInput
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                icon={FiUser}
              />
              <ReadonlyInput
                type="text"
                id="email"
                name="email"
                placeholder="E-mail"
                icon={FiAtSign}
              />
            </Styled.Row>
            <Styled.Row>
              <ReadonlyInput
                type="text"
                id="role"
                name="role"
                placeholder="Função"
                value="Operador"
                icon={FiSettings}
              />
              <ReadonlyInput
                type="text"
                id="updated_at"
                name="updated_at"
                placeholder="Última atualização"
                icon={FiCalendar}
              />
            </Styled.Row>
            <Styled.Strong>Alterar a senha</Styled.Strong>
            <Styled.Row>
              <Input
                type="password"
                id="oldPassword"
                name="oldPassword"
                placeholder="Senha Atual"
                icon={FiUnlock}
              />
              <Input
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder="Nova Senha"
                icon={FiLock}
              />
              <Input
                type="password"
                id="passwordConfirmation"
                name="passwordConfirmation"
                placeholder="Confirme a Senha"
                icon={FiLock}
              />
            </Styled.Row>

            <div>
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.MyDataForm>
        </Styled.MyDataContainer>
      </ContainerMain>
    </Container>
  );
};

export default Responsible;
