import styled from "styled-components";

interface ButtonProps {
  backgroundColor: string;
  hoverColor: string;
}

interface LabelProps {
  backgroundColor: string;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  flex: 1;
  border: 0;
  background: ${(props) => props.backgroundColor};
  outline: none;
  color: #fff;
  text-shadow: 1px 1px 1px #ccc;
  font-size: 16px;
  padding: 14px 0;
  border: solid 1px #ccc;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;

  &:hover {
    background: ${(props) => props.hoverColor};
  }
`;

export const Label = styled.div<LabelProps>`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${(props) => props.backgroundColor};
  font-weight: bold;
`;
