import React from "react";
import { FiArrowLeft, FiAtSign } from "react-icons/fi";
import { Link } from "react-router-dom";

import logoImg from "../../assets/img/logo-movio.webp";

import * as Styled from "./styles";

const Contact: React.FC = () => {
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.AnimationContainer>
          <img src={logoImg} alt="Loocal Logotipo" />
          <Styled.ContactContainer>
            <h1> Fale conosco </h1>
            <Styled.ContactChannels>
              <h3>Nos envie uma mensagem através do nosso email:</h3>

              <Styled.Contact>
                <FiAtSign size={17} /> <span>Email: </span>
                suporte@loocal.online
              </Styled.Contact>
            </Styled.ContactChannels>
            <Link to="/">
              {" "}
              <FiArrowLeft size={17} /> Voltar ao login
            </Link>
          </Styled.ContactContainer>
        </Styled.AnimationContainer>
      </Styled.Content>
      <Styled.Background />
    </Styled.Container>
  );
};

export default Contact;
