import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  OnlineDeliveryman,
  OnlineDeliverymanV1,
} from "../../@types/customTypes";

import motoMarker from "../../assets/img/moto-marker.png";

import * as Styled from "./styles";

interface OnlineDeliverymansMapProps {
  deliverymansData: OnlineDeliveryman[] | OnlineDeliverymanV1[] | never[];
}

interface DeliverymanMarkerRefProps {
  id: number;
  marker: google.maps.Marker;
}

const OnlineDeliverymansMap: React.FC<OnlineDeliverymansMapProps> = ({
  deliverymansData,
}) => {
  const [mapAlreadyLoaded, setMapAlreadyLoaded] = useState(false);

  const mapDivRef = useRef<HTMLInputElement>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const deliverymansMarkerRef = useRef<DeliverymanMarkerRefProps[] | null>(
    null,
  );

  const initialize = useCallback(() => {
    const firstDeliverymanLatLng = new google.maps.LatLng(
      Number(deliverymansData[0].latitude),
      Number(deliverymansData[0].longitude),
    );

    mapRef.current = new google.maps.Map(mapDivRef.current as HTMLDivElement, {
      zoom: 11,
      center: firstDeliverymanLatLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    });

    deliverymansData.forEach((deliveryman) => {
      const tempLatLgn = new google.maps.LatLng(
        Number(deliveryman.latitude),
        Number(deliveryman.longitude),
      );

      const tempMarker = new google.maps.Marker({
        position: tempLatLgn,
        map: mapRef.current as google.maps.Map,
        title: deliveryman.nickname,
        icon: motoMarker,
        zIndex: 5,
      });

      const deliverymanNameInfoWindow = new google.maps.InfoWindow({
        content: deliveryman.nickname,
      });

      google.maps.event.addListener(tempMarker, "click", () => {
        deliverymanNameInfoWindow.open({
          anchor: tempMarker,
          map: mapRef.current,
          shouldFocus: false,
        });
      });

      deliverymansMarkerRef.current?.push({
        id: deliveryman.id,
        marker: tempMarker,
      });
    });

    setMapAlreadyLoaded(true);
  }, [deliverymansData]);

  useEffect(() => {
    if (!mapAlreadyLoaded) {
      initialize();
    }
  }, [initialize, mapAlreadyLoaded]);

  return <Styled.Container ref={mapDivRef} />;
};

export default OnlineDeliverymansMap;
