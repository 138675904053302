import styled from "styled-components";

export const RadioContainer = styled.div`
  > label {
    display: block;
    color: #373737;

    > input {
      margin-top: 15px;
      margin-right: 10px;
    }
  }
`;
