/* eslint-disable */
import React, { useCallback, useRef, useState } from "react";
import { OptionTypeBase, Props as SelectProps, ActionMeta } from "react-select";
import { IconBaseProps } from "react-icons/lib";
import { FiAlertCircle } from "react-icons/fi";

import * as Styled from "./styles";

interface ReactSelectProps
  extends Omit<SelectProps<OptionTypeBase>, "isMulti"> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  childrenStateUpdater?: number;
  hasAnimation?: boolean;
  onBlur?: any;
  onChange?: any;
  isMulti?: boolean;
}

const ReactSelect: React.FC<ReactSelectProps> = ({
  name,
  placeholder,
  icon: Icon,
  hasAnimation,
  childrenStateUpdater,
  onChange,
  isSearchable,
  onBlur,
  defaultValue,
  ...rest
}) => {
  const [hasValue, setHasValue] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState("");
  const selectRef = useRef<any>(null);
  // const {
  //   fieldName,
  //   defaultValue,
  //   registerField,
  //   error,
  //   clearError,
  // } = useField(name);

  // useEffect(() => {
  //   if (childrenStateUpdater) {
  //     setHasValue(false);
  //   }
  // }, [childrenStateUpdater]);

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: selectRef.current,
  //     getValue: (ref: any) => {
  //       if (rest.isMulti) {
  //         if (!ref.state.value) {
  //           return [];
  //         }
  //         return ref.state.value.map((option: OptionTypeBase) => option.value);
  //       }
  //       if (!ref.state.value) {
  //         return "";
  //       }
  //       return ref.state.value.value;
  //     },
  //     setValue: (ref: any, value: any) => {
  //       if (rest.isMulti && Array.isArray(value)) {
  //         const items = ref?.props?.options?.filter((option: any) =>
  //           value.includes(option.value),
  //         );
  //         ref?.select.setValue(items);
  //       } else {
  //         const item = ref?.props?.options?.filter((option: any) => {
  //           return option.value === value;
  //         });
  //         if (item && item.length > 0) {
  //           ref?.select?.setValue(item[0]);
  //         }
  //       }
  //     },
  //     clearValue: (ref: any) => {
  //       ref?.select?.clearValue();
  //     },
  //   });
  // }, [fieldName, registerField, rest.isMulti]);

  const handleOnChange = useCallback(
    (option: OptionTypeBase, action: ActionMeta<OptionTypeBase>): void => {
      setHasValue(!!option);

      if (action.action === "set-value" || action.action === "clear") {
        return;
      }

      if (onChange) {
        onChange(option);
      }
    },
    [onChange],
  );

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);

    if (isSearchable) {
      setIsViewing(false);
    }

    if (onBlur) {
      onBlur(selectRef.current?.state.value);
    }
  }, [onBlur, isSearchable]);

  const handleOnFocus = useCallback(() => {
    setError("");
    setIsFocused(true);

    if (isSearchable) {
      setIsViewing(true);
    }
  }, [setError, isSearchable]);

  return (
    <Styled.Container
      isErrored={!!error}
      isFocused={isFocused}
      hasAnimation={!!hasAnimation}
    >
      {Icon && <Icon size={20} />}
      <Styled.ReactSelect
        ref={selectRef}
        classNamePrefix="react-select"
        placeholder=""
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        onBlur={handleOnBlur}
        {...rest}
      />
      <Styled.Label htmlFor={rest.id} hasValue={hasValue || isViewing}>
        {placeholder}
      </Styled.Label>
      {error && (
        <Styled.Error title={error}>
          <FiAlertCircle size={20} />
        </Styled.Error>
      )}
    </Styled.Container>
  );
};

export default ReactSelect;
