import * as React from "react";

type ContextProps = {
  balance: string;
  isLoading: boolean;
  onLoadBalance: (value: boolean) => void;
  onChangeBalance: (value: number) => void;
};

const BalanceContext = React.createContext<ContextProps>({
  balance: "",
  isLoading: false,
  onLoadBalance: () => {},
  onChangeBalance: () => {},
});

export const useBalance = (): ContextProps => {
  const context = React.useContext(BalanceContext);

  if (!context) {
    throw new Error("useBalance must be used within a BalanceProvider");
  }

  return context;
};

export const BalanceProvider: React.FC = ({ children }) => {
  const [balance, setBalance] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const onChangeBalance = React.useCallback((value: number) => {
    const formatedBalance = value?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    setBalance(formatedBalance);
    setIsLoading(false);
  }, []);

  const onLoadBalance = React.useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  return (
    <BalanceContext.Provider
      value={{ balance, onChangeBalance, isLoading, onLoadBalance }}
    >
      {children}
    </BalanceContext.Provider>
  );
};
