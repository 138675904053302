import { shade } from "polished";
import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface InputContainer {
  isFocused: boolean;
  isErrored: boolean;
  hasContent: boolean;
}

export const Container = styled.div<InputContainer>`
  position: relative;
  width: 100%;
  border: 1px solid rgba(220, 220, 220);
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: white;
  padding: 2px 0px;
  position: relative;
  min-width: 250px;

  > label {
    color: #8e8e8e;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    padding: 0 3px;
    left: 47px;
    transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -moz-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  .react-autosuggest__container {
    flex: 1;
  }

  .react-autosuggest__input {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1;
    border: 0;
    background: transparent;
    outline: none;
    color: #373737;
    font-size: 16px;
    padding: 14px 0;
    margin-right: 16px;

    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;

    &::placeholder {
      color: #8e8e8e;
    }
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    display: none;
    z-index: 5;
    background: white;
    padding: 10px 15px;
    border-radius: 4px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  }

  .react-autosuggest__suggestions-container--open {
    display: unset;
  }

  .react-autosuggest__suggestions-list {
    list-style: none;

    > li {
      > span {
        font-size: 13px;
        font-weight: normal;
        line-height: unset;
        border-top: 0;
        color: var(--color-text-primary);
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
        padding-bottom: 2px;
        border-bottom: 1px solid rgba(220, 220, 220, 0.5);
      }

      &:hover {
        background: unset;
        > span {
          color: ${shade(0.6, "#253451")};
          > span {
            color: ${shade(0.6, "#253451")};
          }
        }
      }
    }
  }

  ${({ isErrored }) =>
    isErrored &&
    css`
      .react-autosuggest__input {
        padding-right: 45px;
      }
      > svg:first-child {
        stroke: #c53030;
      }
      border-color: #c53030;
    `}

  ${({ hasContent }) =>
    hasContent &&
    css`
      label {
        position: absolute !important;
        top: -10px;
        left: 20px;
        background: white;
        font-size: 14px;
        color: #8e8e8e;
      }
    `};

  ${({ isFocused }) =>
    isFocused &&
    css`
      > svg:first-child {
        stroke: var(--color-primary) !important;
      }
      border-color: var(--color-primary);
      label {
        position: absolute !important;
        top: -10px;
        left: 20px;
        background: white;
        font-size: 14px;
        color: #8e8e8e;
      }
    `}

  &:hover {
    > svg:first-child {
      stroke: var(--color-primary) !important;
    }
    border-color: var(--color-primary);
  }

  > svg {
    margin: 0 16px;
    stroke: #8e8e8e;
    transition: opacity 0.3s;
    min-width: 20px;
    min-height: 20px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  right: 0;
  margin: 0 16px;
  > svg {
    stroke: #c53030;
  }
`;
