import React, { useCallback, useState, useRef, useEffect } from "react";
import { FormHandles } from "@unform/core";
import { FiArrowLeft, FiLock, FiMail, FiUnlock } from "react-icons/fi";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import api from "../../services/api";

import Input from "../../components/Input";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";

import getValidationErrors from "../../utils/getValidationErrors";

import logoImg from "../../assets/img/logo-movio.webp";

import * as Styled from "./styles";

interface ForgotFormData {
  email?: string;
  password?: string;
  password_confirmation?: string;
  token?: string | null;
}

const Forgot: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState<null | string>(null);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToasts();
  const { search } = useLocation();
  const { push } = useHistory();

  const handleGenerateNewToken = useCallback(
    async (data: ForgotFormData) => {
      setIsLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required("E-mail obrigatório")
            .email("Digite um e-mail válido"),
        });
        await schema.validate(data, { abortEarly: false });
        await api.post("/password/forgot", data);
        Swal.fire({
          title: "E-mail enviado!",
          text: "Enviamos instruções ao seu e-mail, confira também a caixa de lixo eletrônico (spam).",
          icon: "success",
          confirmButtonColor: "var(--color-primary)",
          confirmButtonText: "Ok",
        });
        formRef.current?.clearField("email");
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response?.status === 422) {
          formRef.current?.setErrors({
            email: "E-mail inválido",
          });
          addToast("Ocorreu um erro ao recuperar senha, verifique o email!", {
            appearance: "warning",
            autoDismiss: true,
          });
        } else {
          addToast(
            "Ocorreu um erro ao recuperar senha, verifique sua conexão!",
            {
              appearance: "warning",
              autoDismiss: true,
            },
          );
        }
      }
      setIsLoading(false);
    },
    [addToast],
  );

  const handleSubmitNewPassword = useCallback(
    async (data: ForgotFormData) => {
      setIsLoading(true);
      data.token = token;
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().min(4, "No mínimo 4 digitos"),
          password_confirmation: Yup.string()
            .min(4, "No mínimo 4 digitos")
            .oneOf([Yup.ref("password")], "Confirmação inválida"),
        });
        await schema.validate(data, { abortEarly: false });
        await api.post("/password/reset", data);
        push("/signin?password=success");
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          addToast("Ocorreu um erro ao alterar senha, tente novamente!", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      }
      setIsLoading(false);
    },
    [addToast, push, token],
  );

  useEffect(() => {
    const query = new URLSearchParams(search);
    setToken(query.get("token") || null);
  }, [search]);

  return (
    <Styled.Container>
      {isLoading && <LoadingSpinner />}
      <Styled.Content>
        <Styled.AnimationContainer>
          <img src={logoImg} alt="Loocal Logotipo" />
          <Styled.Form
            onSubmit={token ? handleSubmitNewPassword : handleGenerateNewToken}
            ref={formRef}
          >
            <h1>{token ? "Salvar senha" : "Recuperar senha"}</h1>
            <Styled.InputAndButtonContainer>
              {token ? (
                <>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Senha"
                    icon={FiUnlock}
                  />

                  <Input
                    type="password"
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Confirme a senha"
                    icon={FiLock}
                  />

                  <Button
                    type="submit"
                    content={isLoading ? "Carregando..." : "Salvar"}
                    disabled={isLoading}
                  />
                </>
              ) : (
                <>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    autoComplete="null"
                    icon={FiMail}
                  />

                  <Button
                    type="submit"
                    content={isLoading ? "Carregando..." : "Recuperar"}
                    disabled={isLoading}
                  />
                </>
              )}
              <Link to="/">
                {" "}
                <FiArrowLeft size={17} /> Voltar ao login
              </Link>
            </Styled.InputAndButtonContainer>
          </Styled.Form>
        </Styled.AnimationContainer>
      </Styled.Content>
      <Styled.Background />
    </Styled.Container>
  );
};

export default Forgot;
