/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Modal from "../Modal";
import ButtonV2 from "../ButtonV2";
import OnlineDeliverymansMap from "../OnlineDeliverymansMap";

import {
  OnlineDeliveryman,
  OnlineDeliverymanV1,
} from "../../@types/customTypes";

import * as Styled from "./styles";

type Steps = "map" | "initial";

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  deliverymansData:
    | OnlineDeliveryman[]
    | OnlineDeliverymanV1[]
    | undefined
    | null;
  initialStep?: Steps;
}

const OnlineDeliverymansModal: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  deliverymansData,
  initialStep,
}) => {
  const [step, setStep] = useState<Steps>(initialStep || "initial");

  const { push } = useHistory();

  const handleChangeStep = useCallback((selectedStep: Steps) => {
    setStep(selectedStep);
  }, []);

  useEffect(() => {
    if (!isOpen) setTimeout(() => setStep(initialStep || "initial"), 100);
  }, [isOpen, initialStep]);

  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      customStyles={{ maxWidth: "800px", padding: "0", borderRadius: "4px" }}
    >
      <Styled.Container>
        {step === "initial" && (
          <Styled.InitialStepContainer>
            <Styled.InitialStepTitle>
              O que deseja fazer?
            </Styled.InitialStepTitle>

            <Styled.InitialStepActions>
              <ButtonV2
                onClick={() => handleChangeStep("map")}
                title="Ver mapa"
                type="button"
              />
              <ButtonV2
                onClick={() =>
                  push("/gerenciar/por-cidade?conteudo=listaEntregadores")
                }
                title="Ver lista"
                type="button"
                isSecondary
              />
            </Styled.InitialStepActions>
          </Styled.InitialStepContainer>
        )}
        {step === "map" && (
          <OnlineDeliverymansMap deliverymansData={deliverymansData || []} />
        )}
      </Styled.Container>
    </Modal>
  );
};

export default OnlineDeliverymansModal;
