import React from "react";
import * as Styled from "./styles";

interface ContainerMainProps {
  isSidebarMaximized: boolean;
}

const ContainerMain: React.FC<ContainerMainProps> = ({
  children,
  isSidebarMaximized,
}) => (
  <Styled.Main isSidebarMaximized={isSidebarMaximized}>{children}</Styled.Main>
);

export default ContainerMain;
