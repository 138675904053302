import { lighten } from "polished";
import styled from "styled-components";
import { Link as ReactLink } from "react-router-dom";

export const Container = styled.div``;

export const DetailsContainer = styled.div`
  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Strong = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 15px;
  color: #373737;
`;

export const CommonField = styled.div`
  display: flex;
  align-items: center;
  > p {
    color: #373737;
    margin-left: 50px;
    > strong {
      font-weight: 500;
    }
  }
`;

export const FieldTitle = styled.div`
  display: flex;
  align-items: center;
  min-width: 178px;

  > h3 {
    color: var(--color-text-secondary);
    margin-left: 8px;
  }
  > svg {
    color: var(--color-primary);
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  > p {
    color: #373737;
    margin-left: 50px;
    > strong {
      font-weight: 500;
    }
  }
`;

export const Image = styled.img`
  height: 130px;
  width: 130px;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const ImageTitle = styled.p`
  color: #373737;
  text-align: center;
`;

export const ActionContainer = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;

  > svg {
    margin-left: 15px;
  }

  > a > svg,
  svg {
    stroke: #373737;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      stroke: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const Link = styled(ReactLink)``;
