import React, { useState, useRef, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";

import ReactCodeInput from "../../components/CodeInput/ReactCodeInput.js";
import Sidebar from "../../components/Sidebar";
import Container from "../../components/Container";
import LoadingSpinner from "../../components/LoadingSpinner";
import ContainerMain from "../../components/ContainerMain";

import api from "../../services/api";

import { useAuth } from "../../hooks/AuthContext";
import { useSidebar } from "../../hooks/SidebarContext";

import * as Styled from "./styles";

const IfoodIntegration: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [integrationUrl, setIntegrationUrl] = useState("");
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [code, setCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tokenCountdown, setTokenCountdown] = useState(360);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const { company_id, ifoodStatus, changeIfoodStatus } = useAuth();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const tokenCountdownInterval = useRef<NodeJS.Timeout | null>(null);

  const handleSetIsFocused = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleOnChangeCode = (typedCode: string) => {
    setCurrentStep(2);
    setCompanyName("");
    setCode(typedCode);
  };

  const handleOnChangeCompanyName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCompanyName(event.target.value);
  };

  const handleSwitchIntegration = async () => {
    try {
      setIsLoading(true);
      if (ifoodStatus === "enabled" || ifoodStatus === "finished") {
        await api.patch("ifood/disable", { company_id });
        localStorage.setItem("@Loocal-client:ifood", "disabled");
        changeIfoodStatus("disabled");
        setIntegrationUrl("");
        setCompanyName("");
        setCode("");
        clearInterval(tokenCountdownInterval.current as NodeJS.Timeout);
        setTokenCountdown(360);
        setCurrentStep(1);
      } else {
        await api.post("ifood/create", { company_id });
        localStorage.setItem("@Loocal-client:ifood", "enabled");
        changeIfoodStatus("enabled");
      }
    } catch (err: any) {
      addToast(
        err.response?.data.message || "Falha ao mudar estado da integração!",
        {
          appearance: "warning",
          autoDismiss: true,
        },
      );
    }
    setIsLoading(false);
  };

  const handleGenerateIfoodLink = async () => {
    try {
      setIsLoading(true);
      const { data } = await api.post("ifood/create-user-code", { company_id });
      tokenCountdownInterval.current = setInterval(() => {
        setTokenCountdown((state) => (state !== 0 ? state - 1 : 0));
      }, 1000);

      addToast(
        "Link gerado com sucesso, o token gerado a seguir expira em 6 minutos.",
        {
          appearance: "success",
          autoDismiss: true,
        },
      );

      setIntegrationUrl(data.verificationUrlComplete);
      setCurrentStep(2);
    } catch (err: any) {
      addToast(err.response?.data.message || "Falha ao gerar link!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    setIsLoading(false);
  };

  const handleFinishIntegration = async () => {
    try {
      setIsLoading(true);

      await api.post("ifood/integration", {
        company_id,
        merchant_name: companyName,
        authorization_code: code?.match(/.{4}/g)?.join("-"),
      });

      localStorage.setItem("@Loocal-client:ifood", "finished");

      Swal.fire({
        title: "Integração bem sucedida!",
        text: "A integração com o ifood foi realizada com sucesso!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "var(--color-primary)",
        confirmButtonText: "Ok",
      }).then(() => {
        changeIfoodStatus("finished");
        setIntegrationUrl("");
        setCompanyName("");
        setCode("");
        clearInterval(tokenCountdownInterval.current as NodeJS.Timeout);
        setTokenCountdown(360);
        setCurrentStep(0);
      });
    } catch (err: any) {
      addToast(err.response?.data.message || "Falha ao enviar dados!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    setIsLoading(false);
  };

  const copyLinkToClipboard = () => {
    inputRef.current?.select();
    document.execCommand("copy");

    addToast("Link copiado para sua área de transferência!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const handleGoToLastStep = () => {
    setCurrentStep(3);
  };

  useEffect(() => {
    if (tokenCountdown === 0) {
      Swal.fire({
        title: "Token expirado!",
        text: "O seu token expirou, reinicie o processo e tente novamente!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "var(--color-primary)",
        confirmButtonText: "Ok",
      }).then(() => {
        setIntegrationUrl("");
        setCompanyName("");
        setCode("");
        clearInterval(tokenCountdownInterval.current as NodeJS.Timeout);
        setTokenCountdown(360);
        setCurrentStep(1);
      });
    }
  }, [tokenCountdown]);

  useEffect(() => {
    const integrationStatus = localStorage.getItem("@Loocal-client:ifood");

    if (integrationStatus === "finished") {
      setCurrentStep(0);
    } else {
      setCurrentStep(1);
    }
  }, [changeIfoodStatus]);

  return (
    <Container>
      <Sidebar />
      {isLoading && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.Content>
          {currentStep === 0 && (
            <Styled.IntegrationCard>
              <Styled.Title>
                A integração está ativa, deseja desabilitar?
              </Styled.Title>

              <Styled.DisableIntegration>
                <Styled.Switch
                  onColor="#466aa9"
                  width={36}
                  height={18}
                  onChange={handleSwitchIntegration}
                  checked={ifoodStatus === "finished"}
                />
                Desabilitar integração
              </Styled.DisableIntegration>
            </Styled.IntegrationCard>
          )}

          {currentStep! >= 1 && (
            <Styled.IntegrationCard>
              <Styled.StepBadge>1</Styled.StepBadge>
              <Styled.EnableIntegration>
                <Styled.Switch
                  onColor="#466aa9"
                  width={36}
                  height={18}
                  onChange={handleSwitchIntegration}
                  checked={ifoodStatus === "enabled"}
                />
                Habilitar integração
              </Styled.EnableIntegration>
              <Styled.InputContainer>
                <Styled.Input
                  isFocused={isFocused}
                  onFocus={handleSetIsFocused}
                  onBlur={handleInputBlur}
                  disabled={ifoodStatus === "disabled"}
                  value={integrationUrl}
                  ref={inputRef}
                  readOnly
                />
                <Styled.Button
                  onClick={copyLinkToClipboard}
                  disabled={ifoodStatus === "disabled" || !integrationUrl}
                >
                  Copiar
                </Styled.Button>

                {!integrationUrl && (
                  <Styled.GenerateLinkButton
                    onClick={handleGenerateIfoodLink}
                    disabled={ifoodStatus === "disabled"}
                  >
                    Gerar link
                  </Styled.GenerateLinkButton>
                )}
              </Styled.InputContainer>
            </Styled.IntegrationCard>
          )}

          {currentStep! >= 2 && (
            <Styled.IntegrationCard>
              <Styled.StepBadge>2</Styled.StepBadge>

              <Styled.TitleContainer>
                <Styled.Title>
                  Digite abaixo, o código que foi gerado no iFood:
                </Styled.Title>

                <Styled.CircularProgress
                  value={tokenCountdown}
                  strokeWidth={12}
                  minValue={0}
                  maxValue={365}
                />
              </Styled.TitleContainer>

              <ReactCodeInput
                forceUppercase
                name="code"
                inputMode="numeric"
                fields={8}
                onChange={handleOnChangeCode}
                value={code}
                className="input-code"
                inputStyle={{
                  width: 40,
                  borderRadius: 6,
                  fontSize: 30,
                  height: 42,
                  paddingLeft: 10,
                  borderWidth: 1,
                  borderColor: "lightgray",
                  borderStyle: "solid",
                  color: "#373737",
                }}
              />

              <Styled.Button
                onClick={handleGoToLastStep}
                disabled={code.length < 5}
              >
                Continuar
              </Styled.Button>
            </Styled.IntegrationCard>
          )}

          {currentStep! === 3 && (
            <Styled.IntegrationCard>
              <Styled.StepBadge>3</Styled.StepBadge>
              <Styled.Title>
                Digite abaixo, o nome da sua empresa que consta no iFood:
              </Styled.Title>

              <Styled.Input
                isFocused={isFocused}
                onFocus={handleSetIsFocused}
                onBlur={handleInputBlur}
                onChange={handleOnChangeCompanyName}
                placeholder="Digite o nome da sua empresa..."
                value={companyName}
              />

              <Styled.Button
                onClick={handleFinishIntegration}
                disabled={companyName.length < 3}
              >
                Enviar
              </Styled.Button>
            </Styled.IntegrationCard>
          )}
        </Styled.Content>
      </ContainerMain>
    </Container>
  );
};

export default IfoodIntegration;
