import styled, { css } from "styled-components";
import reactSelect from "react-select";
import { shade } from "polished";

import Tooltip from "../Tooltip";

interface ContainerProps {
  isErrored: boolean;
  hasAnimation: boolean;
  isFocused: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;

  > svg {
    position: absolute;
    top: 17px;
    left: 17px;
    color: #8e8e8e;
    > path {
      stroke: #8e8e8e;
    }
    z-index: 2;
  }

  &:hover {
    > svg {
      color: var(--color-primary);
      > path {
        stroke: #8e8e8e;
      }
    }

    .react-select__control {
      border-color: var(--color-primary);
    }

    .react-select__indicator {
      color: var(--color-primary);
      cursor: pointer;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${({ hasAnimation }) =>
    hasAnimation &&
    css`
      animation: fadeIn 1s;
    `}

  ${({ isErrored }) =>
    isErrored &&
    css`
      > svg:first-child {
        color: #c53030;
        > path {
          stroke: #c53030;
        }
      }

      .react-select__control {
        border-color: #c53030 !important;
      }
    `}

    ${({ isFocused }) =>
    isFocused &&
    css`
      > svg:first-child {
        stroke: var(--color-primary) !important;
        color: var(--color-primary) !important;
        > path {
          stroke: var(--color-primary) !important;
        }
      }
      .react-select__control {
        border-color: var(--color-primary) !important;
      }
    `}
`;

export const ReactSelect = styled(reactSelect)`
  font-size: 16px;
  width: 100%;

  .react-select__control {
    position: relative;
    width: 100%;
    border: 1px solid rgba(220, 220, 220);
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 2px 0px;
    position: relative;
    min-width: 250px;
    min-height: 53px;
    cursor: default;
    width: 100%;
  }

  .react-select__control--is-focused {
    box-shadow: none;
  }

  .react-select__placeholder {
    color: #8e8e8e;
  }

  .react-select__loading-indicator {
    display: none;
  }

  .react-select__value-container {
    padding-left: 48px;
    > div {
    }
  }

  .react-select__single-value {
    color: #373737;
  }

  .react-select__menu {
    top: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(220, 220, 220, 1);
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    padding: 0 !important;
  }

  .react-select__input {
    width: 100%;
    > input {
      width: 100% !important;
    }
  }

  .react-select__menu-list {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding: 5px 10px;
  }

  .react-select__option--is-focused:active {
    background-color: unset;
  }

  .react-select__option--is-selected:active {
    background-color: unset;
  }

  .react-select__option {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(220, 220, 220, 0.5);
    }
    background: white;
    color: #253451;
    font-size: 13px;
    display: block;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 5px 0;
    cursor: pointer;
    &:hover {
      color: ${shade(0.6, "#253451")};
    }
  }

  .react-select__indicator {
    color: #8e8e8e;
    cursor: pointer;
  }

  .react-select__indicator-separator {
    display: none;
  }

  > input:focus {
    outline: none;
  }
`;

export const Label = styled.label<{ hasValue: boolean }>`
  color: #8e8e8e;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  padding: 0 3px;
  z-index: 2;
  background: white;
  transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  -moz-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  -webkit-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;

  top: ${({ hasValue }) => (hasValue ? `-8px` : "unset")};
  font-size: ${({ hasValue }) => (hasValue ? `14px` : `16px`)};
  left: ${({ hasValue }) => (hasValue ? `20px` : `47px`)};
  display: ${({ hasValue }) => (hasValue ? `unset` : `flex`)};
`;

export const Error = styled(Tooltip)`
  height: 20px;

  position: absolute;
  top: 17px;
  right: 11px;
  background: white;
  padding: 0 5px;
  > svg {
    stroke: #c53030;
  }
`;
