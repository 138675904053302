import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiEdit, FiRefreshCw, FiZoomIn } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";

import { FormHandles } from "@unform/core";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import BorderlessInput from "../../components/BorderlessInput";
import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import { PriceTable } from "../../@types/customTypes";

import * as Styled from "./styles";

interface TablesResponse {
  data: PriceTable[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
  };
}

const Tables: React.FC = () => {
  const [priceTables, setPriceTables] = useState<PriceTable[]>(
    [] as PriceTable[],
  );

  // eslint-disable-next-line
  const [selectedPriceTable, setSelectedPriceTable] = useState<PriceTable>(
    {} as PriceTable,
  );

  // eslint-disable-next-line
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line
  const [isFeeModalOpen, setIsFeeModalOpen] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState<null | NodeJS.Timeout>(
    null,
  );
  const [query, setQuery] = useState("");

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleGetTables = useCallback(
    async (page = 1 /* , size = 8, searchQuery = "" */) => {
      try {
        setIsLoading(true);
        const { data: tablesResponse }: { data: TablesResponse } =
          await api.get(`price-table`);
        tablesResponse.data.forEach((priceTable) => {
          switch (priceTable.type) {
            case "DEFAULT":
              priceTable.color = "var(--color-primary)";
              break;
            case "ESTABELECIMENTO":
              priceTable.color = "#e5e619";
              break;
            case "CIDADE":
              priceTable.color = "#4c4cff";
              break;
            default:
              priceTable.color = "var(--color-primary)";
              break;
          }
        });
        setCurrentPage(page);
        /*     setTotalPages(tablesResponse.meta.to); */
        setPriceTables(tablesResponse.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const debounceSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      e.persist();
      const newDebounceTimer = setTimeout(() => {
        handleGetTables(8 /* , 1, e.target.value */);
        setQuery(e.target.value);
      }, 500);
      setDebounceTimer(newDebounceTimer);
    },
    [debounceTimer, handleGetTables],
  );

  const handleSetIsFeeModalOpen = useCallback(() => {
    setIsFeeModalOpen((state) => !state);
  }, []);

  // eslint-disable-next-line
  const handleViewTableDetails = useCallback(
    (table: PriceTable) => {
      setSelectedPriceTable(table);
      handleSetIsFeeModalOpen();
    },
    [handleSetIsFeeModalOpen],
  );

  useEffect(() => {
    handleGetTables();
  }, [handleGetTables]);

  return (
    <Container>
      <Sidebar />
      {/*   {isFeeModalOpen && (
        <FeeModal isOpen={isFeeModalOpen} setIsOpen={handleSetIsFeeModalOpen} />
      )} */}

      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.TablesContainer>
          <Styled.Title>
            <div>
              Gerenciar - Tabelas
              <FiRefreshCw
                size={20}
                onClick={() => handleGetTables(8 /* , 1 */)}
              />
            </div>
            <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
              <Styled.TopLink to="nova-tabela">+ Nova Tabela</Styled.TopLink>
              <BorderlessInput
                type="text"
                id="search"
                name="search"
                onChange={debounceSearch}
                placeholder="Pesquisar"
                autoComplete="off"
              />
            </Styled.SearchForm>
          </Styled.Title>
          <Styled.ListContainer>
            {isLoading ? (
              <Styled.TablesShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.TablesShimmer>
            ) : (
              <Styled.Ul>
                {priceTables.map((priceTable) => {
                  return (
                    <Styled.Li
                      color={priceTable.color || "lightblue"}
                      key={priceTable.id}
                    >
                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>{priceTable.id}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Nome:</span>
                        <strong>{priceTable.name}</strong>
                      </Styled.CommonField>
                      <Styled.Separator />
                      <Styled.StatusField
                        color={priceTable.color || "lightblue"}
                      >
                        {/*  {priceTable.type} */} COMUM
                      </Styled.StatusField>
                      <Styled.ActionField>
                        <FiZoomIn
                          size={27}
                          /*  onClick={() => handleViewTableDetails(priceTable)} */
                        />
                        <Styled.Link to={`/gerenciar/tabelas/${priceTable.id}`}>
                          <FiEdit size={23} />
                        </Styled.Link>
                      </Styled.ActionField>
                    </Styled.Li>
                  );
                })}
              </Styled.Ul>
            )}

            {currentPage !== 0 && (
              <Pagination
                updateList={handleGetTables}
                totalPages={totalPages}
                currentPage={currentPage}
                searchQuery={query}
              />
            )}
          </Styled.ListContainer>
        </Styled.TablesContainer>
      </ContainerMain>
    </Container>
  );
};

export default Tables;
