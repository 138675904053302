import { Form } from "@unform/web";
import { lighten } from "polished";
import styled from "styled-components";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const NewOrdersContainer = styled.div`
  padding: 20px 30px;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
`;

export const Title = styled.h4`
  color: var(--color-text-secondary);
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
`;

export const SForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;

  > button {
    margin-top: 20px;
  }

  @media (min-width: 550px) {
    flex-direction: row;
    > button {
      max-width: 180px;
      margin: 0;
    }
  }
`;

export const AddNewOrder = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    > svg {
      stroke: ${lighten(0.2, "#373737")};
    }
    > span {
      color: ${lighten(0.2, "#373737")};
    }
  }
  > svg {
    stroke: #373737;
    transition: 0.3s;
    margin-right: 10px;
  }
  > span {
    color: #373737;
    transition: 0.3s;
  }
`;
