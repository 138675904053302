import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FiRefreshCw,
  FiZoomIn,
  FiX,
  FiPause,
  FiPlay,
  FiBriefcase,
  FiCompass,
  FiSmartphone,
  FiTruck,
  FiSearch,
  FiRotateCcw,
} from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { FormHandles } from "@unform/core";
import Swal from "sweetalert2";
import "react-circular-progressbar/dist/styles.css";

import api from "../../services/api";

import { useSidebar } from "../../hooks/SidebarContext";
import useStateCallback from "../../hooks/UseStateCallback";
import { useAuth } from "../../hooks/AuthContext";

import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import DetailsModal from "../../components/DetailsModal";
import Pagination from "../../components/Pagination";
import LoadingSpinner from "../../components/LoadingSpinner";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain"; // Inicializando params como um objeto vazio

import {
  formatCurrencyByText,
  phoneFormat,
} from "../../utils/inputAndTextMasks";
import formatLocale from "../../utils/formatLocale";

import { Order } from "../../@types/customTypes";
import {
  ARRIVED_AT_COLLECTION,
  CANCELED_STATUS_ID,
  DELIVERED_STATUS_ID,
  ON_THE_WAY,
  ON_THE_WAY_WITHDRAWAL,
  WAITING_STATUS_ID,
  // ORDERS_FILTER_OPTIONS,
} from "./constants";
import * as Styled from "./styles";
import LoocalAutocompleteInput from "../../components/LoocalAutocompleteInput";
import SelectButton from "../../components/SelectButton";
import Input from "../../components/Input";

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([] as Order[]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ordersCountdown, setOrdersCountdown] = useState(120);
  const [currentOrder, setCurrentOrder] = useStateCallback<null | Order>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentSize, setCurrentSize] = useState<number | null>(null);
  const [count1015Min, setCount1015Min] = useState<string | "">("0");
  const [count1520Min, setCount1520Min] = useState<string | "">("0");
  const [count20PlusMin, setCount20PlusMin] = useState<string | "">("0");
  const [debounceTimer, setDebounceTimer] = useState<null | NodeJS.Timeout>(
    null,
  );
  const [idOrder, setIdOrder] = useState<any>();
  const [query, setQuery] = useState("");
  const [isPaused, setIsPaused] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const getOrdersInterval = useRef<NodeJS.Timeout | null>(null);
  const ordersCountdownInterval = useRef<NodeJS.Timeout | null>(null);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const { role } = useAuth();
  const [buttonSelected, setButtonSelected] = useState<string | null>(null);

  interface FormData {
    city_name: string;
  }

  const handleClearFilters = () => {
    setButtonSelected(null);
    setIdOrder(null);
    formRef.current?.reset();
  };

  const countOrders = useCallback(async () => {
    try {
      const response = await api.get("/backoffice-orders-count");
      setCount1015Min(response.data.count10_15min);
      setCount1520Min(response.data.count15_20min);
      setCount20PlusMin(response.data.count20plusmin);
    } catch (error) {
      addToast("Erro ao buscar lista!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, [addToast]);

  const handleGetOrders = useCallback(
    async (
      size = 8,
      page = 1,
      cityOrder = null,
      deliveryman = null,
      startTime = null,
      endTime = null,
      order = null,
      company = null,
    ) => {
      try {
        setIsLoading(true);
        let url = "/v2/orders";

        if (
          role ===
          "amVzdXNjcmlzdG/DqW9zZW5ob3JkZXRvZGF0ZXJyYXBhcmF0b2Rvc2VtcHJlYW3DqW0=" ||
          role ===
          "IlRlbmRlIGVtIHbDs3MgbyBtZXNtbyBzZW50aW1lbnRvIHF1ZSBob3V2ZSB0YW1iw6ltIGVtIENyaXN0byBKZXN1cy4i"
        ) {
          url = "/orders";
        }

        const { data: ordersResponse } = await api.get<any>(url, {
          params: {
            "page[size]": size,
            "page[number]": page,
            city_id: cityOrder,
            deliveryman_id: deliveryman,
            "timeRanges[0][start_time]": startTime,
            "timeRanges[0][end_time]": endTime,
            order_id: order,
            company_id: company,
          },
        });

        ordersResponse.data.forEach((orderColor: Order) => {
          switch (orderColor.status.name) {
            case "Entregue":
              orderColor.color = "#00A759";
              break;
            case "Preparando":
              orderColor.color = "gray";
              break;
            case "Cancelado":
              orderColor.color = "var(--color-danger)";
              break;
            case "Aguardando":
              orderColor.color = "#e5e619";
              break;
            case "A caminho":
              orderColor.color = "#4c4cff";
              break;
            case "A caminho da retirada":
              orderColor.color = "#fd7e14";
              break;
            default:
              break;
          }
        });

        setCurrentPage(page);
        setTotalPages(ordersResponse.meta.last_page);
        setOrders(ordersResponse.data);
        setIsLoading(false);
      } catch (error) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast, role],
  );

  const getSearchParams = useCallback(() => {
    const formData: FormData | any = formRef.current?.getData();
    let cityOrder = null;
    let deliveryman = null;
    let company = null;
    let startTime = null;
    let endTime = null;

    if (formData !== undefined) {
      cityOrder = formData.city_name.split("-")[0].trim();
      deliveryman = formData.deliveryman_name.split("-")[0].trim();
      company = formData.company_name.split("-")[0].trim();
    }

    if (buttonSelected) {
      // eslint-disable-next-line prefer-destructuring
      startTime = buttonSelected.split("-")[0];
      // eslint-disable-next-line prefer-destructuring
      endTime = buttonSelected.split("-")[1];
    }

    return {
      cityOrder,
      deliveryman,
      startTime,
      endTime,
      idOrder,
      company,
    };
  }, [buttonSelected, idOrder]);

  const debounceSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      e.persist();
      const newDebounceTimer = setTimeout(() => {
        const { cityOrder, deliveryman, startTime, endTime, company } =
          getSearchParams();

        countOrders();

        handleGetOrders(
          currentSize || 8,
          currentPage,
          cityOrder,
          deliveryman,
          startTime,
          endTime,
          idOrder,
          company,
        );
        setQuery(e.target.value);
      }, 500);
      setDebounceTimer(newDebounceTimer);
    },
    [
      debounceTimer,
      handleGetOrders,
      currentSize,
      idOrder,
      countOrders,
      getSearchParams,
      currentPage,
    ],
  );

  const handleSetIsPaused = useCallback(() => {
    setIsPaused((state) => !state);

    if (isPaused) {
      const { cityOrder, deliveryman, startTime, endTime, company } =
        getSearchParams();

      countOrders();

      handleGetOrders(
        currentSize || 8,
        currentPage,
        cityOrder,
        deliveryman,
        startTime,
        endTime,
        idOrder,
        company,
      );

      getOrdersInterval.current = setInterval(() => {
        countOrders();

        handleGetOrders(
          currentSize || 8,
          currentPage,
          cityOrder,
          deliveryman,
          startTime,
          endTime,
          idOrder,
          company,
        );
        addToast("Listagem atualizada!", {
          appearance: "info",
          autoDismiss: true,
        });
      }, 120 * 1000);

      ordersCountdownInterval.current = setInterval(() => {
        setOrdersCountdown((state) => (state !== 0 ? state - 1 : 120));
      }, 1000);
    } else {
      setOrdersCountdown(120);
      clearInterval(getOrdersInterval.current as NodeJS.Timeout);
      clearInterval(ordersCountdownInterval.current as NodeJS.Timeout);
    }
  }, [
    isPaused,
    addToast,
    handleGetOrders,
    countOrders,
    currentPage,
    currentSize,
    getSearchParams,
    idOrder,
  ]);

  const handleOpenModal = useCallback(
    (orderId: number) => {
      const selectedOrder = orders?.find((order) => order.id === orderId);
      setCurrentOrder(selectedOrder as Order, () => setIsModalOpen(true));
    },
    [orders, setCurrentOrder],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setCurrentOrder(null);
  }, [setIsModalOpen, setCurrentOrder]);

  const handleUpdateOrderStatus = useCallback(
    async (newStatus: number, orderId: number) => {
      try {
        setShowSpinner(true);
        await api.put(`orders/${orderId}/status`, {
          order_status_id: newStatus,
        });
        const { cityOrder, deliveryman, startTime, endTime, company } =
          getSearchParams();

        countOrders();

        handleGetOrders(
          currentSize || 8,
          currentPage,
          cityOrder,
          deliveryman,
          startTime,
          endTime,
          idOrder,
          company,
        );
        addToast("Status alterado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (e) {
        addToast("Ocorreu um erro ao atualizar status do pedido!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    },
    [
      currentPage,
      handleGetOrders,
      addToast,
      currentSize,
      countOrders,
      getSearchParams,
      idOrder,
    ],
  );

  const handleCancelOrder = useCallback(
    async (orderId: number) => {
      const { isConfirmed } = await Swal.fire({
        title: "Tem certeza?",
        text: `Tem certeza que deseja cancelar o pedido ${orderId}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (isConfirmed) {
        handleUpdateOrderStatus(CANCELED_STATUS_ID, orderId);
      }
    },
    [handleUpdateOrderStatus],
  );

  const handleSelect = useCallback(
    (selected: string) => {
      if (buttonSelected === selected) {
        setButtonSelected(null);
      } else {
        setButtonSelected(selected);
      }
    },
    [buttonSelected],
  );

  const handleRefreshPage = useCallback(() => {
    const { cityOrder, deliveryman, startTime, endTime, company } = getSearchParams();

    countOrders();

    handleGetOrders(
      currentSize || 8,
      currentPage,
      cityOrder,
      deliveryman,
      startTime,
      endTime,
      idOrder,
      company,
    );
  }, [countOrders, currentSize, currentPage, getSearchParams, handleGetOrders, idOrder]);

  const addNewOrder = useCallback(
    async (data) => {
      try {
        const newOrders = [];
        setShowSpinner(true);
        const dataToAddNewOrder = {
          customer: {
            name: data.customer.name,
            phones: data.customer.phones[0],
            address: {
              address: data.customer.address.address,
              number: data.customer.address.number,
              complement: data.customer.address.complement,
              neighborhood: data.customer.address.neighborhood,
              latitude: data.customer.address.latitude,
              longitude: data.customer.address.longitude,
              city: data.customer.address.city.name,
              city_id: data.customer.address.city.id,
              uf: data.customer.address.state_id,
              postal_code: data.customer.address.postal_code
            },
          },
          observation: data.observations[0],
          return: data.return,
          packet_type_id: data.packet_type_id,
          payment_type_id: data.payment_type_id,
          amount: data.amount,
          change: data.change,
          thermal_box: data.thermal_box,
          order_status_id: 1,
          get_sign: data.get_sign
        };
        newOrders.push(dataToAddNewOrder);
        await api.post("deliveries", {
          company_id: data.company_id,
          orders: newOrders
        });
        handleRefreshPage()
        addToast("Pedido relançado com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
        setShowSpinner(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Error updating order status:", e);
        addToast("Ocorreu um erro ao atualizar status do pedido!", {
          appearance: "warning",
          autoDismiss: true,
        });
        setShowSpinner(false);
      }
    },
    [addToast, handleRefreshPage]
  );

  const handleRelauchOrder = useCallback(
    async ({ order }) => {
      const { isConfirmed } = await Swal.fire({
        title: "Tem certeza?",
        text: `Tem certeza que deseja relançar o pedido ${order.id}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (isConfirmed) {
        addNewOrder(order);
      }
    },
    [addNewOrder],
  );

  const handleConfirmUpdateStatus = useCallback(
    async ({ type, order, statusId }) => {
      let textMessage = '';

      if (type === "cancel") {
        textMessage = `Tem certeza que deseja cancelar o pedido ${order.id}?`;
      } else if (type === "waiting") {
        if (order.order_status_id === ON_THE_WAY_WITHDRAWAL || order.order_status_id === ON_THE_WAY || order.order_status_id === ARRIVED_AT_COLLECTION) {
          textMessage = `Tem certeza que deseja realocar o pedido ${order.id}?`;
        } else {
          return;
        }
      } else if (type === "delivered") {
        if (order.order_status_id === CANCELED_STATUS_ID || order.order_status_id === WAITING_STATUS_ID) {
          return;
        }
        textMessage = `Tem certeza que deseja marcar o pedido ${order.id} com o status entregue?`;
      }

      const { isConfirmed } = await Swal.fire({
        title: "Tem certeza?",
        text: textMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (isConfirmed) {
        handleUpdateOrderStatus(statusId, order.id);
      }
    },
    [handleUpdateOrderStatus],
  );

  useEffect(() => {
    const { cityOrder, deliveryman, startTime, endTime, company } =
      getSearchParams();

    countOrders();

    handleGetOrders(
      currentSize || 8,
      currentPage,
      cityOrder,
      deliveryman,
      startTime,
      endTime,
      idOrder,
      company,
    );

    getOrdersInterval.current = setInterval(() => {
      handleGetOrders();
      addToast("Listagem atualizada!", {
        appearance: "info",
        autoDismiss: true,
      });
    }, 120 * 1000);

    ordersCountdownInterval.current = setInterval(() => {
      setOrdersCountdown((state) => (state !== 0 ? state - 1 : 120));
    }, 1000);

    return () => {
      if (getOrdersInterval.current) {
        clearInterval(getOrdersInterval.current);
      }
      if (ordersCountdownInterval.current) {
        clearInterval(ordersCountdownInterval.current);
      }
    };
  }, [
    handleGetOrders,
    addToast,
    countOrders,
    currentPage,
    currentSize,
    getSearchParams,
    idOrder,
  ]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        {isModalOpen && (
          <DetailsModal
            currentOrder={currentOrder as Order}
            setCurrentOrder={setCurrentOrder}
            handleCloseModal={handleCloseModal}
            isModalOpen={isModalOpen}
          />
        )}
        <Styled.OrdersContainer>
          <Styled.CardTitle
            isAdmin={
              role === process.env.REACT_APP_ADMIN_ROLE ||
              role === process.env.REACT_APP_OPERATOR_ROLE
            }
          >
            <div>
              Listagem dos Meus Pedidos{" "}
              <FiRefreshCw size={20} onClick={() => handleRefreshPage()} />
              {isPaused ? (
                <FiPlay size={20} onClick={handleSetIsPaused} />
              ) : (
                <FiPause size={20} onClick={handleSetIsPaused} />
              )}
            </div>
            <Styled.CircularProgress
              value={ordersCountdown}
              isPaused={isPaused}
              strokeWidth={12}
              minValue={0}
              maxValue={125}
              isModalOpen={isModalOpen}
            />
            <div>
              {(role === process.env.REACT_APP_ADMIN_ROLE ||
                role === process.env.REACT_APP_OPERATOR_ROLE) && (
                  <Styled.SearchForm ref={formRef} onSubmit={() => { }}>
                    <Styled.GridForm>
                      <Styled.ContainerDiv>
                        <LoocalAutocompleteInput
                          type="normal"
                          id="city_name"
                          name="city_name"
                          placeholder="Cidade"
                          formRef={formRef}
                          endpoint="city"
                          // onSelect={(city: any) => setSelectedCity(city)}
                          icon={FiCompass}
                        />
                      </Styled.ContainerDiv>

                      <Styled.ContainerDiv>
                        <LoocalAutocompleteInput
                          type="normal"
                          id="deliveryman_name"
                          name="deliveryman_name"
                          placeholder="Entregador"
                          formRef={formRef}
                          endpoint="deliveryman"
                          icon={FiTruck}
                        />
                      </Styled.ContainerDiv>
                      <Styled.ContainerDiv>
                        <Input
                          type="text"
                          id="order_id"
                          name="order_id"
                          placeholder="ID do Pedido"
                          onChange={(order: any) =>
                            setIdOrder(order.target.value)
                          }
                          icon={FiSmartphone}
                          maxLength={15}
                        />
                      </Styled.ContainerDiv>

                      <Styled.ContainerDiv>
                        <LoocalAutocompleteInput
                          type="normal"
                          id="company_name"
                          name="company_name"
                          placeholder="Comércio"
                          formRef={formRef}
                          endpoint="company"
                          icon={FiBriefcase}
                        />
                      </Styled.ContainerDiv>
                    </Styled.GridForm>
                    <Styled.GridFormButtons>
                      <Styled.ContainerDiv>
                        <SelectButton
                          onSelect={() => handleSelect("10-15")}
                          backgroundColor={
                            buttonSelected === "10-15" ? "#00a759" : "#aeaeae6e"
                          }
                          hoverColor="#00a759"
                          label={count1015Min}
                          labelHeader="10 min - 15 min"
                          isSelected={buttonSelected === "10-15"}
                        />
                      </Styled.ContainerDiv>

                      <Styled.ContainerDiv>
                        <SelectButton
                          onSelect={() => handleSelect("15-20")}
                          backgroundColor={
                            buttonSelected === "15-20" ? "#fd7e14" : "#aeaeae6e"
                          }
                          hoverColor="#fd7e14"
                          label={count1520Min}
                          labelHeader="15 min - 20 min"
                          isSelected={buttonSelected === "15-20"}
                        />
                      </Styled.ContainerDiv>

                      <Styled.ContainerDiv>
                        <SelectButton
                          onSelect={() => handleSelect("20-20")}
                          backgroundColor={
                            buttonSelected === "20-20" ? "#c31111" : "#aeaeae6e"
                          }
                          hoverColor="#c53030"
                          label={count20PlusMin}
                          labelHeader="+20 min"
                          isSelected={buttonSelected === "20-20"}
                        />
                      </Styled.ContainerDiv>
                    </Styled.GridFormButtons>

                    <Styled.ContainerDivButtonActions>
                      <Styled.SearchButtonForm
                        type="button"
                        onClick={handleClearFilters}
                      >
                        Limpar Filtros <FiX />
                      </Styled.SearchButtonForm>

                      <Styled.SearchButtonForm
                        type="button"
                        onClick={debounceSearch}
                      >
                        Buscar
                        <FiSearch />
                      </Styled.SearchButtonForm>
                    </Styled.ContainerDivButtonActions>

                    <Styled.ContainerDivButton />
                  </Styled.SearchForm>
                )}
            </div>
          </Styled.CardTitle>
          {isLoading ? (
            <Styled.OrdersShimmer
              isAdmin={
                role === process.env.REACT_APP_ADMIN_ROLE ||
                role === process.env.REACT_APP_OPERATOR_ROLE ||
                role === process.env.REACT_APP_MAIN_OFFICE_ROLE
              }
            >
              {[...Array(currentSize || 8)].map((_, index) => (
                <Skeleton key={index} />
              ))}
            </Styled.OrdersShimmer>
          ) : (
            <Styled.Ul>
              <ReactTooltip />
              {orders.map((order) => {
                return (
                  <div key={order.id} style={{ position: 'relative' }}>
                    {(role === process.env.REACT_APP_ADMIN_ROLE ||
                      role === process.env.REACT_APP_OPERATOR_ROLE) && (
                        order.status.name === "Cancelado" && (
                          <div style={{ position: 'absolute', top: '5%', right: '5%' }}>
                            <Styled.ActionField>
                              <FiRotateCcw
                                size={27}
                                onClick={() => handleRelauchOrder({
                                  order
                                })
                                }
                              />
                            </Styled.ActionField>
                          </div>
                        )
                      )}
                    <Styled.Li
                      color={order.color || "lightblue"}
                      style={{ marginBottom: '25px' }}
                      key={order.id}
                      isAdmin={
                        role === process.env.REACT_APP_ADMIN_ROLE ||
                        role === process.env.REACT_APP_OPERATOR_ROLE ||
                        role === process.env.REACT_APP_MAIN_OFFICE_ROLE
                      }
                    >
                      {(role === process.env.REACT_APP_ADMIN_ROLE ||
                        role === process.env.REACT_APP_OPERATOR_ROLE ||
                        role === process.env.REACT_APP_MAIN_OFFICE_ROLE) && (
                          <Styled.CommonField
                            data-tip={`${order.company.fantasy_name}`}
                          >
                            <span>Estabelecimento:</span>
                            <strong>{order.company.fantasy_name}</strong>
                          </Styled.CommonField>
                        )}
                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>
                          {order.id} ({order.deliveries[0].id})
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Data de criação:</span>
                        <strong>
                          {formatLocale(order.created_at, "dd/MM/yyyy HH:mm")}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Data do Delivery:</span>
                        <strong>
                          {formatLocale(
                            order.deliveries[0].created_at,
                            "dd/MM/yyyy HH:mm",
                          ) || "Não informado"}
                        </strong>
                      </Styled.CommonField>
                      <Styled.CommonField
                        data-tip={
                          role === process.env.REACT_APP_ADMIN_ROLE ||
                            role === process.env.REACT_APP_OPERATOR_ROLE ||
                            role === process.env.REACT_APP_MAIN_OFFICE_ROLE
                            ? order.company.address.city.name
                            : null
                        }
                      >
                        {role === process.env.REACT_APP_ADMIN_ROLE ||
                          role === process.env.REACT_APP_OPERATOR_ROLE ? (
                          <>
                            <span>Cidade:</span>
                            <strong>{order.company.address.city.name}</strong>
                          </>
                        ) : (
                          <>
                            <span>Valor:</span>
                            <strong>{formatCurrencyByText(order.amount)}</strong>
                          </>
                        )}
                      </Styled.CommonField>
                      <Styled.CommonField data-tip={`${order.customer.name}`}>
                        <span>Cliente:</span>
                        <strong>{order.customer.name.split(" ")[0]}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField
                        data-tip={
                          order.deliveries[0]?.deliveryman
                            ? `${order.deliveries[0]?.deliveryman.name} (${order.deliveries[0]?.deliveryman.nickname})`
                            : null
                        }
                      >
                        <span>Entregador:</span>
                        <strong>
                          {order.deliveries[0]?.deliveryman
                            ? order.deliveries[0]?.deliveryman.name.split(" ")[0]
                            : "N/A"}
                        </strong>
                      </Styled.CommonField>

                      <Styled.CommonField>
                        <span>
                          Telefone{" "}
                          <span role="img" aria-label="Moto">
                            🛵
                          </span>
                          :
                        </span>
                        <strong>
                          {phoneFormat(
                            order.deliveries[0].deliveryman?.phones[0] as string,
                          )}
                        </strong>
                      </Styled.CommonField>

                      {(role === process.env.REACT_APP_ADMIN_ROLE ||
                        role === process.env.REACT_APP_OPERATOR_ROLE ||
                        role === process.env.REACT_APP_MAIN_OFFICE_ROLE) && (
                          <>
                            <Styled.CommonField>
                              <span>Origem:</span>
                              <strong>{order.company.address.neighborhood}</strong>
                            </Styled.CommonField>
                            <Styled.CommonField>
                              <span>Destino:</span>
                              <strong>{order.customer.address.neighborhood}</strong>
                            </Styled.CommonField>
                          </>
                        )}

                      {role === process.env.REACT_APP_ADMIN_ROLE ||
                        role === process.env.REACT_APP_OPERATOR_ROLE ? (
                        <div>
                          <Styled.OrderStatus
                            aria-label="status"
                            color={order.color || "lightblue"}
                          >
                            <section aria-label="status-buttons">
                              <button
                                type="button"
                                onClick={() =>
                                  handleConfirmUpdateStatus({
                                    type: "cancel",
                                    order,
                                    statusId: CANCELED_STATUS_ID,
                                  })
                                }
                              >
                                Marcar como cancelado
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  handleConfirmUpdateStatus({
                                    type: "waiting",
                                    order,
                                    statusId: WAITING_STATUS_ID,
                                  })
                                }
                              >
                                Realocar entregador
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  handleConfirmUpdateStatus({
                                    type: "delivered",
                                    order,
                                    statusId: DELIVERED_STATUS_ID,
                                  })
                                }
                              >
                                Marcar como Entregue
                              </button>
                            </section>
                          </Styled.OrderStatus>
                        </div>
                      ) : (
                        <div>
                          <Styled.StatusField color={order.color || "lightblue"}>
                            {order.status.name}
                            {(order.status.name === "Aguardando" ||
                              order.status.name === "A caminho da retirada") && (
                                <FiX
                                  size={15}
                                  onClick={() => handleCancelOrder(order.id)}
                                />
                              )}
                          </Styled.StatusField>
                        </div>
                      )}
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Styled.StatusField
                          color={order.color || "lightblue"}
                        >
                          {order.status.name}
                        </Styled.StatusField>
                        <Styled.ActionField>
                          <FiZoomIn
                            size={27}
                            onClick={() => handleOpenModal(order.id)}
                          />
                        </Styled.ActionField>
                      </div>
                    </Styled.Li>
                  </div>
                );
              })}
            </Styled.Ul>
          )}
          {currentPage !== 0 && (
            <Pagination
              updateList={handleGetOrders}
              totalPages={totalPages}
              currentPage={currentPage}
              searchQuery={query}
              sizes={[8, 10, 20, 30]}
              currentSize={currentSize}
              setCurrentSize={setCurrentSize}
            />
          )}
        </Styled.OrdersContainer>
      </ContainerMain>
    </Container>
  );
};

export default Orders;
