/* eslint-disable */
import React, {
  InputHTMLAttributes,
  useCallback,
  useState,
  useRef,
} from "react";
import { FiCopy } from "react-icons/fi";

import * as Styled from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClickCopy: (ref: React.MutableRefObject<HTMLInputElement | null>) => void;
}

const Input: React.FC<InputProps> = ({
  onBlur,
  placeholder,

  onClickCopy,
  ...rest
}) => {
  const copiaEColaRef = useRef<null | HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);

      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  const handleSetIsFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <Styled.Container isFocused={isFocused}>
      <Styled.Input
        onFocus={handleSetIsFocused}
        onBlur={handleInputBlur}
        placeholder=""
        ref={copiaEColaRef}
        readOnly
        {...rest}
      />

      <FiCopy size={20} onClick={() => onClickCopy(copiaEColaRef)} />
    </Styled.Container>
  );
};

export default Input;
