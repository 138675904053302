/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ActionMeta, OptionTypeBase } from "react-select";
import { Props as AsyncProps } from "react-select/async";
import { IconBaseProps } from "react-icons/lib";
import { FormHandles, useField } from "@unform/core";
import { FiAlertCircle } from "react-icons/fi";

import * as Styled from "./styles";

interface AsyncReactSelectProps extends AsyncProps<OptionTypeBase, boolean> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  formRef: React.RefObject<FormHandles>;
  childrenStateUpdater?: number;
  hasAnimation?: boolean;
  onBlur?: any;
  onChange?: any;
}

const AsyncReactSelect: React.FC<AsyncReactSelectProps> = ({
  name,
  icon: Icon,
  hasAnimation,
  childrenStateUpdater,
  onChange,
  onBlur,
  isSearchable,
  placeholder,
  ...rest
}) => {
  const [hasValue, setHasValue] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const selectRef = useRef<any>(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    if (childrenStateUpdater) {
      setHasValue(false);
    }
  }, [childrenStateUpdater]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value,
          );
        }
        if (!ref.select.state.value) {
          return "";
        }
        return ref.select.state.value.value;
      },
      setValue: (ref: any, value: any) => {
        if (rest.isMulti && Array.isArray(value)) {
          const items = ref?.props?.options?.filter((option: OptionTypeBase) =>
            value.includes(option.value),
          );
          ref?.select.setValue(items);
        } else {
          const item = ref?.props?.options?.filter((option: OptionTypeBase) => {
            return option.value === value;
          });
          if (item && item.length > 0) {
            ref?.select?.setValue(item[0]);
          }
        }
      },
      clearValue: (ref: any) => {
        ref?.select?.select?.clearValue();
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleOnChange = useCallback(
    (option: OptionTypeBase, action: ActionMeta<OptionTypeBase>): void => {
      setHasValue(!!option);

      if (action.action === "set-value" || action.action === "clear") {
        return;
      }

      if (onChange) {
        onChange(option);
      }
    },
    [onChange],
  );

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);

    if (isSearchable) {
      setIsViewing(false);
    }

    if (onBlur) {
      onBlur(selectRef.current?.select.state.value);
    }
  }, [onBlur, isSearchable]);

  const handleOnFocus = useCallback(() => {
    clearError();
    setIsFocused(true);

    if (isSearchable) {
      setIsViewing(true);
    }
  }, [clearError, isSearchable]);

  return (
    <Styled.Container
      isErrored={!!error}
      isFocused={isFocused}
      hasAnimation={!!hasAnimation}
    >
      {Icon && <Icon size={20} />}
      <Styled.ReactSelect
        ref={selectRef}
        classNamePrefix="react-select"
        placeholder=""
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        onBlur={handleOnBlur}
        noOptionsMessage={() => "Sem resultados!"}
        {...rest}
      />
      <Styled.Label htmlFor={rest.id} hasValue={hasValue || isViewing}>
        {placeholder}
      </Styled.Label>
      {error && (
        <Styled.Error title={error}>
          <FiAlertCircle size={20} />
        </Styled.Error>
      )}
    </Styled.Container>
  );
};

export default AsyncReactSelect;
