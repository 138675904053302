import { lighten } from "polished";
import styled from "styled-components";
import { Link as ReactLink } from "react-router-dom";
import { Form as Unform } from "@unform/web";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const CompanyContainer = styled.div`
  padding: 20px 30px;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
`;

export const Link = styled(ReactLink)`
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
  color: var(--color-text-secondary);
  text-decoration: none;
  &:hover {
    > svg {
      stroke: ${lighten(0.25, "#466aa9")};
    }
  }

  > svg {
    stroke: #373737;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 15px;
  }
`;

export const Form = styled(Unform)`
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    padding: 15px 50px;

    > button {
      max-width: 300px;
    }
  }
`;

export const Strong = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 20px;
  color: #373737;
`;

export const Row = styled.div`
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 20px;
  > div {
    margin-right: 10px;
  }
`;
