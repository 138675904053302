import React from "react";

import { AuthProvider } from "./AuthContext";
import { SidebarProvider } from "./SidebarContext";

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <SidebarProvider>{children}</SidebarProvider>
  </AuthProvider>
);

export default AppProvider;
