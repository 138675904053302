import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import {
  FiBookmark,
  FiColumns,
  FiCompass,
  FiFeather,
  FiGlobe,
  FiHash,
  FiMap,
  FiMapPin,
  FiNavigation,
  FiPhone,
  FiUser,
} from "react-icons/fi";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";

import api from "../../services/api";

import { useAuth } from "../../hooks/AuthContext";
import { useSidebar } from "../../hooks/SidebarContext";

import {
  cpfOrCnpjFormat,
  onChangePhoneMask,
  phoneFormat,
} from "../../utils/inputAndTextMasks";
import getValidationErrors from "../../utils/getValidationErrors";

import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import ReadonlyInput from "../../components/ReadonlyInput";
import Button from "../../components/Button";
import Container from "../../components/Container";
import LoadingSpinner from "../../components/LoadingSpinner";
import ContainerMain from "../../components/ContainerMain";

import * as Styled from "./styles";

interface EstablishmentFormData {
  phone: string;
  fantasy_name: string;
}

const Establishment: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { company_id } = useAuth();
  const { addToast } = useToasts();
  const { isSidebarMaximized } = useSidebar();

  useEffect(() => {
    (async () => {
      try {
        const { data: companyResponseData } = await api.get(
          `companies/${company_id}`,
        );
        formRef.current?.setData({
          fantasy_name: companyResponseData.fantasy_name,
          social_name: companyResponseData.social_name,
          doc: cpfOrCnpjFormat(companyResponseData.doc),
          phone: phoneFormat(companyResponseData.phones[0]) || "N/A",
          address: companyResponseData.address.address,
          number: companyResponseData.address.number,
          complement: companyResponseData.address.complement || "N/A",
          neighborhood: companyResponseData.address.neighborhood,
          postal_code: companyResponseData.address.postal_code,
          uf: companyResponseData.address.uf,
          city: companyResponseData.address.city,
        });
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    })();
  }, [company_id, addToast]);

  const handleSubmit = useCallback(
    async (data: EstablishmentFormData) => {
      data.phone = data.phone && data.phone.replace(/\D/g, "");
      setIsLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          phone: Yup.string().min(10, "Telefone inválido"),
          fantasy_name: Yup.string().min(3, "Nome inválido"),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put(`companies/${company_id}`, {
          phones: [data.phone],
          fantasy_name: data.fantasy_name,
        });
        addToast("Dados atualizados com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          addToast(
            "Ocorreu um erro ao atualizar dados, entre em contato com o suporte!",
            {
              appearance: "warning",
              autoDismiss: true,
            },
          );
        }
      }
      setIsLoading(false);
    },
    [addToast, company_id],
  );

  return (
    <Container>
      <Sidebar />
      {isLoading && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.MyDataContainer>
          <Styled.Title>Meus Dados - Estabelecimento</Styled.Title>
          <Styled.MyDataForm
            onSubmit={handleSubmit}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Dados do estabelecimento</Styled.Strong>
            <Styled.Row>
              <Input
                type="text"
                id="fantasy_name"
                name="fantasy_name"
                placeholder="Nome Fantasia"
                icon={FiUser}
              />
              <Input
                type="text"
                id="phone"
                name="phone"
                onChange={onChangePhoneMask}
                placeholder="Telefone"
                icon={FiPhone}
                maxLength={14}
              />
            </Styled.Row>
            <Styled.Row>
              <ReadonlyInput
                type="text"
                id="social_name"
                name="social_name"
                placeholder="Razão Social"
                icon={FiFeather}
              />
              <ReadonlyInput
                type="text"
                id="doc"
                name="doc"
                placeholder="CPF/CNPJ"
                icon={FiColumns}
              />
            </Styled.Row>
            <Styled.Strong>Endereço do estabelecimento</Styled.Strong>
            <Styled.Row>
              <ReadonlyInput
                type="text"
                id="address"
                name="address"
                placeholder="Logradouro"
                icon={FiCompass}
              />
            </Styled.Row>
            <Styled.Row>
              <ReadonlyInput
                type="text"
                id="number"
                name="number"
                placeholder="Número"
                icon={FiHash}
              />
              <ReadonlyInput
                type="text"
                id="complement"
                name="complement"
                placeholder="Complemento"
                icon={FiMap}
              />
              <ReadonlyInput
                type="text"
                id="neighborhood"
                name="neighborhood"
                placeholder="Bairro"
                icon={FiGlobe}
              />
            </Styled.Row>
            <Styled.Row>
              <ReadonlyInput
                type="text"
                id="postal_code"
                name="postal_code"
                placeholder="CEP"
                icon={FiMapPin}
              />
              <ReadonlyInput
                type="text"
                id="city"
                name="city"
                placeholder="Cidade"
                icon={FiNavigation}
              />
              <ReadonlyInput
                type="text"
                id="uf"
                name="uf"
                placeholder="Estado"
                icon={FiBookmark}
              />
            </Styled.Row>
            <div>
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.MyDataForm>
        </Styled.MyDataContainer>
      </ContainerMain>
    </Container>
  );
};

export default Establishment;
