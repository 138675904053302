import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import {
  FiArrowLeft,
  FiAtSign,
  FiBarChart2,
  FiCoffee,
  FiCompass,
  FiFeather,
  FiLock,
  FiMap,
  FiPhone,
  FiSettings,
  FiUnlock,
  FiUser,
} from "react-icons/fi";
import { BsCardHeading } from "react-icons/bs";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import ReadonlyInput from "../../components/ReadonlyInput";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import Select from "../../components/ReactSelect";
import InvisibleInput from "../../components/InvisibleInput";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import AutocompleteInput from "../../components/AutocompleteInput";

import getValidationErrorsWithAutocomplete from "../../utils/getValidationErrorsWithAutocomplete";

import {
  cpfOrCnpjFormat,
  onChangePhoneMask,
} from "../../utils/inputAndTextMasks";
import { useAuth } from "../../hooks/AuthContext";

import * as Styled from "./styles";

interface NewEstablishmentFormData {
  social_name: string;
  fantasy_name: string;
  status: string;
  segment: string;
  phones: string;
  doc: string;
  state_register: string;
  city_register: string;
  postal_code_1: string;
  city_1: string;
  uf_1: string;
  latitude_1: string;
  longitude_1: string;
  address_1: string;
  number_1: string;
  complement: string;
  neighborhood_1: string;
  company_role: string;
  name: string;
  email: string;
  responsible_phone: string;
  password: string;
  password_confirmation: string;
}

const EstablishmentsNew: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [segments, setSegments] = useState([{ label: "", value: 0 }]);

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);
  const [companyStatus, setCompanyStatus] = useState([{ label: "", value: 0 }]);
  const { role } = useAuth();

  const submitCompanyUpdateForm = useCallback(
    async (data: NewEstablishmentFormData) => {
      try {
        data.phones = data.phones.replace(/\D/g, "");
        data.responsible_phone = data.responsible_phone.replace(/\D/g, "");
        data.doc = data.doc.replace(/\D/g, "");
        data.postal_code_1 = data.postal_code_1.replace(/\D/g, "");

        const dataToSubmitCompany = {
          social_name: data.social_name,
          fantasy_name: data.fantasy_name,
          segment_id: data.segment,
          doc: data.doc,
          phones: [data.phones],
          state_register: data.state_register,
          city_register: data.city_register,
          address: {
            address: data.address_1,
            number: data.number_1,
            complement: data.complement || null,
            neighborhood: data.neighborhood_1,
            postal_code: data.postal_code_1,
            uf: data.uf_1,
            city: data.city_1,
            latitude: data.latitude_1,
            longitude: data.longitude_1,
          },
          responsible: {
            user: {
              name: data.name,
              email: data.email,
              password: data.password,
            },
            phones: [data.responsible_phone],
            company_role: data.company_role,
          },
        };

        await api.post("companies", dataToSubmitCompany);

        formRef.current?.reset();

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Alterações realizadas com sucesso!",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Falha ao atualizar, erro: ${error.message}`,
        });
      }
      setShowSpinner(false);
      setIsLoading(false);
    },
    [],
  );

  const verifyFormSubmit = useCallback(
    async (data: NewEstablishmentFormData) => {
      setShowSpinner(true);
      setIsLoading(true);
      try {
        const schema = Yup.object().shape({
          social_name: Yup.string().min(3, "Razão social inválida"),
          segment: Yup.string().required("Campo obrigatório"),
          status: Yup.string().required("Campo obrigatório"),
          autocomplete_1: Yup.string().required("Campo obrigatório"),
          complement: Yup.string().when("number_1", {
            is: "s/n",
            then: Yup.string().required(
              "Complemento necessário para endereços sem número",
            ),
          }),
          email: Yup.string()
            .email("Email inválido")
            .required("Campo obrigatório"),
          name: Yup.string().required("Campo obrigatório"),
          company_role: Yup.string().required("Campo obrigatório"),
          fantasy_name: Yup.string().min(3, "Nome fantasia inválido"),
          phones: Yup.string().min(14, "Telefone inválido"),
          responsible_phone: Yup.string().min(14, "Telefone inválido"),
          doc: Yup.string().min(14, "Documento inválido"),
          latitude_1: Yup.string().required("Endereço inválido"),
          longitude_1: Yup.string().required("Endereço inválido"),
          password: Yup.string().min(4, "No mínimo 4 digitos"),
          password_confirmation: Yup.string()
            .min(4, "No mínimo 4 digitos")
            .oneOf([Yup.ref("password")], "Confirmação inválida"),
        });
        await schema.validate(data, { abortEarly: false });
        submitCompanyUpdateForm(data);
      } catch (err) {
        setShowSpinner(false);
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrorsWithAutocomplete(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [submitCompanyUpdateForm],
  );

  const onBlurMaskDoc = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.value = cpfOrCnpjFormat(e.target.value);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        Promise.all([api.get("/segments"), api.get("/companystatus")]).then(
          ([dataSegments, dataCompanyStatus]) => {
            setSegments(
              dataSegments.data.map(
                (segment: { name: string; id: number }) => ({
                  label: segment.name,
                  value: segment.id,
                }),
              ),
            );
            setCompanyStatus(
              dataCompanyStatus.data.map(
                (status: { name: string; id: number }) => ({
                  label: status.name,
                  value: status.id,
                }),
              ),
            );
          },
        );
      } catch (e) {
        addToast("Falha ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    })();
  }, [addToast]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.CompanyContainer>
          <Styled.Link to="/gerenciar/estabelecimentos">
            <FiArrowLeft size={20} />
            Voltar
          </Styled.Link>
          <Styled.Form
            onSubmit={verifyFormSubmit}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Dados do estabelecimento</Styled.Strong>
            <Styled.Row>
              <Input
                type="text"
                id="social_name"
                name="social_name"
                placeholder="Razão social"
                icon={FiFeather}
              />
              <Input
                type="text"
                id="fantasy_name"
                name="fantasy_name"
                placeholder="Nome fantasia"
                icon={FiUser}
              />
              {role === process.env.REACT_APP_ADMIN_ROLE ||
              role === process.env.REACT_APP_OPERATOR_ROLE ? (
                <Select
                  id="status"
                  name="status"
                  placeholder="Status"
                  formRef={formRef}
                  options={companyStatus}
                  isSearchable={false}
                  icon={FiBarChart2}
                />
              ) : (
                <ReadonlyInput
                  type="text"
                  id="status"
                  name="status"
                  placeholder="Status"
                  value="Inativo"
                  icon={FiBarChart2}
                />
              )}
            </Styled.Row>
            <Styled.Row>
              <Select
                id="segment"
                name="segment"
                placeholder="Segmento"
                formRef={formRef}
                options={segments}
                icon={FiCoffee}
                isSearchable={false}
              />
              <Input
                type="text"
                id="phones"
                name="phones"
                placeholder="Telefone"
                onChange={onChangePhoneMask}
                icon={FiPhone}
                maxLength={15}
              />
              <Input
                type="text"
                id="doc"
                name="doc"
                placeholder="CPF/CNPJ"
                icon={BsCardHeading}
                onBlur={onBlurMaskDoc}
                maxLength={14}
              />
            </Styled.Row>
            <Styled.Row>
              <AutocompleteInput
                type="text"
                id="autocomplete_1"
                name="autocomplete_1"
                index={1}
                placeholder="Endereço"
                icon={FiCompass}
                formRef={formRef}
              />
              <Input
                type="text"
                id="complement"
                name="complement"
                placeholder="Complemento"
                icon={FiMap}
              />
              <InvisibleInput type="text" id="address_1" name="address_1" />
              <InvisibleInput type="text" id="latitude_1" name="latitude_1" />
              <InvisibleInput type="text" id="longitude_1" name="longitude_1" />
              <InvisibleInput
                type="text"
                id="postal_code_1"
                name="postal_code_1"
              />
              <InvisibleInput type="text" id="number_1" name="number_1" />
              <InvisibleInput
                type="text"
                id="neighborhood_1"
                name="neighborhood_1"
              />
              <InvisibleInput type="text" id="city_1" name="city_1" />
              <InvisibleInput type="text" id="uf_1" name="uf_1" />
            </Styled.Row>
            <Styled.Row>
              <Input
                type="text"
                id="state_register"
                name="state_register"
                placeholder="Inscrição Estadual"
                icon={BsCardHeading}
                maxLength={9}
              />
              <Input
                type="text"
                id="city_register"
                name="city_register"
                placeholder="Inscrição Municipal"
                icon={BsCardHeading}
                maxLength={11}
              />
            </Styled.Row>

            <Styled.Strong>Dados do responsável</Styled.Strong>
            <Styled.Row>
              <ReadonlyInput
                type="text"
                id="company_role"
                name="company_role"
                placeholder="Cargo"
                value="companyAdmin"
                icon={FiSettings}
              />
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                icon={FiUser}
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                icon={FiAtSign}
              />

              <Input
                type="text"
                id="responsible_phone"
                name="responsible_phone"
                placeholder="Telefone"
                onChange={onChangePhoneMask}
                icon={FiPhone}
                maxLength={15}
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                icon={FiUnlock}
                showPasswordOption
              />

              <Input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                placeholder="Confirme a senha"
                icon={FiLock}
                showPasswordOption
              />
            </Styled.Row>

            <div>
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.Form>
        </Styled.CompanyContainer>
      </ContainerMain>
    </Container>
  );
};

export default EstablishmentsNew;
