import { lighten } from "polished";
import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface InputContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;
  border: 1px solid rgba(220, 220, 220);
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: white;
  padding: 2px 0px;
  position: relative;
  min-width: 250px;

  > label {
    color: #999;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    padding: 0 3px;
    left: 47px;
    transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -moz-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  > input:not(:placeholder-shown) {
    & ~ label {
      position: absolute;
      top: -10px;
      left: 20px;
      background: white;
      font-size: 14px;
      color: #8e8e8e;
    }
  }

  > input:focus {
    & ~ label {
      position: absolute;
      top: -10px;
      left: 20px;
      background: white;
      font-size: 14px;
      color: #8e8e8e;
    }
  }

  ${({ isErrored }) =>
    isErrored &&
    css`
      > svg:first-child {
        stroke: #c53030;
      }
      border-color: #c53030;
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      > svg:first-child {
        stroke: var(--color-primary) !important;
        color: var(--color-primary) !important;
      }
      border-color: var(--color-primary);
    `}



  &:hover {
    > svg:first-child {
      stroke: var(--color-primary) !important;
      color: var(--color-primary) !important;
    }
    border-color: var(--color-primary);
  }

  > svg {
    margin: 0 16px;
    stroke: #8e8e8e;
    transition: opacity 0.3s;
    color: #8e8e8e;
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  flex: 1;
  border: 0;
  background: transparent;
  outline: none;
  color: #373737;
  font-size: 16px;
  padding: 14px 0;

  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;

  &::placeholder {
    color: #8e8e8e;
  }
  &:-webkit-autofill {
    & ~ label {
      color: #8e8e8e;
    }
  }
`;

export const Text = styled.div`
  margin-left: auto;
  margin-right: 16px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s;
  color: #373737;

  &:hover {
    text-decoration: underline;
    color: ${lighten(0.1, "#373737")};
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  right: 0;
  margin: 0 16px;
  > svg {
    stroke: #c53030;
  }
`;
