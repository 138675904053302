import React, { useCallback, useState, useRef, useEffect } from "react";
import { FormHandles } from "@unform/core";
import { FiLock, FiLogIn, FiMail } from "react-icons/fi";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import Input from "../../components/Input";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";

import getValidationErrors from "../../utils/getValidationErrors";

import { useAuth } from "../../hooks/AuthContext";

import logoImg from "../../assets/img/logo-movio.webp";

import * as Styled from "./styles";

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToasts();
  const { search } = useLocation();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      setIsLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required("E-mail obrigatório")
            .email("Digite um e-mail válido"),
          password: Yup.string().min(4, "No mínimo 4 dígitos"),
        });
        await schema.validate(data, { abortEarly: false });
        await signIn({
          email: data.email,
          password: data.password,
          type: "responsible",
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else if (err.response?.status === 401) {
          // remover primeiro if quando backend implementar definitivo.
          if (err.response?.error === "inactive") {
            addToast("Estabelecimento inativo, entre em contato conosco!", {
              appearance: "warning",
              autoDismiss: true,
            });
          } else {
            formRef.current?.setErrors({
              email: "Verifique suas credenciais",
              password: "Verifique suas credenciais",
            });
            addToast(
              "Ocorreu um erro ao fazer login, verifique as credenciais!",
              {
                appearance: "warning",
                autoDismiss: true,
              },
            );
          }
        } else {
          addToast("Ocorreu um erro ao fazer login, verifique sua conexão!", {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      }
      setIsLoading(false);
    },
    [signIn, addToast],
  );

  useEffect(() => {
    const query = new URLSearchParams(search);

    if (query.get("password") === "success") {
      addToast("Senha alterada com sucesso!", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  }, [addToast, search]);

  return (
    <Styled.Container>
      {isLoading && <LoadingSpinner />}
      <Styled.Content>
        <Styled.AnimationContainer>
          <img src={logoImg} alt="Loocal Logotipo" />
          <Styled.Form onSubmit={handleSubmit} ref={formRef}>
            <h1>Insira suas credenciais</h1>
            <Styled.InputAndButtonContainer>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder="E-mail"
                autoComplete="username"
                icon={FiMail}
              />
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                autoComplete="current-password"
                showPasswordOption
                icon={FiLock}
              />
              <span>
                Ao acessar nosso sistema você concorda com nossos{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://loocal.online/estabelecimentos/termos"
                >
                  termos de uso
                </a>
                .
              </span>
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Entrar"}
                disabled={isLoading}
              />
              <Link to="recuperar">
                {" "}
                <FiLogIn size={17} /> Esqueci minha senha
              </Link>
              <Link to="fale-conosco">
                {" "}
                <FiMail size={17} /> Fale conosco
              </Link>
            </Styled.InputAndButtonContainer>
          </Styled.Form>
        </Styled.AnimationContainer>
      </Styled.Content>
      <Styled.Background />
    </Styled.Container>
  );
};

export default SignIn;
