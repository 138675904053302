import React, { createContext, useCallback, useState, useContext } from "react";
import * as Sentry from "@sentry/react";

import api from "../services/api";
import supportService from "../services/supportService";

interface SignInCredentials {
  email: string;
  password: string;
  type: string;
}

type IfoodStatus = "disabled" | "enabled" | "finished";

interface AuthContextProps {
  role: string;
  user_name: string;
  ifoodStatus: string | null;
  user_id: string | null;
  company_id: string | null;
  fantasy_name?: string;
  companyLocation: {
    companyLat: string;
    companyLng: string;
    companyCityId: number;
  } | null;
  responsible_id: string | null;
  changeIfoodStatus(newStatus: string): void;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

interface CompanyLocation {
  companyLat: string;
  companyLng: string;
  companyCityId: number;
}

interface DataState {
  token: string;
  role: string;
  user_name: string;
  ifoodStatus: IfoodStatus | null;
  user_id: string | null;
  company_id: string | null;
  companyLocation: CompanyLocation | null;
  responsible_id: string | null;
  fantasy_name?: string;
}

interface SignInResponse {
  token: string;
  integration: {
    ifood: IfoodStatus;
  };
  user: {
    name: string;
    roles: string[];
    email: string;
    id: number;
  };
  responsible?: {
    id: number;
    company: {
      id: number;
      fantasy_name: string;
    };
  };
}

interface InactiveError extends Error {
  response: { status: number; error: string };
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<DataState>(() => {
    const token = localStorage.getItem("@Loocal-client:token");
    const role = localStorage.getItem("@Loocal-client:role");
    const user_name = localStorage.getItem("@Loocal-client:user_name");
    const user_email = localStorage.getItem("@Loocal-client:user_email");
    const user_id = localStorage.getItem("@Loocal-client:user_id");
    const company_id = localStorage.getItem("@Loocal-client:company_id");
    const fantasy_name =
      localStorage.getItem("@Loocal-client:fantasy_name") || undefined;
    const ifoodStatus = localStorage.getItem(
      "@Loocal-client:ifood",
    ) as IfoodStatus | null;
    const responsible_id = localStorage.getItem(
      "@Loocal-client:responsible_id",
    );
    let companyLocation: string | CompanyLocation | null = localStorage.getItem(
      "@Loocal-client:companyLocation",
    );

    if (companyLocation !== null) {
      companyLocation = JSON.parse(companyLocation) as CompanyLocation;
    }

    if (token && user_name && role && user_email) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      Sentry.setUser({
        id: responsible_id || "n/a",
        username: user_name,
        company_id: company_id || "n/a",
      });

      return {
        token,
        role,
        ifoodStatus,
        user_name,
        user_id,
        company_id,
        companyLocation,
        responsible_id,
        fantasy_name,
      };
    }

    localStorage.clear();

    return {} as DataState;
  });

  const signIn = useCallback(async ({ email, password, type }) => {
    const signInEndpoint =
      type === "backoffice" ? "/login" : "/responsibles/login";

    const { data: loginData } = await api.post<SignInResponse>(signInEndpoint, {
      email,
      password,
    });

    api.defaults.headers.authorization = `Bearer ${loginData.token}`;

    const user_name = loginData.user.name;
    const user_id = loginData.user.id.toString();

    const adminSecret = process.env.REACT_APP_ADMIN_ROLE || "L00c@l123!%#@";
    const operatorSecret =
      process.env.REACT_APP_OPERATOR_ROLE || "L00c@l256!%!@";

    const financialSecret =
      process.env.REACT_APP_FINANCIAL_ROLE || "L00c@l458!%!@";

    const mainOfficeSecret =
      process.env.REACT_APP_MAIN_OFFICE_ROLE || "L00c@l789!%!@";

    let role;

    switch (loginData.user.roles[0]) {
      case "superAdmin":
        role = adminSecret;
        break;
      case "loocalOperator":
        role = operatorSecret;
        break;
      case "loocalFinancial":
        role = financialSecret;
        break;
      case "loocalAdmin":
        role = mainOfficeSecret;
        break;
      default:
        [role] = loginData.user.roles;
    }

    let companyLocation = null;
    let company_id = null;
    let responsible_id = null;
    let fantasy_name;

    if (loginData.responsible) {
      responsible_id = loginData.responsible?.id.toString();
      company_id = loginData.responsible?.company.id.toString();

      const { data: companyData } = await api.get(`/companies/${company_id}`);

      // remover if quando backend implementar definitivo.
      if (companyData.status.name !== "Ativo") {
        const inactiveError = new Error(
          "Estabelecimento inativo.",
        ) as InactiveError;
        inactiveError.response = { status: 401, error: "inactive" };
        throw inactiveError;
      }

      const { address: companyAddress } = companyData;
      companyLocation = {
        companyLat: companyAddress.latitude,
        companyLng: companyAddress.longitude,
        companyCityId: companyAddress.city_id,
      };
      fantasy_name = companyData.fantasy_name;

      localStorage.setItem(
        "@Loocal-client:companyLocation",
        JSON.stringify(companyLocation),
      );
      localStorage.setItem("@Loocal-client:company_id", company_id);
      localStorage.setItem("@Loocal-client:responsible_id", responsible_id);
      localStorage.setItem(
        "@Loocal-client:fantasy_name",
        fantasy_name || "n/a",
      );
    }

    const ifoodStatus = loginData.integration?.ifood;

    localStorage.setItem("@Loocal-client:ifood", ifoodStatus);
    localStorage.setItem("@Loocal-client:user_name", user_name);
    localStorage.setItem("@Loocal-client:user_email", loginData.user.email);
    localStorage.setItem("@Loocal-client:user_id", user_id);
    localStorage.setItem("@Loocal-client:role", role);
    localStorage.setItem("@Loocal-client:token", loginData.token);
    localStorage.setItem(
      "@Loocal-client:expiration",
      JSON.stringify(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    );

    Sentry.setUser({
      id: responsible_id || "n/a",
      username: user_name,
      company_id: company_id || "n/a",
    });

    setData({
      token: loginData.token,
      role,
      user_name,
      ifoodStatus,
      user_id,
      company_id,
      companyLocation,
      fantasy_name,
      responsible_id,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();
    Sentry.setUser({});
    if (data.role !== process.env.REACT_APP_ADMIN_ROLE) {
      supportService();
    }

    setData({} as DataState);
  }, [data.role]);

  const changeIfoodStatus = useCallback((newStatus: IfoodStatus) => {
    setData((state) => ({ ...state, ifoodStatus: newStatus }));
    localStorage.setItem("@Loocal-client:ifood", newStatus);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        role: data.role,
        company_id: data.company_id,
        user_name: data.user_name,
        fantasy_name: data.fantasy_name,
        user_id: data.user_id,
        companyLocation: data.companyLocation,
        responsible_id: data.responsible_id,
        ifoodStatus: data.ifoodStatus,
        changeIfoodStatus,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { useAuth, AuthProvider };
