/* eslint-disable */
import { darken, lighten } from "polished";
import styled from "styled-components";
import { Form as Unform } from "@unform/web";
import ReactSwitch from "react-switch";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

interface CardValueProps {
  isActive: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main<SidebarStatus>`
  max-width: 100vw;

  @media (min-width: 1024px) {
    width: 100%;
    max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
    height: 100%;
    margin: 30px;
  }
`;

export const Content = styled.div`
  padding: 20px 30px;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
`;

export const TopBar = styled.div`
  padding-bottom: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
  > svg {
    color: #373737;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const CurrentCity = styled.strong`
  font-weight: 500;
  font-size: 18px;
  color: #373737;
`;

export const Strong = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 20px;
  color: #373737;
`;

export const SearchForm = styled(Unform)`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    margin: 0 0 20px 25px;
    > svg {
      margin-left: 15px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: ${lighten(0.25, "#466aa9")};
      }
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    > div:first-child {
      margin-right: 10px;
    }
  }
`;

export const ToolsContainer = styled.div<{ show?: boolean }>`
  display: ${(props) => (!!props.show && props.show ? "flex" : "none")};
  align-items: center;
  flex-wrap: wrap;
  animation: fadeIn 1.5s;
  margin: 20px 20px;
  

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const FlagCard = styled.div`
  margin: 10px;

  /*   &:not(:first-child) {
    margin: 15px 0 0 15px;
  } */

  padding: 30px;
  background: white;
  width: 300px;
  min-height: 180px;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
`;

export const CardTitle = styled.h4`
  color: var(--color-text-secondary);
  margin-bottom: 10px;
`;

export const CardStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardStrong = styled.strong`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
  color: #373737;
`;

export const CardValue = styled.strong<CardValueProps>`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
  color: ${({ isActive }) => (isActive ? "green" : "red")};
`;

export const FlagForm = styled(Unform)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;

  > svg {
    cursor: pointer;

    transition: 0.3s;
    color: #373737;
    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Switch = styled(ReactSwitch)``;

export const PushCard = styled.div`
  margin: 10px;
  width: 620px;
  padding: 30px;
  background: white;
  min-height: 180px;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
`;

export const PushForm = styled(Unform)`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  > div:first-child {
    margin-bottom: 10px;
    width: 250px;
  }

  > div:nth-child(2) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Separator = styled.div`
  margin: 0 30px;
  color: gray;
  opacity: 0.6;
`;

export const TopButton = styled.div`
  background: #6a64d9;
  padding: 5px 14px;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  font-size: 12px;

  &:hover {
    background: ${darken("0.25", "#6a64d9")};
  }
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0 15px;
  > li:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Li = styled.li`
  width: 100%;
  height: 260px;
  padding: 10px 15px;
  border-radius: 4px;
  border-top: 3px solid lightblue;
  box-shadow: 2px 0px 5px gray;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > div:nth-child(5) {
    flex: 100%;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
  }

  > div:nth-child(6) {
    flex: 70%;
    max-width: 80%;
    margin-top: 10px;
  }

  > div:last-child {
    flex: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  @media (min-width: 550px) {
    padding: 15px 60px;
  }

  @media (min-width: 800px) {
    flex-wrap: unset;
    height: 70px;
    padding: 0 10px;
    border-top: none;
    border-left: 3px solid lightblue;

    > div {
      flex: unset;
    }

    > div:first-child {
      min-width: 8%;
    }
    > div:nth-child(2) {
      min-width: 15%;
    }

    > div:nth-child(3) {
      min-width: 15%;
    }

    > div:nth-child(4) {
      max-width: 16%;
      min-width: 200px;
      margin-top: 0;
      flex: unset;
    }

    > div:nth-child(5) {
      min-width: 16%;
      border-bottom: none;
      flex: unset;
    }
  }

  @media (min-width: 1200px) {
    padding: 0 20px;
  }
`;

export const CommonField = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > strong {
    font-size: 14px;
    color: #515a6a;
  }
`;
export const StatusField = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 5px 11px;
  background-color: var(--color-primary);
  border-radius: 14px;
  color: white;

  > span {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
    font-size: 13px;
    margin-top: 2px;
  }
`;

export const ActionField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > svg:first-child {
    color: #373737;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: ${lighten(0.25, "#466aa9")};
    }
  }
`;

export const WarningText = styled.h3`
  text-align: center;
  margin: 55px 0 50px;
  font-size: 23px;
`;

export const ButtonSearch = styled.button`
  background-color: rgb(70, 106, 169);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease 0s;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  display: flex;
  align-items: center;
  gap: 3px;
`;
