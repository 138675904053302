import styled from "styled-components";
import { darken } from "polished";

interface Button {
  padding?: string;
  isSecondary: boolean;
}

export const StyledButton = styled.button<Button>`
  height: 2.5rem;
  width: 100%;
  background: ${({ isSecondary }) =>
    isSecondary ? "white" : "var(--color-primary)"};
  color: ${({ isSecondary }) => (isSecondary ? "#373737" : "white")};
  border: ${({ isSecondary }) =>
    isSecondary ? "1px solid #373737" : "1px solid #466aa9"};
  border-radius: 2px !important;
  padding: ${({ padding }) => padding};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem !important;
  cursor: pointer;
  outline: none !important;
  transition: background 0.5s;
  &:hover {
    background: ${({ isSecondary }) =>
      isSecondary ? darken(0.05, "#fff") : "#466aa9"};
  }
`;
