import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface InputContainer {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<InputContainer>`
  border-bottom: 1px solid rgba(220, 220, 220);
  display: flex;
  align-items: center;
  background: white;
  position: relative;
  width: 130px;

  > label {
    color: #999;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    padding: 0 0;
    left: 0;
    transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -moz-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  > input:not(:placeholder-shown) {
    & ~ label {
      position: absolute;
      top: -10px;
      left: 0;
      background: white;
      font-size: 10px;
      color: #8e8e8e;
    }
  }

  > input:focus {
    & ~ label {
      position: absolute;
      top: -10px;
      left: 0;
      background: white;
      font-size: 10px;
      color: #8e8e8e;
    }
  }

  ${({ isErrored }) =>
    isErrored &&
    css`
      border-color: #c53030;
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      border-color: var(--color-primary);
    `}



  &:hover {
    border-color: var(--color-primary);
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  flex: 1;
  border: 0;
  background: transparent;
  outline: none;
  color: #373737;
  font-size: 16px;
  padding: 5px 0;

  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;

  &::placeholder {
    color: #8e8e8e;
  }
  &:-webkit-autofill {
    & ~ label {
      color: #8e8e8e;
    }
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  right: 0;
  margin: 0 16px;
  > svg {
    stroke: #c53030;
  }
`;
