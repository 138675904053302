export default function supportService(): void {
  if (!window) {
    throw new Error("DOM is unavailable");
  }

  const userName = localStorage.getItem("@Loocal-client:user_name");
  const userEmail = localStorage.getItem("@Loocal-client:user_email");

  if (!userName || !userEmail) {
    document.querySelector("#supportService")?.remove();
    document
      .querySelectorAll(".octadesk-octachat-app")
      ?.forEach((e) => e.parentNode?.removeChild(e));

    return;
  }

  const script = document.createElement("script");
  script.innerHTML = `(function(o,c,t,a,d,e,s,k){o.octadesk=o.octadesk||{};o.octadesk.chatOptions={subDomain:a,showButton:d,openOnMessage:e,hide:s};var bd=c.getElementsByTagName("body")[0];var sc=c.createElement("script");sc.async=1;sc.src=t;bd.appendChild(sc);})(window,document,'https://cdn.octadesk.com/embed.js','loocal2','true','true','false');`;
  const firstScriptTag = document.getElementsByTagName("script")[0];
  if (!firstScriptTag || !firstScriptTag.parentNode) {
    throw new Error("DOM is unavailable");
  }
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
}
