import React from "react";

import Container from "../../components/Container";
import Sidebar from "../../components/Sidebar";
import logoImg from "../../assets/img/logo-movio.webp";

const Start: React.FC = () => {
  return (
    <Container>
      <Sidebar />
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={logoImg}
          alt="Loocal Logotipo"
          style={{ width: "230px", height: "69px" }}
        />
      </div>
    </Container>
  );
};

export default Start;
