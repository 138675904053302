/* eslint-disable */
import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { IconBaseProps } from "react-icons";

import * as Styled from "./styles";

interface ReadonlyInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const ReadonlyInput: React.FC<ReadonlyInputProps> = ({
  icon: Icon,
  placeholder,
  name,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);
  return (
    <Styled.Container>
      {Icon && <Icon size={20} />}
      <Styled.Input disabled ref={inputRef} {...rest} />
      <label htmlFor={rest.id}>{placeholder}</label>
    </Styled.Container>
  );
};

export default ReadonlyInput;
