import { darken, lighten } from "polished";
import ReactSwitch from "react-switch";
import styled, { css } from "styled-components";

interface OrderContainer {
  isSidebarMaximized: boolean;
  isAdmin: boolean;
  index: number;
  isDispatch: boolean;
}

interface TopContainer {
  isAdmin: boolean;
  index: number;
}

export const Order = styled.div<OrderContainer>`
  ${({ isAdmin, index, isDispatch }) =>
    isAdmin &&
    index === 0 &&
    css`
      > div:nth-child(3) {
        > div {
          margin-right: 0;
          width: 100% !important;
        }
      }

      > div:nth-child(${isDispatch ? 4 : 5}) {
        flex-wrap: wrap;
      }

      > div:nth-child(${isDispatch ? 5 : 7}) {
        display: flex;
        flex-direction: column;
        > div:first-child {
          width: 100%;
          margin-bottom: 15px;
        }
        > div:last-child {
          width: 100%;
        }
      }

      > div:nth-child(${isDispatch ? 6 : 8}) {
        display: flex;
        flex-direction: column;
        > div:first-child {
          width: 100%;
          margin-bottom: 15px;
        }
        > div:last-child {
          width: 100%;
        }
      }

      > div:nth-child(${isDispatch ? 8 : 10}) {
        display: flex;
        flex-direction: column;
        > div:first-child {
          width: 100%;
          margin-bottom: 15px;
        }
        > div:nth-child(2) {
          margin-bottom: 15px;
        }
        > div:last-child {
          width: 100%;
        }
      }

      > div:nth-child(${isDispatch ? 9 : 11}) {
        margin-bottom: 20px;
        flex-direction: column;
      }
    `}

  ${({ isAdmin, index, isDispatch }) => {
    if (!isAdmin) {
      if (index === 0 && !isDispatch) {
        return css`
          > div:nth-child(3) {
            flex-wrap: wrap;
          }
          > div:nth-child(5) {
            display: flex;
            flex-direction: column;
            > div:first-child {
              width: 100%;
              margin-bottom: 15px;
            }
            > div:last-child {
              width: 100%;
            }
          }

          > div:nth-child(6) {
            display: flex;
            flex-direction: column;
            > div:first-child {
              width: 100%;
              margin-bottom: 15px;
            }
            > div:last-child {
              width: 100%;
            }
          }

          > div:nth-child(8) {
            display: flex;
            flex-direction: column;
            > div:first-child {
              width: 100%;
              margin-bottom: 15px;
            }
            > div:nth-child(2) {
              margin-bottom: 15px;
            }
            > div:last-child {
              width: 100%;
            }
          }

          > div:nth-child(9) {
            margin-bottom: 20px;
            flex-direction: column;
          }
        `;
      }
      return css`
        > div:nth-child(3) {
          display: flex;
          flex-direction: column;
          > div:first-child {
            width: 100%;
            margin-bottom: 15px;
          }
          > div:last-child {
            width: 100%;
            margin-right: unset;
          }
        }

        > div:nth-child(4) {
          display: flex;
          flex-direction: column;
          > div:first-child {
            width: 100%;
            margin-bottom: 15px;
          }
          > div:last-child {
            width: 100%;
          }
        }

        > div:nth-child(6) {
          display: flex;
          flex-direction: column;
          > div:first-child {
            width: 100%;
            margin-bottom: 15px;
          }
          > div:nth-child(2) {
            width: 100%;
            margin-bottom: 15px;
          }
          > div:last-child {
            width: 100%;
            margin-right: unset;
          }
        }

        > div:nth-child(7) {
          margin-bottom: 20px;
          flex-direction: column;
        }
      `;
    }
    return null;
  }};

  ${({ isSidebarMaximized, isAdmin, index, isDispatch }) =>
    !isSidebarMaximized &&
    isAdmin &&
    index === 0 &&
    css`
      @media (min-width: 1105px) {
        > div:nth-child(3) {
          // vehicle btn
        }

        > div:nth-child(${isDispatch ? 5 : 7}) {
          flex-direction: row;
          > div:first-child {
            width: 30%;
            margin-bottom: unset;
          }
          > div:last-child {
            width: 70%;
            margin-right: unset;
          }
        }

        > div:nth-child(${isDispatch ? 6 : 8}) {
          flex-direction: row;
          > div:first-child {
            width: 70%;
            margin-bottom: unset;
          }
          > div:last-child {
            width: 30%;
            margin-right: unset;
          }
        }

        > div:nth-child(${isDispatch ? 8 : 10}) {
          flex-direction: row;
          > div:first-child {
            width: 100%;
            margin-bottom: unset;
          }
          > div:nth-child(2) {
            width: 100%;
            margin-bottom: unset;
          }
          > div:last-child {
            width: 100%;
            margin-right: unset;
          }
        }

        > div:nth-child(${isDispatch ? 9 : 11}) {
          margin-bottom: 20px;
        }
      }
    `}

  ${({ isSidebarMaximized, index, isAdmin, isDispatch }) => {
    if (
      (!isSidebarMaximized && index !== 0) ||
      (!isSidebarMaximized && !isAdmin)
    ) {
      return css`
        @media (min-width: 1105px) {
          > div:nth-child(${isDispatch || index !== 0 ? 3 : 0}) {
            flex-direction: row;
            > div:first-child {
              margin-bottom: unset;
            }
          }

          > div:nth-child(${isDispatch || index !== 0 ? 3 : 5}) {
            flex-direction: row;
            > div:first-child {
              width: 30%;
              margin-bottom: unset;
            }
            > div:last-child {
              width: 70%;
              margin-right: 0;
            }
          }

          > div:nth-child(${isDispatch || index !== 0 ? 4 : 6}) {
            flex-direction: row;
            > div:first-child {
              width: 70%;
              margin-bottom: unset;
            }
            > div:last-child {
              width: 30%;
              margin-right: 0;
            }
          }

          > div:nth-child(${isDispatch || index !== 0 ? 6 : 8}) {
            flex-direction: row;

            > div:first-child {
              width: 100%;
              margin-bottom: unset;
            }
            > div:nth-child(2) {
              width: 100%;
              margin-bottom: unset;
            }
            > div:last-child {
              margin-right: 0;
              width: 100%;
            }
          }

          > div:nth-child(${isDispatch || index !== 0 ? 7 : 9}) {
            margin-bottom: 20px;
          }
        }
      `;
    }
    return null;
  }}

  ${({ isSidebarMaximized, isAdmin, index, isDispatch }) =>
    isSidebarMaximized &&
    isAdmin &&
    index === 0 &&
    css`
      @media (min-width: 1305px) {
        > div:nth-child(${isDispatch ? 4 : 5}) {
          flex-wrap: wrap;
        }

        > div:nth-child(${isDispatch ? 5 : 7}) {
          flex-direction: row;
          > div:first-child {
            width: 30%;
            margin-bottom: unset;
          }
          > div:last-child {
            width: 70%;
            margin-right: unset;
          }
        }

        > div:nth-child(${isDispatch ? 6 : 8}) {
          flex-direction: row;
          > div:first-child {
            width: 70%;
            margin-bottom: unset;
          }
          > div:last-child {
            width: 30%;
            margin-right: unset;
          }
        }

        > div:nth-child(${isDispatch ? 8 : 10}) {
          flex-direction: row;
          > div:first-child {
            width: 100%;
            margin-bottom: unset;
          }
          > div:nth-child(2) {
            width: 100%;
            margin-bottom: unset;
          }
          > div:last-child {
            width: 100%;
            margin-right: unset;
          }
        }
      }
    `}

  ${({ isSidebarMaximized, index, isAdmin, isDispatch }) => {
    if (
      (isSidebarMaximized && index !== 0) ||
      (isSidebarMaximized && !isAdmin)
    ) {
      return css`
        @media (min-width: 1305px) {
          > div:nth-child(${isDispatch || index !== 0 ? 3 : 0}) {
            flex-direction: row;
            > div:first-child {
              margin-bottom: unset;
            }
          }

          > div:nth-child(${isDispatch || index !== 0 ? 3 : 5}) {
            flex-direction: row;
            > div:first-child {
              width: 30%;
              margin-bottom: unset;
            }
            > div:last-child {
              width: 70%;
              margin-right: 0;
            }
          }

          > div:nth-child(${isDispatch || index !== 0 ? 4 : 6}) {
            flex-direction: row;
            > div:first-child {
              width: 70%;
              margin-bottom: unset;
            }
            > div:last-child {
              width: 30%;
              margin-right: 0;
            }
          }

          > div:nth-child(${isDispatch || index !== 0 ? 6 : 8}) {
            flex-direction: row;

            > div:first-child {
              width: 100%;
              margin-bottom: unset;
            }
            > div:nth-child(2) {
              width: 100%;
              margin-bottom: unset;
            }
            > div:last-child {
              margin-right: 0;
              width: 100%;
            }
          }

          > div:nth-child(${isDispatch || index !== 0 ? 7 : 9}) {
            margin-bottom: 20px;
          }
        }
      `;
    }
    return null;
  }}
`;

export const Title = styled.h4`
  color: var(--color-text-secondary);

  display: flex;
  align-items: center;
`;

export const TopBar = styled.div<TopContainer>`
  display: flex;
  flex-direction: ${({ index }) => (index === 0 ? "column" : "row")};
  justify-content: ${({ index }) => (index === 0 ? "center" : "space-between")};
  align-items: ${({ index }) => (index === 0 ? "flex-start" : "center")};
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);

  > svg {
    margin-right: 2%;
    stroke: #c53030;
    cursor: pointer;

    transition: 0.3s;

    &:hover {
      stroke: ${lighten(0.25, "#c53030")};
    }
  }

  @media (min-width: 550px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

export const Strong = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 20px;
  color: #373737;

  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    > span {
      font-weight: bold;
    }
    padding-right: 5%;
    -webkit-animation: fadein 1s;
    -moz-animation: fadein 1s;
    -ms-animation: fadein 1s;
    -o-animation: fadein 1s;
    animation: fadein 1s;
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-moz-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-webkit-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-ms-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-o-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;

  margin-bottom: 15px;
  > div {
    margin-right: 10px;
  }

  @media (min-width: 700px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const RadioRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 3px solid rgba(37, 37, 37, 0.2);

  > div {
    max-width: 100px;
  }

  @media (min-width: 550px) {
    padding-left: 40px;
    padding-right: 40px;

    > div:first-child {
      margin-right: 20%;
    }

    > div {
      max-width: unset;
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  > svg {
    margin-left: 8px;
    color: darkgray;
    transition: 0.2s;

    &:hover {
      color: ${darken(0.5, "darkgray")};
    }
  }

  @media (min-width: 550px) {
    margin-top: unset;
    margin-right: 15px;
  }
`;

export const Switch = styled(ReactSwitch)`
  margin-right: 10px;
`;

export const RadioOptions = styled.div`
  > p {
    margin-bottom: 5px;
    color: #373737;
  }
`;
