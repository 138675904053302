import React from "react";
import {
  FiAtSign,
  FiBarChart2,
  FiCoffee,
  FiEdit,
  FiFeather,
  FiHash,
  FiMapPin,
  FiNavigation,
  FiPhone,
  FiTool,
  FiUser,
} from "react-icons/fi";
import { GiIdCard } from "react-icons/gi";
import { RiAdminLine } from "react-icons/ri";

import Modal from "../Modal";

import { cpfOrCnpjFormat, phoneFormat } from "../../utils/inputAndTextMasks";

import { CompanyFromGetCompanies } from "../../@types/customTypes";
import * as Styled from "./styles";

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  establishmentData: CompanyFromGetCompanies;
}

const EstablishmentDetailsModal: React.FC<IModalProps> = ({
  isOpen,
  establishmentData,
  setIsOpen,
}) => {
  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      customStyles={{ maxWidth: "650px", padding: "30px" }}
    >
      <Styled.Container>
        <Styled.DetailsContainer>
          <Styled.Strong>Dados do Estabelecimento</Styled.Strong>
          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiHash size={18} />
              <h3>ID</h3>
            </Styled.FieldTitle>
            <p>{establishmentData.id}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiBarChart2 size={18} />
              <h3>Status</h3>
            </Styled.FieldTitle>
            <p>{establishmentData.status.name}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiUser size={18} />
              <h3>Nome Fantasia</h3>
            </Styled.FieldTitle>
            <p>{establishmentData.fantasy_name}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiFeather size={18} />
              <h3>Razão Social</h3>
            </Styled.FieldTitle>
            <p>{establishmentData.social_name}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <GiIdCard size={18} />
              <h3>Documento</h3>
            </Styled.FieldTitle>
            <p>{cpfOrCnpjFormat(establishmentData.doc)}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiCoffee size={18} />
              <h3>Segmento</h3>
            </Styled.FieldTitle>
            <p>{establishmentData.segment.name}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiPhone size={18} />
              <h3>Telefone</h3>
            </Styled.FieldTitle>
            <p>{phoneFormat(establishmentData.phones[0].number)}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiNavigation size={18} />
              <h3>Cidade</h3>
            </Styled.FieldTitle>
            <p>
              {establishmentData.address.city.name}
              <strong> ({establishmentData.address.city.state.uf}) </strong>
            </p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiMapPin size={18} />
              <h3>Logradouro</h3>
            </Styled.FieldTitle>
            <p>
              {establishmentData.address.address}
              <strong> ({establishmentData.address.number})</strong>
            </p>
          </Styled.CommonField>
          <Styled.Strong>Dados do Responsável</Styled.Strong>
          <Styled.CommonField>
            <Styled.FieldTitle>
              <RiAdminLine size={18} />
              <h3>Nome</h3>
            </Styled.FieldTitle>
            <p>{establishmentData.responsibles[0].user.name}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiTool size={18} />
              <h3>Cargo</h3>
            </Styled.FieldTitle>
            <p>{establishmentData.responsibles[0].company_role}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiAtSign size={18} />
              <h3>E-mail</h3>
            </Styled.FieldTitle>
            <p>{establishmentData.responsibles[0].user.email}</p>
          </Styled.CommonField>

          <Styled.CommonField>
            <Styled.FieldTitle>
              <FiPhone size={18} />
              <h3>Telefone</h3>
            </Styled.FieldTitle>
            <p>
              {phoneFormat(establishmentData.responsibles[0].phones[0].number)}
            </p>
          </Styled.CommonField>
        </Styled.DetailsContainer>
        <Styled.ActionContainer>
          <Styled.Link to={`estabelecimentos/${establishmentData.id}`}>
            <FiEdit size={20} />
          </Styled.Link>
        </Styled.ActionContainer>
      </Styled.Container>
    </Modal>
  );
};

export default EstablishmentDetailsModal;
