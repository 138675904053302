import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border: 1px solid rgba(220, 220, 220);
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: white;
  padding: 2px 0px;
  position: relative;
  min-width: 250px;

  > label {
    color: #999;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    padding: 0 3px;
    left: 47px;
    transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -moz-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  > input:not(:placeholder-shown) {
    & ~ label {
      position: absolute;
      top: -10px;
      left: 20px;
      background: white;
      font-size: 14px;
      color: lightgray;
    }
  }

  > input:focus {
    & ~ label {
      position: absolute;
      top: -10px;
      left: 20px;
      background: white;
      font-size: 14px;
      color: lightgray;
    }
  }

  > svg {
    margin: 0 16px;
    color: lightgray;
    transition: opacity 0.3s;
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  flex: 1;
  border: 0;
  background: white;
  outline: none;
  color: lightgray;
  font-size: 16px;
  padding: 14px 0;
`;
