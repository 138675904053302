import React from "react";
import { FiPackage } from "react-icons/fi";

import Modal from "../Modal";

import * as Styled from "./styles";

interface OrdersQuantityProps {
  status_name: string;
  quantity: number;
  color: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  ordersQuantityData: OrdersQuantityProps[];
  filterPeriod: { startDate: string; endDate: string };
}

const OrdersQuantityModal: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  ordersQuantityData,
  filterPeriod,
}) => {
  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      customStyles={{ maxWidth: "550px", padding: "0" }}
    >
      <Styled.Container>
        <Styled.Strong>Quantidade de pedidos</Styled.Strong>
        <Styled.Subtitle>Quantidade de pedidos por status</Styled.Subtitle>
        <Styled.PeriodText>
          {filterPeriod.startDate} até {filterPeriod.endDate}
        </Styled.PeriodText>
        <Styled.OrdersQuantity>
          {ordersQuantityData.map((order) => (
            <Styled.CommonField key={order.status_name}>
              <Styled.FieldTitle color={order.color}>
                <FiPackage size={18} />
                <h3>{order.status_name}</h3>
              </Styled.FieldTitle>

              <p>{order.quantity}</p>
            </Styled.CommonField>
          ))}
        </Styled.OrdersQuantity>
      </Styled.Container>
    </Modal>
  );
};

export default OrdersQuantityModal;
