export function onChangeCurrencyMask(
  e: React.ChangeEvent<HTMLInputElement>,
): void {
  let currency: string | number = e.target.value.replace(/\D/g, "");
  currency = Number(currency);
  currency = `${(currency / 100).toFixed(2)}`;
  currency = currency.replace(".", ",");
  currency = currency.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  currency = currency.replace(/(\d)(\d{3}),/g, "$1.$2,");
  e.target.value = `R$ ${currency}`;
}

export function onChangeTimeMask(e: React.ChangeEvent<HTMLInputElement>): void {
  let time = e.target.value;
  time = time.replace(/\D/g, ""); // Remove tudo que não é dígito

  if (time.length <= 2) {
    time = time.replace(/(\d{0,2})/, "$1");
  } else if (time.length <= 4) {
    time = time.replace(/(\d{2})(\d{0,2})/, "$1:$2");
  } else if (time.length <= 6) {
    time = time.replace(/(\d{2})(\d{2})(\d{0,2})/, "$1:$2:$3");
  } else {
    time = time.replace(/(\d{2})(\d{2})(\d{2})(.*)/, "$1:$2:$3"); // Limita a seis dígitos
  }

  e.target.value = time;
}

export function onChangePhoneMask(
  e: React.ChangeEvent<HTMLInputElement>,
): void {
  let phone = e.target.value;
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
  e.target.value = phone;
}

export function onChangeCreditCardMask(
  e: React.ChangeEvent<HTMLInputElement>,
  isAmex = true,
): void {
  if (!isAmex) {
    let creditCard = e.target.value;
    creditCard = creditCard.replace(/\D/g, "");
    creditCard = creditCard.replace(/^(\d{4})(\d)/g, "$1 $2");
    creditCard = creditCard.replace(/^(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3");
    creditCard = creditCard.replace(
      /^(\d{4})\s(\d{4})\s(\d{4})(\d)/g,
      "$1 $2 $3 $4",
    );
    e.target.value = creditCard;
  } else {
    let creditCard = e.target.value;
    creditCard = creditCard.replace(/\D/g, "");
    creditCard = creditCard.replace(/^(\d{4})(\d)/g, "$1 $2");
    creditCard = creditCard.replace(/^(\d{4})\s(\d{6})(\d)/g, "$1 $2 $3");
    e.target.value = creditCard;
  }
}

export function onChangeAcceptOnlyNumbers(
  e: React.ChangeEvent<HTMLInputElement>,
): void {
  let inputValue = e.target.value;
  inputValue = inputValue.replace(/\D/g, "");
  e.target.value = inputValue;
}

export function cpfOrCnpjFormat(
  cpfOrCnpj: string | number | null | undefined,
): string {
  if (!cpfOrCnpj) return "";
  cpfOrCnpj = `${cpfOrCnpj}`.toString();

  if (cpfOrCnpj.length !== 11) {
    cpfOrCnpj = cpfOrCnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5",
    );
  } else {
    cpfOrCnpj = cpfOrCnpj.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4",
    );
  }
  return cpfOrCnpj;
}

export function rgFormat(rg: string | number): string {
  rg = rg.toString();
  rg = rg.replace(/\D/g, "");
  rg = rg.replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
  return rg;
}

export function cepFormat(cep: string | number | null): string {
  if (!cep) return "N/A";

  cep = cep.toString();
  cep = cep.replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, "$1$2-$3");
  return cep;
}

export function phoneFormat(phone: string | number | null): string {
  if (!phone) return "N/A";

  phone = phone.toString().replace(/\D/g, "");
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
  return phone;
}

export function formatCurrencyByText(currency: string | number | null): string {
  if (currency === null) return "";
  return Number(currency).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}
