import React, { createContext, useCallback, useState, useContext } from "react";

interface CollapseNavStatesProps {
  gerenciar: boolean;
  financeiro: boolean;
  meusDados: boolean;
  financeiroComercio: boolean;
}

interface SidebarContextProps {
  isSidebarMaximized: boolean;
  handleToggleSidebar(): void;
  setCollapseNavStates: React.Dispatch<
    React.SetStateAction<CollapseNavStatesProps>
  >;
  collapseNavStates: CollapseNavStatesProps;
}

const SidebarContext = createContext<SidebarContextProps>(
  {} as SidebarContextProps,
);

export const SidebarProvider: React.FC = ({ children }) => {
  const [isSidebarMaximized, setIsSidebarMaximized] = useState<boolean>(() => {
    let isSidebarMax: string | boolean | null = localStorage.getItem(
      "@Loocal-client:sidebar",
    );

    if (isSidebarMax !== null) {
      isSidebarMax = Boolean(JSON.parse(isSidebarMax));
      return isSidebarMax;
    }
    return true;
  });

  const [collapseNavStates, setCollapseNavStates] = React.useState({
    gerenciar: false,
    financeiro: false,
    meusDados: false,
    financeiroComercio: false,
  });

  const handleToggleSidebar = useCallback(() => {
    localStorage.setItem(
      "@Loocal-client:sidebar",
      (!isSidebarMaximized).toString(),
    );

    setIsSidebarMaximized((sidebarState) => {
      if (sidebarState) {
        setCollapseNavStates({
          gerenciar: false,
          financeiro: false,
          meusDados: false,
          financeiroComercio: false,
        });
      }

      return !sidebarState;
    });
  }, [isSidebarMaximized]);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarMaximized,
        handleToggleSidebar,
        collapseNavStates,
        setCollapseNavStates,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebar(): SidebarContextProps {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error("useSidebar must be used within a SidebarContext");
  }
  return context;
}
