import React, { useState, useCallback, useRef, useEffect } from "react";
import { FiMap, FiMapPin, FiSearch, FiCompass } from "react-icons/fi";
import { FormHandles } from "@unform/core";

import Modal from "../Modal";
import Select from "../ReactSelect";

import api from "../../services/api";

import * as Styled from "./styles";
import Input from "../Input";
import { onChangeAcceptOnlyNumbers } from "../../utils/inputAndTextMasks";

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  filterPeriod: { startDate: string; endDate: string };
  handleGetAverageDeliveryTime: (
    isUpdate?: boolean,
    cityId?: number,
    maxDistance?: number,
  ) => Promise<void>;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  averageDeliveryTime: string;
}

interface StateResponse {
  id: number;
  name: string;
  uf: string;
}

interface CitiesResponse {
  id: number;
  state_id: number;
  name: string;
}

interface Option {
  value: number;
  label: string;
}

const AverageDeliveryTimeModal: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  filterPeriod,
  handleGetAverageDeliveryTime,
  setShowSpinner,
  averageDeliveryTime,
}) => {
  const [states, setStates] = useState<Option[] | never[]>([]);
  const [cities, setCities] = useState<Option[] | never[]>([]);

  const averageTimeCityFormRef = useRef<FormHandles>(null);
  const alreadyLoaded = useRef(false);

  const getCities = useCallback(
    async (state_id: number) => {
      setShowSpinner(true);
      setCities([]);
      const { data: CitiesData } = await api.get(`/cities/${state_id}`);
      const MappedCities = CitiesData.map((city: CitiesResponse) => {
        return {
          label: city.name,
          value: city.id,
        };
      });
      setCities(MappedCities);
      setShowSpinner(false);
    },
    [setShowSpinner],
  );

  useEffect(() => {
    if (isOpen && !alreadyLoaded.current) {
      (async () => {
        setShowSpinner(true);
        const { data: StatesData } = await api.get("/states");
        const MappedStates = StatesData.map((state: StateResponse) => {
          return {
            label: state.name,
            value: state.id,
          };
        });
        alreadyLoaded.current = true;
        setStates(MappedStates);
        setShowSpinner(false);
      })();
    }
  }, [setShowSpinner, isOpen]);

  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      customStyles={{ maxWidth: "800px", padding: "0" }}
    >
      <Styled.Container>
        <Styled.Strong>Tempo médio de entrega</Styled.Strong>
        <Styled.Subtitle>Tempo médio de entrega por cidade</Styled.Subtitle>
        <Styled.PeriodText>
          {filterPeriod.startDate} até {filterPeriod.endDate}
        </Styled.PeriodText>

        <Styled.SearchForm
          ref={averageTimeCityFormRef}
          onSubmit={(data) =>
            handleGetAverageDeliveryTime(
              true,
              data.city_id,
              Number(data.max_distance),
            )
          }
          autoComplete="off"
        >
          <Styled.FormTitle>Selecione a localidade:</Styled.FormTitle>
          <Styled.InputsContainer>
            <Select
              isSearchable
              id="state_id"
              name="state_id"
              placeholder="Estado"
              formRef={averageTimeCityFormRef}
              options={states as Option[]}
              icon={FiMap}
              onChange={(option: any) => getCities(option?.value)}
            />
            {cities.length !== 0 && (
              <Select
                isSearchable
                id="city_id"
                name="city_id"
                icon={FiMapPin}
                options={cities as Option[]}
                placeholder="Cidade"
                formRef={averageTimeCityFormRef}
                hasAnimation
              />
            )}
          </Styled.InputsContainer>
          <Styled.FormTitle>Selecione a distância máxima:</Styled.FormTitle>
          <div>
            <Input
              type="text"
              id="max_distance"
              name="max_distance"
              placeholder="Distância máxima (M)"
              icon={FiCompass}
              onChange={onChangeAcceptOnlyNumbers}
            />
            <FiSearch
              size={25}
              onClick={() => averageTimeCityFormRef.current?.submitForm()}
            />
          </div>
        </Styled.SearchForm>

        <Styled.AverageDeliveryTimeContainer>
          O tempo médio é: <span>{averageDeliveryTime}</span>
        </Styled.AverageDeliveryTimeContainer>
      </Styled.Container>
    </Modal>
  );
};

export default AverageDeliveryTimeModal;
