import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiEdit, FiRefreshCw, FiZoomIn } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import { FormHandles } from "@unform/core";
import { debounce } from "lodash";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import BorderlessInput from "../../components/BorderlessInput";
import Sidebar from "../../components/Sidebar";
import Skeleton from "../../components/Skeleton";
import Pagination from "../../components/Pagination";
import EstablishmentDetailsModal from "../../components/EstablishmentDetailsModal";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";

import { cpfOrCnpjFormat } from "../../utils/inputAndTextMasks";

import { CompanyFromGetCompanies } from "../../@types/customTypes";
import * as Styled from "./styles";

interface GetCompaniesApiResponse {
  current_page: number;
  data: CompanyFromGetCompanies[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

const Establishments: React.FC = () => {
  const [establishments, setEstablishments] = useState<
    CompanyFromGetCompanies[] | null
  >(null);
  const [selectedEstablishment, setSelectedEstablishment] =
    useState<CompanyFromGetCompanies>({} as CompanyFromGetCompanies);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [query, setQuery] = useState("");

  const { isSidebarMaximized } = useSidebar();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleGetEstablishments = useCallback(
    async (size = 8, page = 1, searchQuery = "") => {
      try {
        setIsLoading(true);
        const { data: establishmentsResponse } =
          await api.get<GetCompaniesApiResponse>(
            `companies?page[size]=${size}&page[number]=${page}&search=id:${
              Number(searchQuery) || 0
            };social_name:${searchQuery};fantasy_name:${searchQuery};doc:${searchQuery};address.city.name:${searchQuery}`,
          );
        establishmentsResponse.data.forEach((establishment) => {
          switch (establishment.status.name) {
            case "Ativo":
              establishment.color = "var(--color-primary)";
              break;
            case "Aguardando aprovação":
              establishment.status.name = "Ag. aprovação";
              establishment.color = "#e5e619";
              break;
            case "Banido":
              establishment.color = "var(--color-danger)";
              break;
            case "Documentos pendentes":
              establishment.status.name = "Docs. pendentes";
              establishment.color = "#e5e619";
              break;
            case "Inativo":
              establishment.color = "gray";
              break;
            default:
              break;
          }
        });
        setCurrentPage(page);
        setTotalPages(establishmentsResponse.last_page);
        setEstablishments(establishmentsResponse.data);
        setIsLoading(false);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchEstablishment = React.useCallback(
    debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      handleGetEstablishments(8, 1, value);
      setQuery(value);
    }, 500),
    [handleGetEstablishments],
  );
  const handleSetIsModalOpen = useCallback(() => {
    setIsModalOpen((state) => !state);
  }, []);

  const handleViewEstablishmentDetails = useCallback(
    (establishment) => {
      setSelectedEstablishment(establishment);
      handleSetIsModalOpen();
    },
    [handleSetIsModalOpen],
  );

  useEffect(() => {
    handleGetEstablishments();
  }, [handleGetEstablishments]);

  return (
    <Container>
      <Sidebar />
      {isModalOpen && (
        <EstablishmentDetailsModal
          setIsOpen={handleSetIsModalOpen}
          isOpen={isModalOpen}
          establishmentData={selectedEstablishment}
        />
      )}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.EstablishmentsContainer>
          <Styled.Title>
            <div>
              Gerenciar - Estabelecimentos{" "}
              <FiRefreshCw
                size={20}
                onClick={() => handleGetEstablishments(8, 1)}
              />
            </div>
            <Styled.SearchForm ref={formRef} onSubmit={() => {}}>
              <Styled.TopLink to="/gerenciar/novo-estabelecimento">
                + Novo Estabelecimento
              </Styled.TopLink>
              <BorderlessInput
                type="text"
                id="search"
                name="search"
                onChange={searchEstablishment}
                placeholder="Pesquisar"
                autoComplete="off"
              />
            </Styled.SearchForm>
          </Styled.Title>
          <Styled.ListContainer>
            {isLoading ? (
              <Styled.EstablishmentsShimmer>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Styled.EstablishmentsShimmer>
            ) : (
              <Styled.Ul>
                {establishments?.map((establishment) => {
                  return (
                    <Styled.Li
                      color={establishment.color || "lightblue"}
                      key={establishment.id}
                    >
                      <Styled.CommonField>
                        <span>ID:</span>
                        <strong>{establishment.id}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Nome Fantasia:</span>
                        <strong>{establishment.fantasy_name}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Documento:</span>
                        <strong>{cpfOrCnpjFormat(establishment.doc)}</strong>
                      </Styled.CommonField>
                      <Styled.CommonField>
                        <span>Cidade:</span>
                        <strong>{establishment.address.city.name}</strong>
                      </Styled.CommonField>
                      <Styled.Separator />
                      <Styled.StatusField
                        color={establishment.color || "lightblue"}
                      >
                        {establishment.status.name}
                      </Styled.StatusField>
                      <Styled.ActionField>
                        <FiZoomIn
                          size={27}
                          onClick={() =>
                            handleViewEstablishmentDetails(establishment)
                          }
                        />
                        <Styled.Link
                          to={`/gerenciar/estabelecimentos/${establishment.id}`}
                        >
                          <FiEdit size={24} />
                        </Styled.Link>
                      </Styled.ActionField>
                    </Styled.Li>
                  );
                })}
              </Styled.Ul>
            )}

            {currentPage !== 0 && (
              <Pagination
                updateList={handleGetEstablishments}
                totalPages={totalPages}
                currentPage={currentPage}
                searchQuery={query}
              />
            )}
          </Styled.ListContainer>
        </Styled.EstablishmentsContainer>
      </ContainerMain>
    </Container>
  );
};

export default Establishments;
