import { darken } from "polished";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import DatePicker from "react-datepicker";

export const CsvTooltip = styled(ReactTooltip)`
  color: var(--color-text-dark) !important;
  background-color: white !important;
  opacity: 1 !important;
  border: 2px solid var(--color-primary) !important;

  > button {
    margin: 15px 0 5px 0;
  }

  &.place-top {
    &:after {
      border-top-color: var(--color-primary) !important;
    }
  }

  &.place-bottom {
    &:after {
      border-bottom-color: var(--color-primary) !important;
    }
  }
`;

export const CsvOptionsUl = styled.ul`
  list-style: none;
  display: flex;
  margin: 10px 0 20px 0;

  > li:first-child {
    margin-right: 8px;
  }
`;

export const CsvOption = styled.li`
  font-size: 13px;
  color: var(--color-text-in-button);
  background: var(--color-primary);
  padding: 2px 10px;
  border-radius: 25px;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: ${darken("0.1", "#fff")};
  }
`;

export const DateRangePicker = styled(DatePicker)`
  width: 100% !important;
  border: 1px solid rgba(220, 220, 220);
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: white;
  padding: 10px 15px;
  outline: none;
  color: #373737;

  &:hover {
    border-color: var(--color-primary);
  }

  &:focus {
    border-color: var(--color-primary);
  }
`;
