import { ValidationError } from "yup";

interface Errors {
  [key: string]: string;
}

export default function getNewOrdersValidationErrors(
  err: ValidationError,
): Errors {
  const validationErrors: Errors = {};

  err.inner.forEach((error) => {
    const orderIndex = error.path?.replace(/(\D)/g, "");
    if (
      !error.path?.includes("postal_code") &&
      !error.path?.includes("address") &&
      !error.path?.includes("number")
    ) {
      validationErrors[error.path as string] = error.message;
    } else if (error.path === `postal_code_${orderIndex}`) {
      validationErrors[`autocomplete_${orderIndex}`] = error.message;
    } else if (error.path === `address_${orderIndex}`) {
      validationErrors[`autocomplete_${orderIndex}`] = error.message;
    } else if (error.path === `number_${orderIndex}`) {
      validationErrors[`autocomplete_${orderIndex}`] = error.message;
    }
  });

  return validationErrors;
}
