/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const normalizeString = (str: string) => {
    return str.replace(/\s+/g, ' ').trim();
};

export const generateSearchVariations = (str: string) => {
    const normalizedStr = normalizeString(str);
    const words = normalizedStr.split(' ');

    const variations = [normalizedStr];
    for (let i = 0; i < words.length - 1; i++) {
        const variation = [
            ...words.slice(0, i + 1),
            '',
            ...words.slice(i + 1),
        ].join(' ');
        variations.push(variation);
    }

    return variations;
};

export const normalizePhoneNumber = (phone: string) => {
    let normalizedPhone = phone.replace(/^\+55\s*/, '');

    normalizedPhone = normalizedPhone.replace(/[^\d]/g, '');

    return normalizedPhone;
};

export const normalizeCPFNumber = (cpf: string) => {
    let normalizedCPF = cpf.replace(/[^\d]/g, '');

    if (normalizedCPF.length === 11) {
        normalizedCPF = normalizedCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    return normalizedCPF;
};