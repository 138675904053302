const options = [
  { value: "1", option: "Dinheiro" },
  { value: "2", option: "Cartão de crédito" },
  { value: "3", option: "Cartão de débito" },
  { value: "4", option: "Cartão refeição" },
  { value: "5", option: "Online" },
];

export default function getPaymentMethodById(id: number): string | undefined {
  const method = options.find((option) => option.value === id.toString());
  return method?.option;
}
