/* eslint-disable import/no-cycle */
import styled, { css } from "styled-components";
import { Form as Unform } from "@unform/web";

import { PaymentMethodTypes } from ".";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

interface SelectedPaymentMethod {
  selectedPaymentMethod: PaymentMethodTypes;
}

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const AddCreditsContainer = styled.div`
  padding: 20px 30px;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
`;

export const Title = styled.h4`
  color: var(--color-text-secondary);
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
`;

export const Strong = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-left: 16px;
  margin-bottom: 32px;
  color: #373737;
`;

export const PaymentMethodsContainer = styled.div<SelectedPaymentMethod>`
  display: flex;

  @media (min-width: 800px) {
    padding: 0 40px;
  }

  ${({ selectedPaymentMethod }) => {
    switch (selectedPaymentMethod) {
      case "CREDIT_CARD":
        return css`
          > div:first-child {
            background: var(--color-primary);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &::before {
              content: "";
              border-style: solid;
              border-color: var(--color-primary) transparent;
              border-width: 12px 12px 0 12px;
              bottom: 20px;
              top: 100%;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
            > svg {
              fill: white;
            }
            > span {
              color: white;
            }
          }
          > div:nth-child(2) {
            background: unset;
            border: 1px solid #466aa9;
            border-radius: 0;
            border-right: 0;
            transition: 0.3s;

            &:hover {
              background: rgba(0, 0, 0, 0.06);
            }
            > svg {
              fill: #373737;
            }
            > span {
              color: #373737;
            }
          }

          > div:last-child {
            background: unset;
            border: 1px solid #466aa9;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            transition: 0.3s;

            > svg {
              .st1 {
                fill: #373737;
              }
            }

            &:hover {
              background: rgba(0, 0, 0, 0.06);
            }
            > svg {
              fill: #373737;
            }
            > span {
              color: #373737;
            }
          }
        `;
      case "BANK_SLIP":
        return css`
          > div:first-child {
            background: unset;
            border: 1px solid #466aa9;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            transition: 0.3s;

            &:hover {
              background: rgba(0, 0, 0, 0.06);
            }
            > svg {
              fill: #373737;
            }

            > span {
              color: #373737;
            }
          }
          > div:nth-child(2) {
            background: var(--color-primary);
            border-radius: 0;
            &::before {
              content: "";
              border-style: solid;
              border-color: var(--color-primary) transparent;
              border-width: 12px 12px 0 12px;
              bottom: 20px;
              top: 100%;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
            > svg {
              fill: white;
            }
            > span {
              color: white;
            }
          }
          > div:last-child {
            background: unset;
            border: 1px solid #466aa9;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            transition: 0.3s;

            > svg {
              .st1 {
                fill: #373737;
              }
            }

            &:hover {
              background: rgba(0, 0, 0, 0.06);
            }
            > svg {
              fill: #373737;
            }
            > span {
              color: #373737;
            }
          }
        `;
      case "PIX":
        return css`
          > div:first-child {
            background: unset;
            border: 1px solid #466aa9;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            transition: 0.3s;

            &:hover {
              background: rgba(0, 0, 0, 0.06);
            }
            > svg {
              fill: #373737;
            }

            > span {
              color: #373737;
            }
          }
          > div:nth-child(2) {
            background: unset;
            border: 1px solid #466aa9;
            border-radius: 0;
            border-right: 0;
            border-left: 0;
            transition: 0.3s;

            &:hover {
              background: rgba(0, 0, 0, 0.06);
            }
            > svg {
              fill: #373737;
            }
            > span {
              color: #373737;
            }
          }
          > div:last-child {
            background: var(--color-primary);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &::before {
              content: "";
              border-style: solid;
              border-color: var(--color-primary) transparent;
              border-width: 12px 12px 0 12px;
              bottom: 20px;
              top: 100%;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }

            > svg {
              .st1 {
                fill: white;
              }
            }

            > span {
              color: white;
            }
          }
        `;
      default:
        return null;
    }
  }}
`;

export const PaymentMethodBox = styled.div`
  position: relative;
  height: 100px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  > span {
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
  }

  > svg {
    width: 35px;
    height: 35px;
  }

  @media (min-width: 800px) {
    height: 140px;

    > svg {
      width: 40px;
      height: 40px;
    }

    > span {
      font-size: 16px;
    }
  }
`;

export const SelectedPaymentMethodContainer = styled.div`
  margin-top: 30px;

  @media (min-width: 800px) {
    padding: 0 40px;
  }
`;

export const PaymentForm = styled(Unform)`
  max-width: 510px;
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  > p {
    color: #373737;
  }

  > div:nth-child(4) {
    > div {
      margin-right: 10px;
    }
  }

  > div:nth-child(5) {
    > div {
      margin-right: 10px;
    }
  }

  > div:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const SelectedPaymentMethodTitle = styled.h3<SelectedPaymentMethod>`
  font-size: 25px;
  color: #373737;
  margin-bottom: 25px;
  max-width: ${({ selectedPaymentMethod }) =>
    selectedPaymentMethod !== "PIX" ? " 360px" : "540px"};
  display: flex;
  justify-content: ${({ selectedPaymentMethod }) =>
    selectedPaymentMethod === "CREDIT_CARD" ? "flex-start" : "flex-end"};
`;

export const Row = styled.div`
  > div:first-child {
    margin-bottom: 15px;
  }

  @media (min-width: 800px) {
    display: flex;
    > div:first-child {
      margin-bottom: 0;
    }
  }
`;

export const BilletPaymentForm = styled(Unform)`
  max-width: 510px;
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  > p {
    color: #373737;
  }
`;

export const PaymentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
