import { darken, lighten, shade } from "polished";
import styled, { css } from "styled-components";
import reactSelect from "react-select";
import { CircularProgressbar } from "react-circular-progressbar";
import { Form as Unform } from "@unform/web";
import { FiX } from "react-icons/fi";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

interface CircularProps {
  isPaused: boolean;
}

interface ListProps extends OrderColor {
  isAdmin: boolean;
}

interface OrderColor {
  color: string;
}

interface IsAdminProps {
  isAdmin: boolean;
}

interface CancelButtonProps {
  disabled: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const ModalContent = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  > div:first-child {
    display: flex;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: #595959;
`;

export const ModalH2 = styled.h2`
  text-align: center;
  margin: 12px 0 20px;
  font-size: 20px;
  color: #373737;
`;

export const ModalH3 = styled.h3`
  text-align: center;
  color: #373737;
`;

export const SelectedVehiclesInfoText = styled.div`
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  max-width: 80%;
  padding: 15px 0;
  margin: 20px 0;

  > p {
    font-size: 13px;
    color: #373737;
  }

  > p:last-child {
    margin-top: 8px;
  }
`;

export const ActionsContainer = styled.div<{
  hasSelectedVehiclesText: boolean;
}>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${({ hasSelectedVehiclesText }) =>
    hasSelectedVehiclesText ? "0" : "30px"};

  > button {
    max-width: 150px;
  }
  > button:first-child {
    margin-right: 15px;
  }
`;

export const Main = styled.main<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const DispatchesContainer = styled.div`
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
  margin-bottom: 40px;

  @media (min-width: 800px) {
    padding: 20px 30px;
  }
`;

export const CardTitle = styled.h4<IsAdminProps>`
  position: relative;
  padding: 15px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);

  > div:first-child {
    color: var(--color-text-secondary);
    display: flex;
    align-items: center;

    > svg:first-child {
      margin-left: 15px;
    }

    > svg {
      color: #373737;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 10px;

      &:hover {
        color: ${lighten(0.25, "#466aa9")};
      }
    }
  }

  ${({ isAdmin }) =>
    isAdmin &&
    css`
      flex-direction: column;
      align-items: unset;
      > div:last-child {
        margin-top: 15px;
        color: var(--color-text-secondary);
        display: flex;
        align-items: center;
      }

      @media (min-width: 620px) {
        flex-direction: row;

        > div:last-child {
          margin-top: 0;
          padding-right: 70px;
        }
      }
    `}

  @media (min-width: 800px) {
    > div:first-child {
      > svg:first-child {
        margin-left: 25px;
      }

      > svg {
        margin-left: 15px;
      }
    }

    padding: 0 0 19px 0;
  }
`;

export const DispatchesShimmer = styled.div<IsAdminProps>`
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ isAdmin }) =>
    isAdmin
      ? css`
          > div {
            width: 100%;
            height: 300px;
            border-radius: 4px;
          }

          @media (min-width: 1024px) {
            > div {
              height: 280px;
            }
          }
        `
      : css`
          > div {
            width: 100%;
            height: 260px;
            border-radius: 4px;
          }

          @media (min-width: 800px) {
            > div {
              height: 70px;
            }
          }
        `}

  > div:not(:first-child) {
    margin-top: 3px;
  }
`;

export const CircularProgress = styled(CircularProgressbar)<CircularProps>`
  position: absolute;
  right: 20px;
  width: 30px;
  height: 30px;

  .CircularProgressbar-path {
    stroke: #466aa9;
    ${({ isPaused }) =>
      !isPaused &&
      css`
        animation: blink 3s infinite;
      `}
  }

  @keyframes blink {
    0% {
      stroke: #466aa9;
    }
    50% {
      stroke: ${lighten(0.25, "#466aa9")};
    }
    0% {
      stroke: #466aa9;
    }
  }
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0 15px;
  > li:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Li = styled.li<ListProps>`
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  border-top: 3px solid ${({ color }) => color};
  box-shadow: 2px 0px 5px gray;
  height: 260px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 25%;
  }

  > div:last-child {
    flex: 100%;
    min-width: 118px;
  }

  @media (min-width: 550px) {
    padding: 15px 60px;
  }

  @media (min-width: 800px) {
    flex-wrap: unset;
    height: 90px;
    padding: 0 10px;
    border-top: none;
    border-left: 3px solid ${({ color }) => color};

    > div {
      flex: unset;
    }

    > div:first-child {
      width: 8%;
    }
    > div:nth-child(2) {
      width: 20%;
    }

    > div:nth-child(3) {
      width: 13%;
    }

    > div:nth-child(4) {
      width: 10%;
    }

    > div:nth-child(5) {
      width: 16%;
    }

    > div:last-child {
      width: 10%;
      flex: unset;
    }
  }
`;

export const CommonField = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    color: gray;
    font-size: 13px;
    padding-left: 2px;
    margin-bottom: 3px;
  }

  > strong {
    font-size: 14px;
    color: #515a6a;
  }
`;

export const ReactSelect = styled(reactSelect)<OrderColor>`
  .react-select__control {
    border-radius: 14px;
    border: none;
    background-color: ${({ color }) => color};
    min-height: unset;
    justify-content: center;
    outline: none;
    padding: 5px 0;
    box-shadow: none;
  }

  .react-select__loading-indicator {
    display: none;
  }

  .react-select__value-container {
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .react-select__single-value {
    font-size: 13px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
    color: white;
  }

  .react-select__menu {
    top: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(220, 220, 220, 1);
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    padding: 0 !important;
  }

  .react-select__menu-list {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding: 5px 10px;
  }

  .react-select__option--is-focused:active {
    background-color: unset;
  }

  .react-select__option--is-selected:active {
    background-color: unset;
  }

  .react-select__option {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(220, 220, 220, 0.5);
    }
    background: white;
    color: #253451;
    font-size: 13px;
    display: block;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 5px 0;
    cursor: pointer;
    &:hover {
      color: ${shade(0.6, "#253451")};
    }
  }

  .react-select__indicator {
    padding: 0 8px 0 0;
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }

  .react-select__indicator-separator {
    display: none;
  }
`;

export const ActionField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg:first-child {
    margin-right: 15px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: ${lighten(0.2, "#466aa9")};
    }
  }
`;

export const Input = styled.input`
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  transition: 0.1s;
  border: 1px solid #adb8c0;
  text-align: center;
  color: var(--color-text-primary);
  border-radius: 3px;
  outline: none;

  &:disabled {
    pointer-events: none;
    background: rgba(128, 128, 128, 0.2);
    border: 1px solid lightgray;
  }

  &:checked {
    color: var(--color-primary);
  }

  &:checked:before {
    content: "✓";
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const X = styled(FiX)<CancelButtonProps>`
  color: ${({ disabled }) => (disabled ? "lightgray" : "red")};
  cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  transition: 0.3s;
  margin-left: 15px;

  &:hover {
    color: ${darken(0.25, "red")};
  }
`;

export const SearchForm = styled(Unform)`
  > div {
    width: 200px;
  }
`;

export const DispatchButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  > button {
    width: 200px;
  }
`;

export const WarningText = styled.h3`
  text-align: center;
  margin: 55px 0 50px;
  font-size: 23px;
`;
