import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

Sentry.init({
  dsn: "https://fb397226302c463f958bd84e96d123cb@o456643.ingest.sentry.io/5604032",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
