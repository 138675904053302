import { darken, lighten } from "polished";
import styled, { css } from "styled-components";
import ReactSwitch from "react-switch";
import { CircularProgressbar } from "react-circular-progressbar";

interface SidebarStatus {
  isSidebarMaximized: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.div<SidebarStatus>`
  width: 100%;
  max-width: ${({ isSidebarMaximized }) =>
    isSidebarMaximized ? "calc(100vw - 360px);" : "calc(100vw - 160px);"};
  height: 100%;
  margin: 30px;
`;

export const Content = styled.div`
  padding: 20px 60px 50px;
  background: white;
  border-radius: 12px;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);
  min-height: 100%;
`;

export const IntegrationCard = styled.div`
  margin-top: 30px;
  padding: 30px;
  background: white;
  border-radius: 12px;
  position: relative;
  border: 1px solid rgba(220, 220, 220, 0.5);
  box-shadow: 2px 2px 10px rgba(220, 220, 220, 0.2);

  .input-code {
    > input {
      margin: 4px;
    }

    @media (min-width: 600px) {
      width: 100%;
      display: flex !important;
      justify-content: space-between;
      margin: 10px 0 25px;

      > input {
        margin: 0;
      }
    }
  }

  > button {
    width: 100%;
    margin-top: 15px;
  }

  @media (min-width: 550px) {
    width: 400px;
  }
`;

export const StepBadge = styled.div`
  position: absolute;
  left: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
`;

export const DisableIntegration = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #373737;

  > svg {
    margin-left: 8px;
    color: darkgray;
    transition: 0.2s;

    &:hover {
      color: ${darken(0.5, "darkgray")};
    }
  }
`;

export const EnableIntegration = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 25px;
  font-size: 20px;
  font-weight: 500;
  color: #373737;

  > svg {
    margin-left: 8px;
    color: darkgray;
    transition: 0.2s;

    &:hover {
      color: ${darken(0.5, "darkgray")};
    }
  }
`;

export const Switch = styled(ReactSwitch)`
  margin-right: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  > button {
    width: 28%;
  }

  > input {
    width: 68%;
  }
`;

export const Input = styled.input<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid rgba(220, 220, 220);
  border-radius: 4px;
  background: transparent;
  outline: none;
  color: #373737;
  font-size: 16px;
  padding: 5px 16px 5px 16px;
  height: 40px;
  cursor: text;
  width: 100%;

  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;

  ${({ isFocused, disabled }) =>
    isFocused &&
    !disabled &&
    css`
      border-color: var(--color-primary);
    `}

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        border-color: var(--color-primary);
      `}
  }

  &::placeholder {
    color: #8e8e8e;
  }
  &:-webkit-autofill {
    & ~ label {
      color: #8e8e8e;
    }
  }
`;

export const GenerateLinkButton = styled.button`
  border: none;
  position: absolute;
  left: 16px;
  background: none;
  cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
  color: ${({ disabled }) => (disabled ? "lightgray" : "var(--color-primary)")};
  transition: 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        color: ${lighten(0.2, "#466aa9")};
      `}
  }
`;

export const Button = styled.button`
  height: 2.5rem;
  background: ${({ disabled }) =>
    disabled ? "lightgray" : "var(--color-primary)"};
  color: white;
  border: none;
  border-radius: 2px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem !important;
  cursor: ${({ disabled }) => !disabled && "pointer"};
  outline: none !important;
  transition: background 0.5s;

  &:hover:not(:disabled) {
    background: ${darken(0.05, "#466aa9")};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  > h2 {
    max-width: 240px;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  color: #373737;
  margin-bottom: 15px;
`;

export const CircularProgress = styled(CircularProgressbar)`
  width: 40px;
  height: 40px;

  .CircularProgressbar-path {
    stroke: #466aa9;
    animation: blink 3s infinite;
  }

  @keyframes blink {
    0% {
      stroke: #466aa9;
    }
    50% {
      stroke: ${lighten(0.25, "#466aa9")};
    }
    0% {
      stroke: #466aa9;
    }
  }
`;
