import styled from "styled-components";
import { Form as Unform } from "@unform/web";
import { lighten } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  background: white;

  @media (min-width: 800px) {
    padding: 30px;
  }
`;

export const Strong = styled.h2`
  font-weight: 500;
  font-size: 18px;
  padding-left: 15px;
  margin-bottom: 10px;
  color: #373737;
`;

export const Subtitle = styled.h4`
  color: #373737;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const PeriodText = styled.h4`
  color: gray;
  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 80%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 15px;
`;

export const FormTitle = styled.h4`
  margin-bottom: 15px;
  color: #373737;
  align-self: flex-start;
`;

export const SearchForm = styled(Unform)`
  display: flex;
  flex-direction: column;

  > div:nth-child(4) {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    align-items: center;
    width: 100%;

    > svg {
      margin-left: 15px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: ${lighten(0.25, "#466aa9")};
      }
    }
  }

  @media (min-width: 1024px) {
    align-items: center;
    margin: 0 0 20px 25px;

    > div:nth-child(4) {
      display: flex;
      flex-direction: row;
      align-self: flex-start;
      width: 100%;

      > div {
        width: 50%;
      }
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  > div:first-child {
    margin-bottom: 15px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;
    > div:first-child {
      margin-right: 10px;
      margin-bottom: unset;
    }
  }
`;

export const AverageDeliveryTimeContainer = styled.div`
  min-height: 280px;
  font-size: 30px;
  padding-top: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;

  > span {
    font-size: 30px;
    color: var(--color-primary);
  }
`;
