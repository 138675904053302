import styled, { keyframes } from "styled-components";
import { Form as Unform } from "@unform/web";
import { lighten } from "polished";

import loocalBackground from "../../assets/img/loocal-bg.webp";

export const Container = styled.main`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  background: white;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${appearFromLeft} 1s;

  > img {
    width: 280px;
  }
`;

export const Form = styled(Unform)`
  margin: 2% 0;
  width: 340px;
  > h1 {
    text-align: center;
    color: #373737;
    font-size: 23px;
    margin-bottom: 50px;
  }

  @media (min-width: 700px) {
    width: 30rem;
    padding: 2rem;
  }
`;

export const InputAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > a:last-child {
    margin-top: 10px;
  }

  > a {
    display: flex;
    align-items: center;
    color: #373737;
    text-decoration: none;
    transition: 0.3s;
    &:hover {
      color: ${lighten(0.25, "#373737")};
      > svg {
        stroke: ${lighten(0.25, "#373737")};
      }
    }

    > svg {
      stroke: #373737;
      margin-right: 10px;
      transition: 0.3s;
    }
  }

  > span {
    display: block;
    color: gray;
    font-size: 14px;
    margin-top: 12px;
    > a {
      color: #373737;
      font-size: 14px;
      transition: 0.3s;
      &:hover {
        color: ${lighten(0.25, "#373737")};
      }
    }
  }
  > div:first-child {
    margin-bottom: 18px;
  }

  > button {
    margin-top: 24px;
    margin-bottom: 50px;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${loocalBackground}) no-repeat center;
  background-size: cover;
`;
