import { shade } from "polished";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root {
  --color-background: #FCFAFD;
  --color-background-secondary: #FBFBFB;
  --color-background-tertiary: #F8F8F8;
  --color-primary: #466aa9;
  --color-secondary: #24ABE2;
  --color-text-primary: #253451;
  --color-text-secondary: #88909F;
  --color-icon: #A3A9B5;
  --color-text-in-button: #FFF;
  --color-danger: #c53030;
  --color-text-dark: #373737;

}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  &.swal2-icon-content {
    color: unset;
  }

  &.swal2-html-container {
    color: #373737;
    > strong {
      color: #373737;
      font-weight: 500;
    }
  }

  &.swal-disabled{
    background: lightgray !important;
    color: gray !important;
    cursor: default !important;
    pointer-events: none !important;
  }

  &.swal-error {
    color: var(--color-danger);
    font-weight: 500;
  }
}

body {
  background: var(--color-background);
}


.pac-container {
  font-size: 13px;
  font-weight: normal;
  border-top: 0;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px 10px;

  &:after {
    background-image: none !important;
    height: 0px;
  }
}

.pac-icon {
  display: none;
}

.pac-item {
  font-size: 13px;
  font-weight: normal;
  line-height: unset;
  padding: 5px 0;
  border-top: 0;
  color: var(--color-text-primary);
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(220, 220, 220, 0.5);
  }

  &:hover {
    background: unset;
    > span {
      color: ${shade(0.6, "#253451")};
      > span {
        color: ${shade(0.6, "#253451")};
      }
    }
  }
  > span {
    font-size: 13px;
    font-weight: normal;
  }
}

.pac-matched {
  font-size: 13px;
  color: var(--color-text-primary);
  font-weight: normal;
}

.pac-item-query {
  font-size: 13px;
  color: var(--color-text-primary);
  font-weight: normal;
}
`;

export default GlobalStyle;
