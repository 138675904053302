import { darken, shade } from "polished";
import reactSelect from "react-select";
import styled, { css } from "styled-components";

interface ListProps {
  isCurrentPage?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 20px;

  @media (min-width: 600px) {
    padding: 20px 40px;
    flex-direction: row;
  }
`;

export const ReactSelect = styled(reactSelect)`
  font-size: 16px;
  margin-bottom: 15px;
  width: 56px;
  height: 38px;

  .react-select__control {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 2px 0px;
    position: relative;
    cursor: default;
    background: rgb(108, 117, 125);
    border: unset;
  }

  .react-select__control--is-focused {
    box-shadow: none;
  }

  .react-select__loading-indicator {
    display: none;
  }

  .react-select__single-value {
    color: white;
    margin-right: 2px;
  }

  .react-select__menu {
    top: calc(100% - 20px);
    left: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(220, 220, 220, 1);
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    padding: 0 !important;
  }

  .react-select__input {
    width: 100%;
    > input {
      width: 100% !important;
    }
  }

  .react-select__menu-list {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }

  .react-select__option--is-focused:active {
    background-color: unset;
  }

  .react-select__option--is-selected:active {
    background-color: unset;
  }

  .react-select__option {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(220, 220, 220, 0.5);
    }

    &:not(:first-child) {
      padding-top: 5px;
    }

    padding: 0 0 5px 7px;
    background: white;
    color: #253451;
    font-size: 15px;
    display: block;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      color: ${shade(0.6, "#253451")};
    }
  }

  .react-select__indicator {
    color: white;
    cursor: pointer;
    padding: 0;
    margin-right: 5px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  > input:focus {
    outline: none;
  }
`;

export const PaginationContainer = styled.div`
  margin-bottom: 15px;
  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

export const Ul = styled.ul`
  display: flex;
  list-style: none;
`;

export const Li = styled.li<ListProps>`
  cursor: pointer;
  color: ${({ isCurrentPage }) =>
    isCurrentPage ? "#fff" : "var(--color-primary)"};
  background: ${({ isCurrentPage }) =>
    isCurrentPage ? "var(--color-primary)" : "#fff"};
  padding: 8px 12px;

  &:first-child {
    border: 1px solid lightgray;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  & + li {
    border-color: lightgray;
    border-width: 1px;
    border-right-style: solid;
    border-top-style: solid;
    border-bottom-style: solid;
  }

  ${({ isCurrentPage }) =>
    isCurrentPage &&
    css`
      border: none !important;
    `};

  ${({ isCurrentPage }) =>
    !isCurrentPage &&
    css`
      &:hover {
        background: ${darken(0.15, "#fff")};
      }
    `};
`;
