/* eslint-disable */
import React, { useCallback, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import {
  FiCompass,
  FiDollarSign,
  FiMap,
  FiUser,
  FiBriefcase,
} from "react-icons/fi";
import { GrTransaction } from "react-icons/gr";
import { useToasts } from "react-toast-notifications";
import { RiMotorbikeLine } from "react-icons/ri";
import * as Yup from "yup";

import api from "../../services/api";

import {
  CompanyFromGetCompanies,
  DeliverymanFromGetDeliverymans,
} from "../../@types/customTypes";

import Modal from "../Modal";
import Input from "../Input";
import Radio from "../Radio";
import Select from "../ReactSelect";
import Button from "../Button";
import AutocompleteInput from "../AutocompleteInput";
import AsyncReactSelect from "../AsyncReactSelect";
import InvisibleInput from "../InvisibleInput";

import { onChangeCurrencyMask } from "../../utils/inputAndTextMasks";
import getValidationErrors from "../../utils/getValidationErrors";

import * as Styled from "./styles";

interface ModalProps {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
  currentType: string;
}

interface CompanyTransactionFormData {
  company_id: string;
  amount: string;
  transactionTypeId: string;
  isCredit: "true" | "false" | boolean;
}

interface DeliverymanTransactionFormData {
  deliveryman_id: string;
  amount: string;
  transactionTypeId: string;
  isCredit: "true" | "false" | boolean;
  affectBalance: "true" | "false" | boolean;
}

interface DeliveryFormData {
  company_id: string;
  deliveryman_id: string;
  city_1: string;
  address_1: string;
  latitude_1: string;
  longitude_1: string;
  company_name: string;
  complement_1: string;
  name: string;
  number_1: string;
  neighborhood_1: string;
  postal_code_1: string | "";
  return: "false" | "true" | boolean;
  uf_1: string;
}

interface GetCompaniesApiResponse {
  current_page: number;
  data: CompanyFromGetCompanies[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

interface DeliverymanFromGetDeliverymansApiResponse {
  current_page: number;
  data: DeliverymanFromGetDeliverymans[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

const FinancesModal: React.FC<ModalProps> = ({
  isModalOpen,
  handleCloseModal,
  setShowSpinner,
  currentType,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToasts();

  const loadSelectCompanyOptions = useCallback(
    async (inputValue: string, callback: any) => {
      if (!inputValue) {
        return;
      }

      try {
        const {
          data: SearchResultData,
        } = await api.get<GetCompaniesApiResponse>(
          `companies?search=fantasy_name:${inputValue}`,
        );

        callback(
          SearchResultData.data
            .map((result, optionIndex: number) => {
              if (optionIndex < 6) {
                return {
                  value: result.id,
                  label: `${result.id} - ${result.fantasy_name}`,
                  companyCityId: result.address.city_id,
                  companyLat: result.address.latitude,
                  companyLng: result.address.longitude,
                };
              }
              return false;
            })
            .filter((result) => result !== false),
        );
      } catch (e) {
        addToast("Falha ao carregar lista de estabelecimentos!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    },
    [addToast],
  );

  const loadDeliverymanOptions = useCallback(
    async (inputValue: string, callback: any) => {
      if (!inputValue) {
        return;
      }
      try {
        const {
          data: SearchResultData,
        } = await api.get<DeliverymanFromGetDeliverymansApiResponse>(
          `deliverymans?search=user.name:${inputValue}`,
        );

        callback(
          SearchResultData.data
            .map((result, optionIndex: number) => {
              if (optionIndex < 6) {
                return {
                  value: result.id,
                  label: `${result.id} - ${result.user.name}`,
                };
              }
              return false;
            })
            .filter((result) => result !== false),
        );
      } catch (err) {
        // tratar
      }
    },
    [],
  );

  const handleSubmitCompanyTransaction = useCallback(
    async (data: CompanyTransactionFormData) => {
      data.amount = data.amount.replace(/[^0-9,-]+/g, "").replace(/,/g, ".");
      data.isCredit = data.isCredit === "true";
      try {
        setShowSpinner(true);
        setIsLoading(true);
        await api.post(`companies/${data.company_id}/transaction`, data);
        addToast("Dados enviados com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
        formRef.current?.reset();
      } catch (err) {
        addToast(err.message, {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
      setIsLoading(false);
    },
    [addToast, setShowSpinner],
  );

  const handleSubmitDeliverymanTransaction = useCallback(
    async (data: DeliverymanTransactionFormData) => {
      data.amount = data.amount.replace(/[^0-9,-]+/g, "").replace(/,/g, ".");
      data.isCredit = data.isCredit === "true";
      data.affectBalance = data.affectBalance === "true";
      try {
        setShowSpinner(true);
        setIsLoading(true);
        await api.post(`deliverymans/${data.deliveryman_id}/transaction`, data);
        addToast("Dados enviados com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
        formRef.current?.reset();
      } catch (err) {
        addToast(err.message, {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
      setIsLoading(false);
    },
    [addToast, setShowSpinner],
  );

  const handleSubmitDelivery = useCallback(
    async (data: DeliveryFormData) => {
      try {
        setShowSpinner(true);
        setIsLoading(true);
        const dataToSubmitDelivery = {
          company_id: data.company_id,
          deliveryman_id: data.deliveryman_id,
          orders: [
            {
              customer: {
                name: data.name,
                address: {
                  latitude: data.latitude_1,
                  longitude: data.longitude_1,
                  city: data.city_1,
                  uf: data.uf_1,
                  address: data.address_1,
                  number: data.number_1 !== "" ? data.number_1 : null,
                  complement:
                    data.complement_1 !== "" ? data.complement_1 : null,
                  neighborhood: data.neighborhood_1,
                  postal_code:
                    data.postal_code_1 !== ""
                      ? data.postal_code_1.replace(/\D/g, "")
                      : null,
                },
              },
              return: data.return === "true",
            },
          ],
        };
        await api.post(`deliveries/later`, dataToSubmitDelivery);
        addToast("Dados enviados com sucesso!", {
          appearance: "success",
          autoDismiss: true,
        });
        formRef.current?.reset();
      } catch (err) {
        addToast(err.message, {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
      setIsLoading(false);
    },
    [addToast, setShowSpinner],
  );

  const handleSubmitFinancesForm = useCallback(
    async (
      data:
        | CompanyTransactionFormData
        | DeliveryFormData
        | DeliverymanTransactionFormData,
    ) => {
      try {
        if (currentType === "companyTransaction") {
          const schema = Yup.object().shape({
            company_id: Yup.string().required("Campo obrigatório"),
            amount: Yup.string().required("Campo obrigatório"),
            transactionTypeId: Yup.string().required("Campo obrigatório"),
          });
          await schema.validate(data, { abortEarly: false });
          handleSubmitCompanyTransaction(data as CompanyTransactionFormData);
        } else if (currentType === "deliverymanTransaction") {
          const schema = Yup.object().shape({
            deliveryman_id: Yup.string().required("Campo obrigatório"),
            amount: Yup.string().required("Campo obrigatório"),
            transactionTypeId: Yup.string().required("Campo obrigatório"),
          });
          await schema.validate(data, { abortEarly: false });
          handleSubmitDeliverymanTransaction(
            data as DeliverymanTransactionFormData,
          );
        } else if (currentType === "delivery") {
          const schema = Yup.object().shape({
            company_id: Yup.string().required("Campo obrigatório"),
            deliveryman_id: Yup.string().required("Campo obrigatório"),
            name: Yup.string().min(3, "Nome inválido"),
            autocomplete_1: Yup.string().min(6, "Endereço inválido"),
            address_1: Yup.string().required("Endereço inválido"),
            complement_1: Yup.string().when(`number_1`, {
              is: "s/n",
              then: Yup.string().required(
                "Complemento necessário para endereços sem número",
              ),
            }),
          });
          await schema.validate(data, { abortEarly: false });
          handleSubmitDelivery(data as DeliveryFormData);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [
      currentType,
      handleSubmitDelivery,
      handleSubmitCompanyTransaction,
      handleSubmitDeliverymanTransaction,
    ],
  );

  return (
    <Modal
      setIsOpen={handleCloseModal}
      isOpen={isModalOpen}
      customStyles={{
        maxWidth: currentType === "delivery" ? "80vw" : "680px",
        padding: "30px",
        background: "white",
      }}
    >
      <Styled.Container>
        <Styled.Title>
          <Styled.Strong>
            {currentType === "delivery"
              ? "Entrega Retroativa"
              : "Nova transação"}
          </Styled.Strong>
          <Styled.Subtitle>
            Insira os dados da{" "}
            {currentType === "delivery" ? "entrega" : "transação"}
          </Styled.Subtitle>
        </Styled.Title>
        <Styled.Form ref={formRef} onSubmit={handleSubmitFinancesForm}>
          {currentType === "delivery" && (
            <>
              <AsyncReactSelect
                id="company_id"
                name="company_id"
                placeholder="Estabelecimento"
                formRef={formRef}
                icon={FiBriefcase}
                loadOptions={loadSelectCompanyOptions}
                isSearchable
              />
              <AsyncReactSelect
                id="deliveryman_id"
                name="deliveryman_id"
                placeholder="Entregador"
                formRef={formRef}
                icon={RiMotorbikeLine}
                loadOptions={loadDeliverymanOptions}
                isSearchable
              />
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome do Cliente"
                icon={FiUser}
                autoComplete="off"
              />
              <Styled.AddressContainer>
                <AutocompleteInput
                  type="text"
                  id="autocomplete_1"
                  name="autocomplete_1"
                  index={1}
                  placeholder="Endereço"
                  icon={FiCompass}
                  formRef={formRef}
                />
                <Input
                  type="text"
                  id="complement_1"
                  name="complement_1"
                  placeholder="Complemento"
                  icon={FiMap}
                  autoComplete="off"
                />
              </Styled.AddressContainer>
              <InvisibleInput type="text" id="address_1" name="address_1" />
              <InvisibleInput
                type="text"
                id="postal_code_1"
                name="postal_code_1"
              />
              <InvisibleInput type="text" id="number_1" name="number_1" />
              <InvisibleInput
                type="text"
                id="neighborhood_1"
                name="neighborhood_1"
              />
              <InvisibleInput type="text" id="latitude_1" name="latitude_1" />
              <InvisibleInput type="text" id="longitude_1" name="longitude_1" />
              <InvisibleInput type="text" id="city_1" name="city_1" />
              <InvisibleInput type="text" id="uf_1" name="uf_1" />
              <Styled.RadioOption>
                <p>Precisou retornar?</p>
                <Radio
                  name="return"
                  options={[
                    { id: "true", label: "Sim" },
                    { id: "false", label: "Não" },
                  ]}
                  defaultValue="false"
                />
              </Styled.RadioOption>{" "}
            </>
          )}
          {currentType === "companyTransaction" && (
            <>
              <AsyncReactSelect
                id="company_id"
                name="company_id"
                placeholder="Estabelecimento"
                formRef={formRef}
                icon={FiBriefcase}
                loadOptions={loadSelectCompanyOptions}
                isSearchable
              />

              <Input
                type="text"
                id="amount"
                name="amount"
                placeholder="Valor"
                onChange={onChangeCurrencyMask}
                icon={FiDollarSign}
                autoComplete="off"
              />
              <Select
                id="transactionTypeId"
                name="transactionTypeId"
                placeholder="Tipo de transação"
                formRef={formRef}
                options={[
                  { value: "3", label: "Transferência" },
                  { value: "16", label: "Estorno" },
                ]}
                icon={GrTransaction}
                isSearchable={false}
              />
              <Styled.RadioOption>
                <Radio
                  name="isCredit"
                  options={[
                    { id: "true", label: "Crédito" },
                    { id: "false", label: "Débito" },
                  ]}
                  defaultValue="true"
                />
              </Styled.RadioOption>
            </>
          )}

          {currentType === "deliverymanTransaction" && (
            <>
              <AsyncReactSelect
                id="deliveryman_id"
                name="deliveryman_id"
                placeholder="Entregador"
                formRef={formRef}
                icon={RiMotorbikeLine}
                loadOptions={loadDeliverymanOptions}
                isSearchable
              />
              <Input
                type="text"
                id="amount"
                name="amount"
                placeholder="Valor"
                onChange={onChangeCurrencyMask}
                icon={FiDollarSign}
                autoComplete="off"
              />
              <Select
                type="normal"
                id="transactionTypeId"
                name="transactionTypeId"
                placeholder="Tipo de transação"
                formRef={formRef}
                options={[
                  { value: "8", label: "Saque completo" },
                  { value: "12", label: "Saque rápido completo" },
                  { value: "6", label: "Pagamento de taxa" },
                  { value: "16", label: "Estorno" },
                ]}
                icon={GrTransaction}
                isSearchable={false}
              />
              <Styled.RadioOption>
                <Radio
                  name="isCredit"
                  options={[
                    { id: "true", label: "Crédito" },
                    { id: "false", label: "Débito" },
                  ]}
                  defaultValue="true"
                />
                <Radio
                  name="affectBalance"
                  options={[
                    { id: "true", label: "Saldo Disponível" },
                    { id: "false", label: "Saldo Bloqueado" },
                  ]}
                  defaultValue="true"
                />
              </Styled.RadioOption>
            </>
          )}

          <Button
            type="submit"
            content={isLoading ? "Carregando..." : "Salvar"}
            disabled={isLoading}
          />
        </Styled.Form>
      </Styled.Container>
    </Modal>
  );
};

export default FinancesModal;
