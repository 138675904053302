/* eslint-disable */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormHandles, useField } from "@unform/core";
import { FiAlertCircle } from "react-icons/fi";
import { IconBaseProps } from "react-icons";

import * as Styled from "./styles";

interface Options {
  value: string | number;
  label: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  formRef: React.RefObject<FormHandles>;
  childrenStateUpdater?: number;
  options: Options[];
  type: string;
  formNameToSendData?: string;
  initialData?: number[];
  hasAnimation?: boolean;
}

const MultipleSelect: React.FC<InputProps> = ({
  icon: Icon,
  name,
  formNameToSendData,
  formRef,
  options,
  placeholder,
  type,
  initialData,
  childrenStateUpdater,
  hasAnimation = false,
  ...rest
}) => {
  const [currentOptions, setCurrentOptions] = useState<Options[]>(
    [] as Options[],
  );
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { fieldName, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (isOptionsVisible) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!containerRef.current?.contains(event.target as HTMLElement)) {
          setIsOptionsVisible(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
    return undefined;
  }, [inputRef, isOptionsVisible]);

  useEffect(() => {
    if (initialData !== undefined && !alreadyLoaded) {
      const initialValues: number[] = [];
      const initialOptions: Options[] = [];

      initialData.forEach((data) => {
        const foundIndex = options.findIndex((option) => option.value === data);

        initialOptions.push(options[foundIndex]);
        initialValues.push(options[foundIndex].value as number);
      });

      formRef.current?.setData({
        [name]: initialValues,
      });
      setCurrentOptions(initialOptions);
      setAlreadyLoaded(true);
    }
  }, [initialData, formRef, name, options, alreadyLoaded]);

  useEffect(() => {
    if (childrenStateUpdater) {
      setCurrentOptions([]);
    }
  }, [childrenStateUpdater]);

  const handleSetListVisibility = useCallback(() => {
    setIsOptionsVisible(true);
    clearError();
  }, [clearError]);

  const handleChooseOption = useCallback(
    (selectedOption) => {
      const selectedOptionIndex = options.findIndex(
        (option) => option.value === selectedOption.value,
      );

      const selectedOptionIndexAtCurrentOptions = currentOptions.findIndex(
        (currentOption) => currentOption.value === selectedOption.value,
      );

      const isAlreadySelected = selectedOptionIndexAtCurrentOptions !== -1;

      if (isAlreadySelected) {
        formRef.current?.setData({
          [name]: currentOptions
            .filter(
              (currentOption) => currentOption.value !== selectedOption.value,
            )
            .map((currentOption) => currentOption.value),
        });

        setCurrentOptions(
          currentOptions.filter(
            (currentOption) => currentOption.value !== selectedOption.value,
          ),
        );
      } else {
        formRef.current?.setData({
          [name]: [
            ...currentOptions.map((currentOption) => currentOption.value),
            options[selectedOptionIndex].value,
          ],
        });
        setCurrentOptions((state) => [...state, options[selectedOptionIndex]]);
      }
    },
    [currentOptions, formRef, name, options],
  );

  return (
    <Styled.Container
      ref={containerRef}
      isErrored={!!error}
      onClick={handleSetListVisibility}
      hasAnimation={hasAnimation}
    >
      {Icon && <Icon size={20} />}
      <Styled.Input placeholder=" " ref={inputRef} defaultValue="" {...rest} />
      {currentOptions[0]?.label && (
        <Styled.Value>
          {currentOptions.map((currentOption, index) => {
            if (currentOptions.length === 1) {
              return currentOption.label;
            }
            return index !== 0
              ? `, ${currentOption.label}`
              : currentOption.label;
          })}
        </Styled.Value>
      )}
      {isOptionsVisible && (
        <Styled.Options type={type}>
          <ul>
            {options.map((option) => (
              <Styled.Option
                key={option.value}
                onClick={() => handleChooseOption(option)}
                alreadySelected={
                  currentOptions?.findIndex(
                    (currentOption) => currentOption.value === option.value,
                  ) !== -1
                }
              >
                {option.label}
              </Styled.Option>
            ))}
          </ul>
        </Styled.Options>
      )}
      <label htmlFor={rest.id}>{placeholder}</label>
      {error && (
        <Styled.Error title={error}>
          <FiAlertCircle size={20} />
        </Styled.Error>
      )}
    </Styled.Container>
  );
};

export default MultipleSelect;
